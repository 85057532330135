import { useQuery } from 'react-query'
import { Address } from '../../store/address'
import { Carrier } from '../../store/carrierSelector'
import { getStatesFromAddresses } from '../Address'

export function excludeCarriersFromOtherRegions(carriers: Carrier[], addressStates: string[]) {
  return carriers.filter((carrier) => {
    if (!carrier.carrier_availability) {
      return false
    }
    return carrier.available_nationwide || addressStates.some((state) => carrier.carrier_availability!.includes(state))
  })
}

export const useAddressesStates = (addresses: Address[]) => {
  return useQuery({
    queryKey: ['states', addresses],
    staleTime: Infinity,
    queryFn: async () => {
      return getStatesFromAddresses(addresses)
    }
  })
}

export const useGetRegionFilter = (addresses: Address[], skipRegionFilter = false) => {
  const states = useAddressesStates(addresses)

  return useQuery({
    queryKey: ['statesFilter', states, skipRegionFilter],
    enabled: !!states.data,
    queryFn: async () => {
      if (skipRegionFilter) {
        return 'includeRegionalCarriers=true'
      }
      if (!addresses.length) {
        return 'includeRegionalCarriers=false'
      }
      return `includeRegionalCarriers=${states.data!.length ? `true&states=${states.data!.join(',')}` : 'false'}`
    }
  })
}
