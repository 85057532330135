import * as React from 'react'
import { UseQueryResult, useInfiniteQuery, useQuery } from 'react-query'
import shallow from 'zustand/shallow'
import { GeoJsonRecord } from '../../components/CoverageMap/Geodata.service'
import { useBoundStore, useGeneralStore } from '../../store'
import { Address } from '../../store/address'
import { UseCarrier } from '../Carrier.service'
import { ComparedPlan, planCompare } from './planCompare'
import API from '../../api'
import { AddonTypes, Plan, PlanResponse, TopPick, UnlimitedDataPreference } from './Plan'
import { filterPlans, mapPlanResponseToPlan, searchPlans } from './Plan.utils'
import { SortingOption } from '../../components/Header/PlansHeader/PlansHeaderChips'
import {
  capFirstPlansNoRepeatCarrier,
  getRecommendedPlans,
  getRecommendedPlansV2,
  PlanPreferences
} from './getRecommendedPlans'
import { Carrier } from '../../store/carrierSelector'
import { PlanNotFound } from '../../pages/current-plan/PlanNotFound'
import { useGetRegionFilter } from '../../utils/hooks/useGetRegionFilter'
import { ReasonForSwitch } from '../wizard/reason-for-switch'
import { DATA_UNLIMITED } from './Preferences'
import { useFlag } from '../../utils/feature-flags/useFlag'
import { FeatureFlag } from '../../utils/FeatureFlags'
import { useAutoChatStorage } from '../../pages/llm/hooks/useChatStorage'
import { getNetworkTypeId, NetworkType } from '../NetworkType.service'
import { filterPlansV3, PlanPreferencesV3 } from '../../store/standalone/filters-store/filter-plans'
import useFiltersStore from '../../store/standalone/filters-store/filters-store'

export type PerformanceAverages = {
  average4GPerformance: number | undefined
  average5GPerformance: number | undefined
}
export function getNetworkPerformanceAverages(records: GeoJsonRecord[]): PerformanceAverages {
  if (!records.length) {
    return {
      average4GPerformance: undefined,
      average5GPerformance: undefined
    }
  }

  const records4G = records.filter(
    (record) => record.properties.network_type_id === getNetworkTypeId(NetworkType['4G'])
  )
  const average4GPerformance = !!records4G.length
    ? records4G.reduce((acc, record) => acc + record.properties.network_performance, 0) / records4G.length
    : undefined
  const records5G = records.filter(
    (record) => record.properties.network_type_id === getNetworkTypeId(NetworkType['5G'])
  )
  const average5GPerformance = !!records5G.length
    ? records5G.reduce((acc, record) => acc + record.properties.network_performance, 0) / records5G.length
    : undefined
  return {
    average4GPerformance,
    average5GPerformance
  }
}

export function enrichPlansWithPerformance(plans: Plan[], bestCarriers: GeoJsonRecord[], supportedCarriers: Carrier[]) {
  const carrierToNetworkQuality = new Map<
    string,
    { networkPerformance?: number; noCoverage?: boolean; average5GPerformance?: number; average4GPerformance?: number }
  >()

  const isPlanCarrierSupported = (plan: Plan) => {
    if (!supportedCarriers) {
      return false
    }
    if (plan.networkPerformance4G !== undefined) {
      return true
    }
    return supportedCarriers.some((carrier) => plan.networkOperators?.includes(carrier.id))
  }

  for (const plan of plans) {
    plan.isCarrierSupported = isPlanCarrierSupported(plan)
  }

  bestCarriers.forEach((record) => {
    if (carrierToNetworkQuality.has(record.properties.carrier_id)) {
      return
    }

    const allRecords = bestCarriers.filter((r) => r.properties.carrier_id === record.properties.carrier_id)

    if (allRecords.some((r) => r.properties.network_type_id === 0)) {
      carrierToNetworkQuality.set(record.properties.carrier_id, {
        noCoverage: true,
        average5GPerformance: 0,
        average4GPerformance: 0
      })
      return
    }

    carrierToNetworkQuality.set(record.properties.carrier_id, getNetworkPerformanceAverages(allRecords))
  })

  for (const plan of plans) {
    let networkQuality = carrierToNetworkQuality.get(plan.carrierId)

    if (!networkQuality && plan.networkOperators) {
      networkQuality = getBestMvnoNetworkPerformance(plan.networkOperators)
      networkQuality && carrierToNetworkQuality.set(plan.carrierId, networkQuality)
    }

    if (networkQuality) {
      plan.networkPerformance4G = networkQuality.average4GPerformance
      plan.networkPerformance5G = networkQuality.average5GPerformance
    } else {
      plan.networkPerformance4G = undefined
      plan.networkPerformance5G = undefined
    }
  }

  function getBestMvnoNetworkPerformance(networkOperators: string[]) {
    const networkQualities = networkOperators.map((operator) => carrierToNetworkQuality.get(operator))
    return networkQualities.sort((a, b) => ((a?.networkPerformance ?? 0) > (b?.networkPerformance ?? 0) ? -1 : 1))[0]
  }

  function toComparePlan(plan: Plan): ComparedPlan {
    return {
      data: plan.data || 0,
      network_performance: plan.networkPerformance4G || 0,
      price: plan.price,
      telcoType: plan.telcoType,
      premium_data: 0
    }
  }

  plans.sort((a, b) => planCompare(toComparePlan(a), toComparePlan(b)))

  return plans
}

export function sortPlans(plans: Plan[], sortBy?: SortingOption) {
  if (!sortBy) {
    return
  }

  if (sortBy === SortingOption.Featured) {
    return plans.sort((a, b) => ((b.overall5gScore ?? 0) > (a.overall5gScore ?? 0) ? 1 : -1))
  }

  if (sortBy === SortingOption.PriceLowToHigh) {
    plans.sort((a, b) => {
      if (a.price === b.price) {
        return 0
      }

      return a.price > b.price ? 1 : -1
    })
    return
  }

  if (sortBy === SortingOption.PriceHighToLow) {
    plans.sort((a, b) => {
      if (a.price === b.price) {
        return 0
      }

      return a.price > b.price ? -1 : 1
    })
    return
  }

  if (sortBy === SortingOption.Carrier) {
    plans.sort((a, b) => a.carrierName.localeCompare(b.carrierName))
    return
  }

  if (sortBy === SortingOption.NetworkPerformance) {
    plans.sort((a, b) => {
      const aPerf = Number(a.networkPerformance4G) || 0
      const bPerf = Number(b.networkPerformance4G) || 0
      if (aPerf === bPerf) {
        return 0
      }
      return aPerf < bPerf ? 1 : -1
    })
  }
}

interface BestPlansPayload {
  addresses: Address[]
  limit: number
  offset: number
  fuzzy?: boolean
  sort?: string
  bestCarriers?: GeoJsonRecord[]
  preferences: PlanPreferences
}

export interface FilterOptions {
  nLinesFilter?: number
  nPlansFilter?: number
}
export interface PaginationResponse {
  count: number
  limit: number
  offset: number
  total: number
}

export function useSetPreferences() {
  const [setTargetBudget, setUseUnlimitedDataPlan, setPhoneLinesNeeded, setReasonsForSwitch] = useBoundStore(
    (state) => [
      state.setTargetBudget,
      state.setUseUnlimitedDataPlan,
      state.setPhoneLinesNeeded,
      state.setReasonsForSwitch
    ],
    shallow
  )
  return React.useCallback(
    (preferences: PlanPreferences) => {
      // TODO: should be int
      preferences.maxPrice && setTargetBudget(preferences.maxPrice)

      // TODO: should be bool
      setUseUnlimitedDataPlan(
        preferences.minData === DATA_UNLIMITED ? UnlimitedDataPreference.YES : UnlimitedDataPreference.NO_PREFERENCE
      )

      preferences.numLines && setPhoneLinesNeeded(preferences.numLines)
      setReasonsForSwitch(preferences.reasonsForSwitch)
    },
    [setTargetBudget, setUseUnlimitedDataPlan, setPhoneLinesNeeded, setReasonsForSwitch]
  )
}

const usePreferences = (): BestPlansPayload['preferences'] => {
  const [targetBudget, unlimitedDataPlan, phoneLinesNeeded, reasonsForSwitch] = useBoundStore(
    (state) => [state.targetBudget, state.useUnlimitedDataPlan, state.phoneLinesNeeded, state.reasonsForSwitch],
    shallow
  )

  const currentPlan = UsePlan.useUserPlan()
  const preferences: BestPlansPayload['preferences'] = React.useMemo(() => {
    const numBudget = Number(targetBudget) / 100
    return {
      minData: unlimitedDataPlan === UnlimitedDataPreference.YES ? DATA_UNLIMITED : undefined,
      maxPrice: reasonsForSwitch.includes(ReasonForSwitch.Cost)
        ? Math.max(numBudget ?? 0, currentPlan.data?.price ?? 0)
        : numBudget,
      numLines: phoneLinesNeeded,
      reasonsForSwitch
    }
  }, [targetBudget, unlimitedDataPlan, phoneLinesNeeded, reasonsForSwitch, currentPlan.data?.price])

  return preferences
}

const getNextPageParam = (lastPage: PaginationResponse & { plans: Plan[] }) => {
  if (lastPage.offset + lastPage.count >= lastPage.total) {
    return undefined
  }
  return {
    offset: lastPage.offset + lastPage.limit,
    limit: lastPage.limit
  }
}

const paginationLimit = 8
export const UsePlan = {
  useUserPlan: () => {
    const [currentPlanId, carrierId] = useBoundStore((state) => [state.currentPlanId, state.userCarrierId], shallow)
    const result = UsePlan.usePlan(carrierId, currentPlanId)

    return result
  },
  usePlan: (carrierId?: string, planId?: string) => {
    const carrierPlansQuery = UsePlan.useCarrierPlans(carrierId || '')

    const usePlanQuery = useQuery({
      queryKey: 'plan-result/' + carrierId + '/' + planId,
      staleTime: Infinity,
      enabled: carrierPlansQuery.isFetched,
      queryFn: () => {
        if (planId === PlanNotFound.planId) {
          return PlanNotFound
        }
        return carrierPlansQuery.data?.find((plan) => plan.planId === planId)
      }
    })

    return { ...usePlanQuery, isLoading: carrierPlansQuery.isLoading || usePlanQuery.isLoading } as UseQueryResult<
      Plan | undefined,
      unknown
    >
  },
  useAllPlans({ skipRegionFilter = false } = {}) {
    const { data: statesFilter } = useGetRegionFilter(
      useGeneralStore((state) => state.addresses),
      skipRegionFilter
    )
    return useQuery({
      queryKey: ['allPlans', statesFilter],
      staleTime: Infinity,
      async queryFn() {
        const plans: PlanResponse[] = await API.get(`/plans/from-db?${statesFilter ?? ''}`)
        return plans.map((plan: PlanResponse) => mapPlanResponseToPlan(plan))
      }
    })
  },
  useAllPlansControlled: ({
    sortBy,
    search,
    preferences
  }: {
    sortBy?: SortingOption
    search?: string
    preferences?: PlanPreferencesV3
  }) => {
    const plansQuery = UsePlan.usePlansWithPerf()
    const storeFilters = useFiltersStore()
    const filters = preferences ?? storeFilters

    const controlledQuery = useQuery({
      queryKey: ['allPlansControlled', sortBy, search, filters],
      enabled: plansQuery.isFetched,
      cacheTime: 10 * 1000,
      queryFn() {
        const searchPlan = searchPlans(plansQuery.data!, search)
        sortPlans(searchPlan, sortBy)
        const filteredPlans = filterPlansV3(searchPlan, filters)
        return filteredPlans
      }
    })

    return { ...controlledQuery, isLoading: plansQuery.isLoading || controlledQuery.isLoading }
  },
  usePlansWithPerf() {
    const preferences = usePreferences()

    const { chatStorage } = useAutoChatStorage()

    const [addresses] = useBoundStore((state) => [state.addresses], shallow)
    const latlng = addresses.map((addr) => {
      return `[${addr.latitude},${addr.longitude}]`
    })

    const bestCarriersQuery = UseCarrier.useBestCarriers()
    const supportedCarriersQuery = UseCarrier.useCarriersWithRecords()
    const allPlansQuery = UsePlan.useAllPlans()
    const queryKey = ['usePlans/', preferences, chatStorage.data, latlng]

    const plansWithPerfQuery = useQuery({
      enabled: bestCarriersQuery.isFetched && allPlansQuery.isFetched && supportedCarriersQuery.isFetched,
      queryKey,
      async queryFn() {
        return enrichPlansWithPerformance(allPlansQuery.data!, bestCarriersQuery.data!, supportedCarriersQuery.data!)
      }
    })

    return {
      ...plansWithPerfQuery,
      isLoading: bestCarriersQuery.isLoading || allPlansQuery.isLoading || plansWithPerfQuery.isLoading
    } as UseQueryResult<Plan[], unknown>
  },
  useRecommendedPlans: () => {
    const [addresses] = useBoundStore((state) => [state.addresses], shallow)

    const currentPlanQuery = UsePlan.useUserPlan()
    const bestCarriersQuery = UseCarrier.useBestCarriers()

    const latlng = addresses.map((addr) => {
      return `[${addr.latitude},${addr.longitude}]`
    })

    const preferences = usePreferences()
    const plansQuery = UsePlan.usePlansWithPerf()
    const { chatStorage, userPreferences: preferencesV2 } = useAutoChatStorage()

    const chatUiFlag = useFlag(FeatureFlag.CHAT_UI)

    const passedPlans = useGeneralStore((state) => state.passedPlans)

    const queryKey = [
      'best-plans/',
      latlng.toString(),
      preferences,
      currentPlanQuery.data?.planId,
      chatStorage.data,
      passedPlans
    ]

    const recommendedPlansQuery = useQuery({
      queryKey,
      enabled: bestCarriersQuery.isFetched && plansQuery.isFetched && currentPlanQuery.isFetched && !!chatStorage.data,
      staleTime: Infinity,
      queryFn: async () => {
        if (chatUiFlag) {
          return getRecommendedPlansV2(plansQuery.data!, preferencesV2, passedPlans)
        }
        return getRecommendedPlans(plansQuery.data!, preferences)
      }
    })

    return {
      ...recommendedPlansQuery,
      isLoading:
        currentPlanQuery.isLoading ||
        bestCarriersQuery.isLoading ||
        plansQuery.isLoading ||
        recommendedPlansQuery.isLoading
    } as UseQueryResult<Plan[], unknown>
  },
  useRecommendedPlansControlled: ({
    sortBy,
    filters,
    search
  }: {
    sortBy?: SortingOption
    filters?: FilterOptions
    search?: string
  }) => {
    const recommendedPlansQuery = UsePlan.useRecommendedPlans()
    const plans = [...(recommendedPlansQuery.data ?? [])]

    const controlledQuery = useQuery({
      queryKey: ['recommendedPlansControlled', sortBy, filters, search, plans],
      enabled: recommendedPlansQuery.isFetched,
      cacheTime: 10 * 1000,
      async queryFn() {
        const filteredPlans = filterPlans(plans, { ...filters, nPlansFilter: undefined })
        const cappedPlans = capFirstPlansNoRepeatCarrier(filteredPlans, filters?.nPlansFilter ?? 10)
        const searchPlan = searchPlans(cappedPlans, search)

        if (sortBy !== SortingOption.Featured) {
          sortPlans(searchPlan, sortBy)
        }
        return searchPlan
      }
    })

    return {
      ...controlledQuery,
      isLoading: recommendedPlansQuery.isLoading || controlledQuery.isLoading
    } as UseQueryResult<Plan[], unknown>
  },
  useBestPlans: ({
    sortBy,
    filters,
    onSuccess,
    fuzzy
  }: {
    // TODO: deprecate fuzzy
    fuzzy?: boolean
    sortBy?: SortingOption
    filters?: FilterOptions
    onSuccess?: (data: PaginationResponse & { plans: Plan[] }) => void
  } = {}) => {
    const [addresses] = useBoundStore((state) => [state.addresses], shallow)
    const bestCarriers = UseCarrier.useBestCarriers()

    const latlng = addresses.map((addr) => {
      return `[${addr.latitude},${addr.longitude}]`
    })

    const preferences = usePreferences()
    const plans = UsePlan.usePlansWithPerf()
    const queryKey = ['best-plans/', latlng.toString(), preferences, sortBy, filters, fuzzy]

    return useInfiniteQuery({
      queryKey,
      enabled: bestCarriers.isFetched && plans.isFetched,
      keepPreviousData: true,
      staleTime: Infinity,
      queryFn: async ({ pageParam = { limit: paginationLimit, offset: 0 } }) => {
        sortPlans(plans.data!, sortBy)
        const filteredPlans = filterPlans(plans.data!, {
          ...filters,
          withinUserPreferences: { preferences, fuzzy }
        })

        const plansSlice = filteredPlans.slice(pageParam.offset, pageParam.offset + pageParam.limit)

        const result = {
          plans: plansSlice,
          count: plansSlice.length,
          offset: pageParam.offset,
          total: filteredPlans.length,
          limit: pageParam.limit
        }
        onSuccess?.(result)
        return result
      },
      getNextPageParam
    })
  },
  useCarrierPlans: (carrierId: string) => {
    const allPlans = UsePlan.useAllPlans()

    return useQuery({
      queryKey: 'carrier-plans' + carrierId,
      enabled: !!allPlans.data,
      staleTime: Infinity,
      async queryFn() {
        if (!carrierId) {
          return []
        }

        return allPlans.data!.filter((plan) => plan.carrierId === carrierId)
      }
    })
  },
  useTopPicks: () => {
    return useQuery({
      queryKey: 'topPicks',
      staleTime: Infinity,
      async queryFn() {
        const topPicks = await API.get('/top-picks')

        return topPicks as TopPick[]
      }
    })
  },
  useTopPicksPlans: ({ topPicksIds, enrichPlans = true }: { topPicksIds: number[]; enrichPlans?: boolean }) => {
    const bestCarriersQuery = UseCarrier.useBestCarriers()
    const supportedCarriersQuery = UseCarrier.useCarriersWithRecords()
    const { data: statesFilter } = useGetRegionFilter(useGeneralStore((state) => state.addresses))

    const topPicksPlansQuery = useQuery({
      queryKey: ['topPicksPlans', topPicksIds, enrichPlans, statesFilter],
      staleTime: Infinity,
      enabled: !enrichPlans || (bestCarriersQuery.isFetched && supportedCarriersQuery.isFetched),
      async queryFn() {
        return await Promise.all(
          topPicksIds.map(async (id) => {
            const plans = (
              (await API.get(
                `/top-picks/plans?topPickId=${id}${statesFilter ? `&${statesFilter}` : ''}`
              )) as PlanResponse[]
            ).map((planResponse) => {
              return mapPlanResponseToPlan(planResponse)
            })
            if (enrichPlans && supportedCarriersQuery.data) {
              return enrichPlansWithPerformance(plans, bestCarriersQuery.data!, supportedCarriersQuery.data)
            }
            return plans
          })
        )
      }
    })

    return {
      ...topPicksPlansQuery,
      isLoading: bestCarriersQuery.isLoading || topPicksPlansQuery.isLoading
    } as UseQueryResult<Plan[][], unknown>
  },
  useTopPicksPlansControlled: ({
    topPicksIds,
    sortBy,
    filters,
    search
  }: {
    topPicksIds: number[]
    sortBy?: SortingOption
    filters?: FilterOptions
    search?: string
  }) => {
    const topPicksPlansQuery = UsePlan.useTopPicksPlans({ topPicksIds })

    const controlledQuery = useQuery({
      queryKey: ['topPicksPlansControlled', topPicksIds, sortBy, filters, search],
      enabled: topPicksPlansQuery.isFetched,
      cacheTime: 10 * 1000,
      async queryFn() {
        return topPicksPlansQuery.data!.map((plans) => {
          const searchPlan = searchPlans(plans, search)
          sortPlans(searchPlan, sortBy)
          const filteredPlans = filterPlans(searchPlan, filters)
          return filteredPlans
        })
      }
    })

    return {
      ...controlledQuery,
      isLoading: topPicksPlansQuery.isLoading || controlledQuery.isLoading
    } as UseQueryResult<Plan[][], unknown>
  },
  useAllTopPicksPlans: ({ enrichPlans = true }: { enrichPlans?: boolean }) => {
    const bestCarriersQuery = UseCarrier.useBestCarriers()
    const supportedCarriersQuery = UseCarrier.useCarriersWithRecords()

    const allTopPicksPlansQuery = useQuery({
      queryKey: ['allTopPicksPlans', enrichPlans],
      enabled: !enrichPlans || (bestCarriersQuery.isFetched && supportedCarriersQuery.isFetched),
      async queryFn() {
        const topPicks = (await API.get('/top-picks')) as TopPick[]
        const topPicksPlans = await Promise.all(
          topPicks.map(async (topPick) => {
            const plans = ((await API.get(`/top-picks/plans?topPickId=${topPick.id}`)) as PlanResponse[]).map(
              (planResponse) => {
                return mapPlanResponseToPlan(planResponse)
              }
            )
            if (enrichPlans && supportedCarriersQuery.data) {
              return enrichPlansWithPerformance(plans, bestCarriersQuery.data!, supportedCarriersQuery.data)
            }
            return plans
          })
        )
        return topPicksPlans
      }
    })

    return { ...allTopPicksPlansQuery, isLoading: bestCarriersQuery.isLoading || allTopPicksPlansQuery.isLoading }
  }
}

export const addonToImage: Record<AddonTypes, string> = {
  // TODO: add all addon images
  Netflix: '/assets/images/addons/netflix.svg',
  Paramount: '/assets/images/addons/paramount.svg',
  Hulu: '/assets/images/addons/hulu.svg',
  Amazon: '',
  'ESPN+': '/assets/images/addons/espn.svg',
  'Disney+': '/assets/images/addons/disneyplus.svg',
  'HBO Max': '',
  'Apple Tv': '/assets/images/addons/apple.svg',
  'Discovery+': '',
  'Apple Music': '',
  'Google Play': '',
  'Apple Arcade': ''
}
