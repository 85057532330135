import { Logger } from '../utils/Logger'
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export type IApi = {
  get<T>(resource: string): Promise<T>
  post<T, B extends Record<string, any>>(resource: string, body: B): Promise<T>
  put<T, B extends Record<string, any>>(resource: string, body: B): Promise<T>
  delete<T>(resource: string): Promise<T>
}

export const apiName = 'tbirdapi'

async function getToken(): Promise<string> {
  try {
    const Auth = (await import('aws-amplify')).Auth
    const session = await Auth.currentSession()
    const token = session.getAccessToken().getJwtToken()
    return token
  } catch (e) {
    if (e !== 'No current user') {
      Logger.error(e)
    }
    return ''
  }
}

export async function handleRequest(url: string, requestInit: RequestInit = {}): Promise<Response> {
  if (!requestInit.headers) {
    requestInit.headers = {}
  }

  const token = await getToken()
  if (token && !Object.hasOwn(requestInit.headers, 'Authorization')) {
    // @ts-expect-error
    requestInit.headers['Authorization'] = 'Bearer ' + token
  }

  return fetch(BACKEND_URL + url, requestInit)
}

export async function requestJson<T>(url: string, requestInit: RequestInit = {}): Promise<T> {
  return handleRequest(url, requestInit)
    .then((res) => {
      if (res.status === 204) {
        return {} as T
      }

      if (res.status === 403) {
        return Promise.reject({ error: new Error('Forbidden'), res })
      }
      if (res.status === 404) {
        return Promise.reject({ error: new Error('Not found'), res })
      }

      if (res.status === 500) {
        return Promise.reject({ error: new Error('Internal Server Error'), res })
      }

      return res.text().then((text) => {
        return text ? JSON.parse(text) : {}
      })
    })
    .then(
      (result) => Promise.resolve(result),
      (error) => Promise.reject(error)
    )
}

export const HttpApi: IApi = {
  async get<T = unknown>(resource: string): Promise<T> {
    return await requestJson(resource)
  },

  async post<T, B extends Record<string, any>>(resource: string, body: B): Promise<T> {
    return await requestJson<T>(resource, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
  },

  async put<T = unknown>(resource: string, body: Record<string, unknown>): Promise<T> {
    return await requestJson<T>(resource, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
  },

  async delete<T = unknown>(resource: string): Promise<T> {
    return await requestJson<T>(resource, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
  }
}

const API = {
  api: HttpApi,

  async get<T = unknown>(resource: string): Promise<T> {
    return await this.api.get(resource)
  },

  async post<T = unknown>(resource: string, body: Record<string, unknown>): Promise<T> {
    return await this.api.post<T, typeof body>(resource, body)
  },

  async put<T = unknown>(resource: string, body: Record<string, any>): Promise<T> {
    return await this.api.put(resource, body)
  },

  async delete<T = unknown>(resource: string): Promise<T> {
    return await this.api.delete(resource)
  }
}

export default API
