export const IconPremiumData = ({ color, className }: { color?: string; className?: string }) => {
  return (
    <div className={className}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M9.825 19.875L16.8 11.5H12.3L13.2 4.35L6.875 13.5H10.725L9.825 19.875ZM8 22L9 15H4L13 2H15L14 10H20L10 22H8Z'
          fill={color ?? 'rgba(0, 0, 0, 0.6)'}
        />
        <path
          d='M10.725 13.375H7.11336L13.0121 4.8416L12.176 11.4844L12.1583 11.625H12.3H16.5332L10.0102 19.4573L10.8488 13.5175L10.8689 13.375H10.725ZM7.87626 21.9823L7.85587 22.125H8H10H10.0585L10.096 22.08L20.096 10.08L20.2669 9.875H20H14.1416L15.124 2.0155L15.1416 1.875H15H13H12.9345L12.8972 1.92885L3.89723 14.9288L3.76143 15.125H4H8.85587L7.87626 21.9823Z'
          stroke={color ?? 'rgba(0, 0, 0, 0.6)'}
          strokeWidth='0.25'
        />
      </svg>
    </div>
  )
}
