import { createGesture, Gesture } from '@ionic/react'
import React from 'react'

interface UseBottomTrayGestureProps {
  elementRef: React.RefObject<HTMLDivElement>
  enabled?: boolean
  onStart: () => void
  onMove: (ev: any) => void
  onEnd: (ev: any) => void
  direction?: 'x' | 'y'
}

const useSwipeGesture = ({
  elementRef,
  enabled = true,
  onStart,
  onMove,
  onEnd,
  direction = 'y'
}: UseBottomTrayGestureProps) => {
  const gestureRef = React.useRef<Gesture>()

  React.useEffect(() => {
    if (!elementRef.current || gestureRef.current) return

    gestureRef.current = createGesture({
      el: elementRef.current!,
      gestureName: 'swipe',
      direction,
      onStart,
      onMove,
      onEnd
    })
    gestureRef.current.enable(true)
  }, [direction, elementRef, onEnd, onMove, onStart])

  React.useEffect(() => {
    gestureRef.current?.enable(enabled)
  }, [enabled])
}

export default useSwipeGesture
