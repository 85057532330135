import create, { StoreApi, UseBoundStore } from 'zustand'
import createAddressSlice, { addressInitialState } from './address'
import createBestPlansSlice, { bestPlanInitialState } from './bestPlans'
import createCarrierSelectorSlice, { carrierSelectorInitialState } from './carrierSelector'
import createCurrentPlanSlice, { currentPlanInitialState } from './currentPlan'
import createExpandedPlansSlice, { expandedPlansInitialState } from './expandedPlans'
import createNetworkMeterResultsSlice, { networkMeterResultsInitialState } from './networkMeterResults'
import createNetworkQualitySlice, { networkQualityInitialState } from './networkQuality'
import createPlansInteractionSlice, { planInteractionInitialState } from './plansInteraction'
import createQuestionsSlice, { questionsInitialState } from './questions'
import createSearchHistorySlice, { searchHistoryInitialState } from './searchHistory'
import createSelectedPlansSlice, { selectedPlansInitialState } from './selectedPlans'
import { useOpenFrom } from '../components/Header/Header'
import { devtools, subscribeWithSelector } from 'zustand/middleware'
import immer from 'immer'
import { queryClient } from '../queryClient'
import { StoreSlices } from './types/storeSlices'

export type State = StoreSlices & {
  reset: () => void
}

const createGojiStore = create<StoreSlices>()(
  devtools(
    subscribeWithSelector(
      immer((set, get, api) => ({
        ...createAddressSlice(set, get, api),
        ...createBestPlansSlice(set, get, api),
        ...createCarrierSelectorSlice(set, get, api),
        ...createCurrentPlanSlice(set, get, api),
        ...createExpandedPlansSlice(set, get, api),
        ...createNetworkMeterResultsSlice(set, get, api),
        ...createNetworkQualitySlice(set, get, api),
        ...createPlansInteractionSlice(set, get, api),
        ...createQuestionsSlice(set, get, api),
        ...createSearchHistorySlice(set, get, api),
        ...createSelectedPlansSlice(set, get, api),
        reset: () => {
          queryClient.clear()
          set(() => ({
            ...addressInitialState,
            ...bestPlanInitialState,
            ...carrierSelectorInitialState,
            ...currentPlanInitialState,
            ...expandedPlansInitialState,
            ...networkMeterResultsInitialState,
            ...networkQualityInitialState,
            ...planInteractionInitialState,
            ...questionsInitialState,
            ...searchHistoryInitialState,
            ...selectedPlansInitialState
          }))
        }
      }))
    )
  )
)

export const useGeneralStore = createGojiStore
export const useEditStore = createGojiStore

export const useBoundStore = ((selector) => {
  const openFrom = useOpenFrom()
  const generalStore = useGeneralStore(selector)
  const editStore = useEditStore(selector)

  if (!!openFrom) {
    return editStore
  }
  return generalStore
}) as UseBoundStore<StoreApi<StoreSlices> & [['zustand/subscribeWithSelector', never], ['zustand/immer', never]]>

export const resetAllSlices = () => {
  useGeneralStore.getState().reset()
  useEditStore.getState().reset()
}
