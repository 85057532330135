export const Logger = {
  debug: (...msgs: any[]) => {
    console.debug(...msgs)
  },
  info: (...msgs: any[]) => {
    console.info(...msgs)
  },
  warn: (...msgs: any[]) => {
    console.warn(...msgs)
  },
  error: (...msgs: any[]) => {
    console.error(...msgs)
  }
}
