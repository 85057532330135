import { cx } from '@emotion/css'
import { MainNavigationPaths, RoutePaths } from '../../paths'
import { IonPage, IonContent, IonIcon, IonFooter, IonButton } from '@ionic/react'
import { UsePlan } from '../../services/planService/Plan.service'
import { EllipseBackground } from '../../components/EllipseBackground/EllipseBackground'
import React from 'react'
import PlansSwipeDeck, { PlansSwipeDeckRef } from './components/PlanSwipe/PlansSwipeDeck'
import { useIsMobileSize } from '../../utils/hooks/useWindowSize'
import { EllipseBackgroundDesktop } from '../../components/EllipseBackground/EllipseBackgroundDesktop'
import { ellipseBackgroundStyles, footerStyles, PlanSwipePageStyles } from './PlanSwipePage.styles'
import { useBoundStore } from '../../store'
import { PlanInteraction } from '../../store/plansInteraction'
import { Redirect, useHistory } from 'react-router'
import { useAutoChatStorage } from './hooks/useChatStorage'
import { IChatFlow } from './types/ChatStep'
import ChatHeaderComponent from './components/ChatHeaderComponent'

const hasBeenBoardedOnSwipePageStorageKey = 'hasBeenBoardedOnSwipePage'

const useHasBeenBoardedOnSwipePage = () => {
  const hasBeenBoardedOnSwipePage = localStorage.getItem(hasBeenBoardedOnSwipePageStorageKey)
  const setHasBeenBoardedOnSwipePage = () => {
    localStorage.setItem(hasBeenBoardedOnSwipePageStorageKey, 'true')
  }
  return { hasBeenBoardedOnSwipePage, setHasBeenBoardedOnSwipePage }
}

const directionToSwipeType = (direction: 'left' | 'right' | 'up') => {
  switch (direction) {
    case 'left':
      return PlanInteraction.PASS
    case 'right':
      return PlanInteraction.LIKE
    case 'up':
      return PlanInteraction.UNSURE
  }
}

const PlanSwipePage = () => {
  const history = useHistory()
  const isMobile = useIsMobileSize()
  const [setInteraction, addFavoritePlanId] = useBoundStore((state) => [state.setInteraction, state.addFavoritePlanId])
  const { restartChat } = useAutoChatStorage<IChatFlow>()
  const deckRef = React.useRef<PlansSwipeDeckRef>(null)
  const { hasBeenBoardedOnSwipePage, setHasBeenBoardedOnSwipePage } = useHasBeenBoardedOnSwipePage()
  const { userPreferences } = useAutoChatStorage()
  const plansQuery = UsePlan.useRecommendedPlansControlled({
    filters: { nLinesFilter: userPreferences?.numLines ?? 1, nPlansFilter: 5 }
  })

  const [onBoardingState, setOnBoardingState] = React.useState<0 | 1 | 2 | 'done'>(
    hasBeenBoardedOnSwipePage ? 'done' : 0
  )

  const handleOnBoardingStateChange = () => {
    if (onBoardingState === 0) {
      setOnBoardingState(1)
    } else if (onBoardingState === 1) {
      setOnBoardingState(2)
    } else if (onBoardingState === 2) {
      setOnBoardingState('done')
      setHasBeenBoardedOnSwipePage()
    }
  }

  if (plansQuery.data && !plansQuery.data.length) {
    const search = new URLSearchParams(history.location.search)
    search.set('lines', userPreferences?.numLines + '')
    return <Redirect to={MainNavigationPaths.recommendedPlans + search.toString()} />
  }

  return (
    <IonPage className={cx(PlanSwipePageStyles, onBoardingState !== 'done' && 'overlay')}>
      <ChatHeaderComponent
        title='Plan Finder'
        resetLink={RoutePaths.chatFinder + history.location.search}
        onResetClick={restartChat}
      />
      <IonContent className='content-wrapper' forceOverscroll={false}>
        {isMobile ? <EllipseBackground className={ellipseBackgroundStyles} /> : <EllipseBackgroundDesktop />}
        <div className='content'>
          <PlansSwipeDeck
            plans={plansQuery.data ?? []}
            isLoading={plansQuery.isLoading}
            onSwipe={(plan, direction) => {
              if (direction === 'right') addFavoritePlanId(plan.planId)
              setInteraction(plan.planId, directionToSwipeType(direction))
            }}
            onEnd={() => {
              history.push(RoutePaths.planSwipeTransition + history.location.search)
            }}
            ref={deckRef}
          />
        </div>
      </IonContent>
      <IonFooter className={footerStyles}>
        <div className={cx('footer-button', { 'tooltip-visible': onBoardingState === 1 })}>
          <IonButton fill='clear' onClick={() => deckRef.current?.swipe('left')}>
            <div className='inner'>
              <IonIcon src='/assets/images/planSwipe/pass.svg' />
              Pass
            </div>
          </IonButton>
          <div className={cx('onboarding-tooltip start', { visible: onBoardingState === 1 })}>
            <h1>Not feeling it?</h1>
            <p>
              Swipe <strong>left</strong> on a plan to dismiss it from your recommendations.
            </p>
            <IonButton onClick={handleOnBoardingStateChange}>NEXT</IonButton>
          </div>
        </div>
        <div className={cx('footer-button', { 'tooltip-visible': onBoardingState === 2 })}>
          <IonButton fill='clear' onClick={() => deckRef.current?.swipe('up')}>
            <div className='inner'>
              <IonIcon src='/assets/images/planSwipe/unsure.svg' />
              Unsure
            </div>
          </IonButton>
          <div className={cx('onboarding-tooltip center', { visible: onBoardingState === 2 })}>
            <h1>Not sure?</h1>
            <p>
              Swipe <strong>up</strong> on a plan if you're unsure. It might still show up in your recommendations later
              based on your preferences.
            </p>
            <IonButton onClick={handleOnBoardingStateChange}>GOT IT</IonButton>
          </div>
        </div>
        <div className={cx('footer-button', { 'tooltip-visible': onBoardingState === 0 })}>
          <IonButton fill='clear' onClick={() => deckRef.current?.swipe('right')}>
            <div className='inner'>
              <IonIcon src='/assets/images/planSwipe/like.svg' />
              Like
            </div>
          </IonButton>
          <div className={cx('onboarding-tooltip end', { visible: onBoardingState === 0 })}>
            <h1>Interested in a plan?</h1>
            <p>
              Swipe <strong>right</strong> on a plan to like it. We'll keep it for you and recommend similar plans.
            </p>
            <IonButton onClick={handleOnBoardingStateChange}>NEXT</IonButton>
          </div>
        </div>
      </IonFooter>
    </IonPage>
  )
}

export default PlanSwipePage
