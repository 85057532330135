import { Libraries } from '@googlemaps/js-api-loader'
import produce from 'immer'
import { StateCreator } from 'zustand'
import { NetworkType, NetworkTypeValue } from '../services/NetworkType.service'
import { Address } from './address'

export type CarrierId = 'att' | 't-bird' | 't-mobile' | 'us-cellular' | 'verizon' | 'others' | '...'

export type QualityByType = {
  text: number
  email: number
  phone: number
  video: number
  streaming: number
}

export type Coverage = {
  address: Address
  network: {
    carrierName: string
    carrierId: CarrierId
    '4g': QualityByType
    '5g': QualityByType
  }
}

export type NetworkQualityState = {
  networkType: NetworkTypeValue
  map: {
    center?: google.maps.LatLng
    zoom: number
    maxZoom: number
    libraries: Libraries
  }
  coverage: {
    results: Coverage[]
    carrierName?: string
    carrierId?: CarrierId
  }
}

export type NetworkQualityActions = {
  setMap: (state: Partial<NetworkQualityState['map']>) => void
  setCoverage: (state: Partial<NetworkQualityState['coverage']>) => void
  setNetworkType: (state: Partial<NetworkQualityState['networkType']>) => void
}

export const networkQualityInitialState: NetworkQualityState = {
  networkType: NetworkType['5G'],
  map: {
    zoom: 4,
    maxZoom: 18,
    libraries: ['places', 'geometry', 'marker']
  },
  coverage: {
    results: []
  }
}

const createNetworkQualitySlice: StateCreator<
  NetworkQualityState & NetworkQualityActions,
  [],
  [],
  NetworkQualityState & NetworkQualityActions
> = (set, get) => {
  const setObject = (key: string, next: Record<string, unknown>) => {
    set(
      produce((state) => {
        state[key] = { ...state[key], ...next }
      })
    )
  }

  return {
    ...networkQualityInitialState,
    setMap: (next: Partial<NetworkQualityState['map']>) => setObject('map', next),
    setCoverage: (next: Partial<NetworkQualityState['coverage']>) => setObject('coverage', next),
    setNetworkType: (next: Partial<NetworkQualityState['networkType']>) =>
      set(
        produce((state) => {
          state.networkType = next
        })
      )
  }
}

export default createNetworkQualitySlice
