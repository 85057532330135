import { css } from '@emotion/css'

export const BackButtonStyles = css`
  height: 32px;
  width: 32px;
  ion-icon {
    width: 32px;
    height: 32px;
  }
`

export const FavoriteButtonStyles = css`
  background-color: transparent;
  color: var(--ion-color-dark);
  margin-top: 8px;
  width: 24px;
  height: 24px;
  .heart-icon {
    object-fit: contain;
    width: 20px;
    height: 20px;
  }
  justify-self: end;
`

export const InnerHeaderStyles = css`
  font-weight: 900;
  width: 100%;
  padding: 0 16px;
  display: flex;

  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 18px;
    text-align: center;
  }

  .close-button {
    width: 40px;
    height: 40px;
  }
`
