import * as React from 'react'

export function useWindowSize() {
  const [size, setSize] = React.useState<{ width: number | null; height: number | null }>({
    width: null,
    height: null
  })

  React.useLayoutEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return size
}

export const MOBILE_BREAKPOINT = 768
export const MOBILE_BREAKPOINT_MAX = MOBILE_BREAKPOINT - 1
export function useIsMobileSize() {
  const size = useWindowSize()
  return Boolean(size.width && size.width < MOBILE_BREAKPOINT)
}
