import * as React from 'react'
import 'swiper/css'
import 'swiper/css/pagination'
import { cx } from '@emotion/css'
import { IonContent, IonImg } from '@ionic/react'
import { HomePageHeaderStyles, HomePageTitleStyles, HomePageStyles } from './HomePage.styles'
import { UseCarrier } from '../../services/Carrier.service'
import { BaseHeader } from '../../components/Header/BaseHeader'
import useAuthStore from '../../store/auth'
import { EllipseBackground } from '../../components/EllipseBackground/EllipseBackground'
import { EllipseBackgroundDesktop } from '../../components/EllipseBackground/EllipseBackgroundDesktop'
import { DesktopHeader } from './DesktopHeader/DesktopHeader'
import { DesktopFooter } from './DesktopFooter/DesktopFooter'
import { TopPicks } from './TopPicks/TopPicks'
import { JustForYou } from './JustForYou/JustForYou'
import OnboardingDesktopCarousel from '../../components/OnboardingCarousel/OnboardingDesktopCarousel'
import { Helmet } from 'react-helmet-async'
import { HideMobileStyles } from '../../utils/styles/ViewportUtils.styles'
import { UsePlan } from '../../services/planService/Plan.service'

function usePreload() {
  UsePlan.useAllPlans()
  UseCarrier.useAllCarriers()
}

export const Header = (props: { className?: string }) => {
  return (
    <BaseHeader className={cx(HomePageHeaderStyles, props.className)} border={true}>
      <IonImg className='goji-logo' src='/assets/images/goji.svg' alt='Goji logo' />
    </BaseHeader>
  )
}

function HomePage() {
  usePreload()
  const [currentUser] = useAuthStore((state) => [state.currentUser])

  return (
    <>
      <Helmet>
        <title>Find The Best Cell Phone Plan - Goji</title>
        <meta
          name='description'
          content='Searching for the perfect cell phone plan? Goji helps you compare prices, data, and features across carriers to find the best fit for your lifestyle and budget.'
        />
      </Helmet>
      <Header />
      <DesktopHeader className={HideMobileStyles} />
      <OnboardingDesktopCarousel />
      <IonContent className={cx('ion-no-padding', HomePageStyles)}>
        <div className='homepage-padding'>
          <div className='ellipse-desktop'>
            <EllipseBackgroundDesktop />
          </div>
          <div className='ellipse-mobile'>
            <EllipseBackground />
          </div>
          <h1 className={cx('welcome-title', HomePageTitleStyles)}>
            {currentUser?.token ? 'Welcome back!' : 'Welcome!'}
          </h1>
          <TopPicks />
          <JustForYou />

          <div className='ion-padding-vertical' />
          <DesktopFooter className='homepage-footer' />
        </div>
      </IonContent>
    </>
  )
}

export default HomePage
