import { cx } from '@emotion/css'
import { EllipseBackgroundStyles } from './EllipseBackground.styles'

export function EllipseBackgroundLong({ className }: { className?: string }) {
  return (
    <div className={cx(EllipseBackgroundStyles, className)}>
      <svg width='428' height='331' viewBox='0 0 428 331' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <ellipse
          cx='214.001'
          cy='97.9998'
          rx='463'
          ry='231.5'
          transform='rotate(180 214.001 97.9998)'
          fill='currentColor'
        />
      </svg>
    </div>
  )
}
