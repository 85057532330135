import { registerPlugin } from '@capacitor/core'
import { isPlatform } from '@ionic/core'
import { Logger } from '../utils/Logger'

export interface M2CatalystSdkPluginOptions {
  requestBackgroundLocation(): Promise<{ isGranted: boolean; shouldShowRationale: boolean }>
  requestLocationPermissions(): Promise<{ isGranted: boolean; shouldShowRationale: boolean }>
  hasBackgroundLocation(): Promise<{ isGranted: boolean }>
  hasLocationPermissions(): Promise<{ isGranted: boolean }>
  pluginInitSdk(): Promise<void>
}

const M2CatalystSdkPlugin = registerPlugin<M2CatalystSdkPluginOptions>('M2CatalystSdkPlugin')

if (isPlatform('android') && !isPlatform('mobileweb')) {
  try {
    M2CatalystSdkPlugin.pluginInitSdk()
  } catch (e) {
    Logger.error('Cannot init M2 sdk:', e)
  }
}

export default M2CatalystSdkPlugin
