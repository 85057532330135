import * as React from 'react'
import { LoaderComponent } from '../../components/BackendSyncLoader/LoaderComponent'

export const withSuspense =
  <P extends object>(
    WrappedComponent: React.ComponentType<P>,
    fallback: React.ReactNode = <LoaderComponent />
  ): React.ComponentType<P> =>
  (props: P) =>
    (
      <React.Suspense fallback={fallback}>
        <WrappedComponent {...props} />
      </React.Suspense>
    )
