import API, { IApi } from '../../api'
import { stateToBackendUser } from '../../pages/home/hooks/useBackendUser'
import { Address, LabelType } from '../../store/address'
import { Logger } from '../../utils/Logger'
import isEqual from 'lodash/isEqual'
import { StoreSlices } from '../../store/types/storeSlices'
import { BackendUser } from '../../types/BackendUser'
import { PlanNotFound } from '../../pages/current-plan/PlanNotFound'
import { chatSourceService, ChatSourceService } from '../chat/Chat.service'

// TODO: Once backend works with Address[] only, remove this condition
function mapOldLocationsToAddresses(locations: BackendUser['locations'] = []) {
  return locations.map((location) => {
    const { label, placeId, lat, lon } = location as { label: string; placeId: string; lat?: number; lon?: number }
    return {
      placeId: placeId,
      latitude: lat || 0,
      longitude: lon || 0,
      addressName: label,
      labelType: LabelType.Other,
      label: label
    } as Address
  })
}

export class BackendSyncService {
  private lastSavedUser: any
  constructor(public api: IApi, public chatSourceService: ChatSourceService) {}

  static userToStore(user: BackendUser, addresses: Address[]): Partial<StoreSlices> {
    const planPreferences = user.plan_preferences ?? {
      noPlanSelected: undefined,
      carrierId: undefined,
      useUnlimitedDataPlan: undefined,
      phoneLinesNeeded: undefined,
      reasonsForSwitch: []
    }

    return {
      currentPlanId: planPreferences.noPlanSelected ? PlanNotFound.planId : user.current_plan || undefined,
      userCarrierId: planPreferences.carrierId,
      useUnlimitedDataPlan: planPreferences.useUnlimitedDataPlan,
      targetBudget: Number(user.plan_budget),
      phoneLinesNeeded: planPreferences.phoneLinesNeeded,
      reasonsForSwitch: planPreferences.reasonsForSwitch,
      addresses,
      selectedAddress: addresses[0],
      favoritePlanIds: user.favourite_plan_ids ?? [],
      searchedPlansHistory: user.searched_plans ?? [],
      searchedAddressHistory: user.searched_addresses ?? []
    }
  }

  async pushProfileToBackend(state: StoreSlices) {
    const newUser = stateToBackendUser(state)

    if (isEqual(this.lastSavedUser, newUser)) {
      return
    }
    this.lastSavedUser = newUser
    try {
      await this.api.put('/profile', newUser)
    } catch (error) {
      this.lastSavedUser = undefined
      Logger.error(error)
    }
  }

  async backwardsCompatibleAddresses(userLocations: BackendUser['locations'], newAddresses: Address[]) {
    try {
      const oldAddresses: Address[] = mapOldLocationsToAddresses(userLocations)
      const addresses = [
        ...newAddresses,
        ...oldAddresses.filter((address) => !newAddresses.some((a) => a.placeId === address.placeId))
      ]

      return addresses
    } catch (e) {
      Logger.error('cannot load addresses', e, userLocations)
      return []
    }
  }

  async pushAddressesToBackend(addresses: Address[]) {
    const endpoint = '/profile/addresses'

    try {
      await this.api.put(
        endpoint,
        addresses
          .filter((address) => !address.localMemory)
          .map(
            (address) =>
              ({
                placeId: address.placeId,
                latitude: address.latitude,
                longitude: address.longitude,
                label: address.label,
                labelType: address.labelType,
                addressName: address.addressName
              } as Address)
          )
      )
    } catch (error) {
      Logger.error(error)
    }
  }
}

export const defaultSyncService = new BackendSyncService(API, chatSourceService)
