import { css } from '@emotion/css'

export const DesktopHeaderStyles = css`
  display: flex;
  padding: 0 8.5%;
  color: var(--goji-primary-black);
  box-shadow: 0px 1px 0px 0px #00000040;
  background-color: var(--goji-primary-lime);
  min-width: 980px;
  height: 76px;

  ion-row {
    gap: 32px;
  }

  .btn-download {
    text-transform: uppercase;
    font-size: 12px;
    height: 44px;
  }
`

export const HeaderLinkStyles = css`
  color: var(--goji-primary-black);
  cursor: pointer;

  &.active {
    font-weight: 900;
  }
`
