import { GridColorScale } from '../utils/signalStrength'

export const BinSize = {
  s10: 10,
  s12: 12,
  s14: 14,
  s17: 17,
  US: 8
} as const

export const loadPairs = {
  smallBin: { z: 14, binSize: BinSize.s17 },
  mediumBin: { z: 11, binSize: BinSize.s14 },
  largeBin: { z: 9, binSize: BinSize.s12, boost: 1.7 },
  usBin: { z: 5, binSize: BinSize.US, boost: 2.05 }
}

export function getBoosted(tileZ: number, networkPerformance: number): number {
  const boostValue = Object.values(loadPairs).find((pair) => pair.z === tileZ)
  if (!boostValue || !('boost' in boostValue)) {
    return networkPerformance
  }

  return Math.min(networkPerformance * boostValue.boost, GridColorScale.length)
}

export const usBoundary = 0
export const largeBoundary = 8
export const mediumBoundary = 10
export const smallBoundary = 13
