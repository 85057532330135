import { css, cx } from '@emotion/css'
import shallow from 'zustand/shallow'
import { MainNavigationPaths, RoutePaths } from '../../../paths'
import { UseCarrier } from '../../../services/Carrier.service'
import { useBoundStore } from '../../../store'
import { useFlag } from '../../../utils/feature-flags/useFlag'
import { FeatureFlag } from '../../../utils/FeatureFlags'
import { ResetMargin } from '../../../utils/styles/MarginUtils.styles'
import { useIsInitialTimeline } from '../../llm/hooks/useChatStorage'
import { AppPromotionCard } from '../AppPromotionCard.tsx/AppPromotionCard'
import { HomePageTitleStyles } from '../HomePage.styles'
import { BestCarrierCard } from './BestCarrierCard'
import { JustForYouCard } from './JustForYouCard'
import { NetworkMeterResultsCard } from './NetworkMeterResultsCard'
import { SavedPlans } from './SavedPlans'
import { Top3Plans } from './Top3Plans'
import { useResumePage } from './useResumePage'
import { trackAmplitudeEvent } from '../../../utils/amplitude'

const handleOnClickTrackStartEvent = () => {
  trackAmplitudeEvent('ChatBarry - Start Plan Finding')
}

const handleOnClickTrackResumeEvent = () => {
  trackAmplitudeEvent('ChatBarry - Resume Plan Finding')
}

function PlanFlowSwitch() {
  const resumePage = useResumePage()
  const chatEnabled = useFlag(FeatureFlag.CHAT_UI)

  const isInitialTimeline = useIsInitialTimeline()

  if (chatEnabled ? isInitialTimeline : resumePage === RoutePaths.carrierSelector) {
    return (
      <JustForYouCard
        title='Looking for a better plan?'
        description='Answer a few quick questions to get started!'
        imageAlt='screenshot of reasons for switching your plan'
        logo_url='/assets/images/welcome/ai-swiping-background.png'
        linkText='Find my plan'
        imageContainerClass='better-plan-image-container'
        link={resumePage}
        onClick={handleOnClickTrackStartEvent}
      />
    )
  }

  if (resumePage === MainNavigationPaths.results) {
    return (
      <div className='plan-flow-switch'>
        <SavedPlans />
        <Top3Plans />
      </div>
    )
  }

  return (
    <JustForYouCard
      title='Almost there...'
      description='Finish answering just a few more questions to see what plans we recommend for you.'
      imageAlt='screenshot of reasons for switching your plan'
      logo_url='/assets/images/welcome/ai-swiping-background.png'
      linkText='Resume plan search'
      imageContainerClass='better-plan-image-container'
      link={resumePage}
      onClick={handleOnClickTrackResumeEvent}
    />
  )
}

export function MapCardSwitch() {
  const bestCarrier = UseCarrier.useBestCarrier()
  const bestCarriers = UseCarrier.useBestCarriers()
  const [addresses] = useBoundStore((state) => [state.addresses], shallow)

  if (!bestCarriers.isLoading && !bestCarrier) {
    return (
      <JustForYouCard
        title='Experiencing dropped calls?'
        description='Check to see how good (or bad) coverage is across carriers for your favorite locations.'
        imageAlt='screenshot of the coverage map'
        logo_url='/assets/images/welcome/dropped-calls-background.jpg'
        linkText='View my coverage'
        link={MainNavigationPaths.map}
      />
    )
  }

  return <BestCarrierCard isLoading={bestCarriers.isLoading} carrier={bestCarrier} addresses={addresses} />
}

function NetworkMeterSwitch() {
  const [hasNetworkMeterResults] = useBoundStore((state) => [state.hasNetworkMeterResults], shallow)

  if (hasNetworkMeterResults) {
    return <NetworkMeterResultsCard />
  }

  return (
    <JustForYouCard
      title='Want to see how fast your network is?'
      description='Get a real-time measurement of your cellular speeds'
      imageAlt='screenshot of the network meter'
      imageContainerClass='network-performance-image-container'
      logo_url='/assets/images/welcome/network-performance-background.svg'
      linkText='Test my network'
      link={MainNavigationPaths.meter}
    />
  )
}

const JustForYouStyles = css`
  margin-top: 24px;

  .plan-flow-switch {
    .swiper-slide {
      max-width: 392px;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .better-plan-image-container {
    width: 121px;
    min-height: 182px;
    padding-top: 18px;
  }

  .network-performance-image-container {
    width: 119px;
    min-height: 156px;
  }

  @media screen and (min-width: 768px) {
    padding: 0 8.5%;
    margin-top: 56px;
    ul {
      gap: 56px;
    }
  }
`

export function JustForYou() {
  return (
    <section className={JustForYouStyles}>
      <h2 className={cx(ResetMargin, HomePageTitleStyles)}>Just for you</h2>

      <ul>
        <PlanFlowSwitch />
        <MapCardSwitch />
        <NetworkMeterSwitch />

        <div className='promotion-card-wrapper'>
          <AppPromotionCard />
        </div>
      </ul>
    </section>
  )
}
