import { css } from '@emotion/css'

export const PlanSwipePageStyles = css`
  .content-wrapper {
    .content {
      padding: 16px;
    }
  }

  &.overlay {
    ::after {
      display: block;
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      z-index: 15;
    }
  }
`

export const footerStyles = css`
  background: white;
  box-shadow: 0px -2px 0px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: space-around;
  padding: 16px 0;
  z-index: 16;

  .footer-button {
    position: relative;

    > ion-button {
      ::part(native) {
        padding: 0;
      }

      color: var(--ion-color-dark);
      .inner {
        display: flex;
        flex-direction: column;
        gap: 4px;
        > ion-icon {
          width: 64px;
          height: 64px;
        }
      }
    }

    .onboarding-tooltip {
      background: white;
      color: var(--ion-color-dark);
      min-width: 250px;
      max-width: 300px;
      border-radius: 8px;
      padding: 16px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);

      z-index: 17;

      position: absolute;
      bottom: 0;
      transform: translateY(-120px);

      display: none;
      &.visible {
        display: block;
      }

      h1 {
        margin: 0 0 8px 0;
        font-size: 18px;
        font-weight: 900;
      }

      p {
        margin: 0;
        font-size: 16px;

        strong {
          color: var(--ion-color-primary);
        }
      }

      > ion-button {
        --border-radius: 30px;
        width: 100%;
        margin-top: 24px;
        font-weight: 700;
        font-size: 14px;
      }

      &.end {
        left: 50%;
        transform: translateY(-120px) translateX(-80%);
      }
      &.start {
        right: 50%;
        transform: translateY(-120px) translateX(80%);
      }
      &.center {
        left: 50%;
        transform: translateY(-120px) translateX(-50%);
      }
    }

    &.tooltip-visible::after {
      z-index: 17;
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -30px;
      border: 10px solid transparent;
      border-top-color: white;
    }
  }
`

export const ellipseBackgroundStyles = css`
  top: unset;
`
