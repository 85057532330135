import create from 'zustand'

type MapBoardingStore = {
  hasBeenOnboardedOnMapTray: boolean
  completeOnboard: () => void
}

const HAS_BEEN_ONBOARDED_ON_MAP_TRAY_KEY = 'hasBeenOnboardedOnMapTray'

const useMapOnboardingStore = create<MapBoardingStore>((set) => ({
  hasBeenOnboardedOnMapTray: window.localStorage.getItem(HAS_BEEN_ONBOARDED_ON_MAP_TRAY_KEY) === 'true',
  completeOnboard: () =>
    set(() => {
      window.localStorage.setItem(HAS_BEEN_ONBOARDED_ON_MAP_TRAY_KEY, 'true')
      return { hasBeenOnboardedOnMapTray: true }
    })
}))

export default useMapOnboardingStore
