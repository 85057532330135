import shallow from 'zustand/shallow'
import { useGeneralStore } from '../../../store'
import { Address } from '../../../store/address'

export function useUnsavedAddresses() {
  const [generalStoreAddresses, setAddress, setSelectedAddress] = useGeneralStore(
    (state) => [state.addresses, state.setAddress, state.setSelectedAddress],
    shallow
  )

  function toggleAddressSaved(address: Address) {
    setAddress({ ...address, localMemory: !address.localMemory }, getAddressIndex(address))
  }

  function getAddressIndex(address: Address) {
    return generalStoreAddresses.findIndex((addr) => addr.placeId === address.placeId)
  }

  function setLocalAddress(address: Address, index?: number) {
    setAddress({ ...address, localMemory: true }, index)
    setSelectedAddress(address)
  }

  return {
    setLocalAddress,
    toggleAddressSaved
  }
}
