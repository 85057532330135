import { IonCol, IonRow, IonText } from '@ionic/react'
import { NumericFormat } from 'react-number-format'
import { DATA_UNLIMITED, SMS_UNLIMITED, VOICE_UNLIMITED } from '../../../services/planService/Preferences'
import { CompareColumn, PlanDiff } from '../utils/getDifferences'

export const renderSimpleRow = (
  label: string,
  key: Exclude<CompareColumn, 'addons'>,
  planDiffs: PlanDiff[],
  highlight: boolean,
  numeric = false,
  prefix = ''
) => {
  const parseAllowance = (key: string, allowance?: string | number) => {
    if (key === 'data' && allowance === DATA_UNLIMITED) return 'Unlimited'
    if (key === 'voice' && allowance === VOICE_UNLIMITED) return 'Unlimited'
    if (key === 'sms' && allowance === SMS_UNLIMITED) return 'Unlimited'
    if (key === 'data' && allowance) return `${+allowance ?? 0}GB`
    return allowance
  }

  return (
    <IonRow className='border-bottom'>
      <IonCol className='dark'>
        <IonText>{label}</IonText>
      </IonCol>
      {planDiffs.length
        ? planDiffs.map((planDiff) => (
            <IonCol key={planDiff.plan.planId} className={highlight && planDiff[key] ? 'highlight' : ''}>
              {!numeric ? (
                <IonText> {parseAllowance(key, planDiff.plan[key])} </IonText>
              ) : (
                <NumericFormat value={planDiff.plan[key]} displayType='text' prefix={prefix} />
              )}
            </IonCol>
          ))
        : null}
    </IonRow>
  )
}
