function getZ5BasedTile(coord: number, z: number) {
  return coord >> (z - 5)
}

export function withinBbox(tile: { x: number; y: number; z: number }, bbox: Bbox) {
  const x = getZ5BasedTile(tile.x, tile.z)
  const y = getZ5BasedTile(tile.y, tile.z)
  return x >= bbox.xmin && x <= bbox.xmax && y >= bbox.ymin && y <= bbox.ymax
}

type Bbox = {
  xmin: number
  xmax: number
  ymin: number
  ymax: number
}

const usBbox = {
  xmin: 4,
  xmax: 10,
  ymin: 10,
  ymax: 13
} as const

const alaskaBbox = {
  xmin: 1,
  xmax: 3,
  ymin: 6,
  ymax: 9
} as const

const puertoRicoBbox = {
  xmin: 10,
  xmax: 10,
  ymin: 14,
  ymax: 14
}

const hawaiiBbox = {
  xmin: 1,
  xmax: 2,
  ymin: 13,
  ymax: 14
}

export const bboxes = [usBbox, alaskaBbox, puertoRicoBbox, hawaiiBbox]
