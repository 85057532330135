import create, { StateCreator } from 'zustand'

export interface CurrentUser {
  email: string
  token: string
}
export type AuthState = {
  currentUser?: CurrentUser | null
}

export type AuthActions = {
  setCurrentUser: (currentUser: CurrentUser | undefined | null) => void
}

const createAuthSlice: StateCreator<AuthState & AuthActions, [], [], AuthState & AuthActions> = (set, get) => ({
  currentUser: undefined,

  setCurrentUser: (currentUser) => {
    set(() => ({ currentUser }))
  }
})

// This is a separate store because the current user should not be cleaned up with the reset button
const useAuthStore = create(createAuthSlice)

export default useAuthStore
