import create from 'zustand'
import { getBoundsFromAddresses } from '../../../utils/Address'
import { Address } from '../../../store/address'

interface MinimapStore {
  owner: number | undefined
  setOwner: (newOwner: number | undefined) => void
}

export const useMinimapStore = create<MinimapStore>()((set) => ({
  owner: undefined,
  setOwner: (newOwner) => set(() => ({ owner: newOwner }))
}))

const getClosestAddress = (center: google.maps.LatLng, addresses: Address[]) => {
  const sortedAddresses = addresses
    .map((address) => {
      return {
        distance: google.maps.geometry.spherical.computeDistanceBetween(
          new google.maps.LatLng(address.latitude, address.longitude),
          center
        ),
        address
      }
    })
    .sort((address1, address2) => address1.distance - address2.distance)

  return sortedAddresses[0]?.address
}

export const calcArea = (A: google.maps.LatLngBounds) => {
  return google.maps.geometry.spherical.computeArea(A)
}

interface MinimapInitializer {
  map: google.maps.Map
  parentMap: google.maps.Map
  addresses: Address[]
  onChangeVisibility: (visible: boolean) => void
}

export class MinimapEventController {
  private static instance: MinimapEventController
  static replaceInstance(initializer: MinimapInitializer) {
    if (MinimapEventController.instance) {
      MinimapEventController.instance.destroy()
    }
    MinimapEventController.instance = new MinimapEventController(initializer)
    return MinimapEventController.instance
  }

  private minimap: google.maps.Map
  private parentMap: google.maps.Map
  private addresses: Address[]
  constructor(private options: MinimapInitializer) {
    this.minimap = options.map
    this.parentMap = options.parentMap
    this.addresses = options.addresses
  }

  setAddresses(addresses: Address[]) {
    this.addresses = addresses
  }

  syncMap(sourceMap: google.maps.Map, destinationMap: google.maps.Map) {
    const center = sourceMap.getCenter()
    center && destinationMap.setCenter(center)
  }

  panToClosestAddress() {
    const center = this.minimap.getCenter()
    if (!center) {
      return
    }

    const closestAddress = getClosestAddress(center, this.addresses)

    this.parentMap.panTo(new google.maps.LatLng(closestAddress.latitude, closestAddress.longitude))
    this.minimap.panTo(new google.maps.LatLng(closestAddress.latitude, closestAddress.longitude))
  }

  fitsAddresses(): boolean {
    const parentBounds = this.parentMap.getBounds()
    if (!parentBounds) {
      return false
    }
    const addressesBounds = getBoundsFromAddresses(this.addresses)

    return (
      parentBounds.contains(addressesBounds.getNorthEast()) && parentBounds.contains(addressesBounds.getSouthWest())
    )
  }

  private largerThanParent(): boolean {
    const bounds = this.minimap.getBounds()
    const parentBounds = this.parentMap.getBounds()
    if (!bounds || !parentBounds) {
      return true
    }
    return calcArea(bounds) > calcArea(parentBounds)
  }

  private listeners: google.maps.MapsEventListener[] = []

  // owner is used so when one map updates, the other one is not listening.
  // It avoids a loop
  static readonly minimapId = 1
  static readonly parentMapId = 2

  getOwner(): number | undefined {
    return useMinimapStore.getState().owner
  }
  setOwner(newOwner: number | undefined) {
    useMinimapStore.getState().setOwner(newOwner)
  }

  listen() {
    const addListener = (map: google.maps.Map, evt: string, listener: Function) => {
      const l = map.addListener(evt, listener)
      this.listeners.push(l)
      return l
    }

    const addSyncedMap = (map: google.maps.Map, listener: Function, id: number) => {
      addListener(map, 'dragstart', () => {
        this.setOwner(id)
      })

      addListener(map, 'dragend', () => {
        this.setOwner(undefined)
      })

      addListener(map, 'bounds_changed', () => {
        if (this.getOwner() === id) {
          listener()
        }
      })

      return id
    }

    addSyncedMap(
      this.minimap,
      () => {
        this.syncMap(this.minimap, this.parentMap)
      },
      MinimapEventController.minimapId
    )

    addSyncedMap(
      this.parentMap,
      () => {
        this.syncMap(this.parentMap, this.minimap)
      },
      MinimapEventController.parentMapId
    )

    addListener(this.parentMap, 'bounds_changed', () => {
      if (!this.largerThanParent()) {
        this.options.onChangeVisibility(false)
      } else {
        this.options.onChangeVisibility(!this.fitsAddresses())
      }
    })

    addListener(this.minimap, 'dragend', () => {
      this.panToClosestAddress()
    })
  }

  destroy() {
    this.listeners.forEach((listener) => listener.remove())
  }
}
