import useAuthStore from '../store/auth'
import API from '../api'
import { Logger } from '../utils/Logger'

export const RecentSearchService = {
  addRecentPlanSearch: async (search: string) => {
    if (search === '') return
    if (!useAuthStore.getState().currentUser) {
      Logger.debug('No user logged in, cannot add recent plan search')
      return
    }
    const recentPlans: string[] = await API.post('/profile/recent-plan-search', { planSearch: search })
    return recentPlans
  },

  addRecentAddressSearch: async (placeId: string) => {
    if (!placeId) return
    if (!useAuthStore.getState().currentUser) {
      Logger.debug('No user logged in, cannot add recent address search')
      return
    }

    const recentAddresses: string[] = await API.post('/profile/address-search-in-historical', { placeId })
    return recentAddresses
  }
}
