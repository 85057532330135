import React from 'react'
import { PlanOverlayPassStyles } from '../PlanSwipe.styles'
import OverlayBlock, { PlanOverlayType } from './OverlayBlock'

const PlanOverlayPass = React.forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div className={PlanOverlayPassStyles} ref={ref}>
      <OverlayBlock type={PlanOverlayType.PASS} />
      <span>Nah, not for me.</span>
    </div>
  )
})

export default PlanOverlayPass
