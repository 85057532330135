import * as React from 'react'
import { useHistory } from 'react-router'

export const useNavigateAway = (cb: () => void) => {
  const history = useHistory()

  React.useEffect(() => {
    const unlisten = history.listen(() => cb())
    return () => {
      unlisten()
    }
  }, [cb, history])
}
