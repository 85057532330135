import { css, cx } from '@emotion/css'

const BaseBannerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;

  gap: 4px;
  padding: 8px 8px 8px 16px;
  margin: 8px 16px;

  border-radius: 10px;
  border-left: 4px solid;

  .title {
    font-size: 14px;
    font-weight: 600;
  }

  .content {
    font-size: 13px;
    font-weight: 500;
  }
`

interface BaseBannerProps {
  title: string
  content: string
  className?: string
  bgColor?: string
  accentColor?: string
  textColor?: string
}

const BaseBanner = (props: BaseBannerProps) => {
  return (
    <div
      className={cx(BaseBannerStyles, props.className)}
      style={{ backgroundColor: props.bgColor, color: props.textColor, borderLeftColor: props.accentColor }}
    >
      <div className='title'>{props.title}</div>
      <div className='content'>{props.content}</div>
    </div>
  )
}

export interface ListBannerProps {
  title: string
  content: string
  className?: string
}

export const WarningBanner = (props: ListBannerProps) => {
  return <BaseBanner {...props} bgColor='#fff4e5' accentColor='#ff7e20' textColor='#663c00' />
}

// * Info: Other banners like Success, Info, Error, etc. can be added here just like WarningBanner
