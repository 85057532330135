import { handleRequest } from '../../api'
import { BackendNetworkTypes, ValueOf } from '../../services/NetworkType.service'
import { EventEmitter } from '../../utils/EventEmitter'
import type { BinaryFeatures } from '@loaders.gl/schema'
import type { Feature } from 'geojson'
import { BinSize } from '../../services/Bin.service'

export interface GeoJsonRecord {
  type: string
  geometry: {
    type: string
    coordinates: number[][][]
  }
  properties: {
    tileZ?: number
    aggregation_date: string
    avg_quality: number
    avg_snr: number | null
    avg_strength: number
    carrier_abbr: string
    carrier_id: string
    carrier_name: string
    grid_id: string
    lat_bounds1: number
    lat_bounds2: number
    lat_center: number
    lon_bounds1: number
    lon_bounds2: number
    lon_center: number
    network_performance: number
    network_type: string
    network_type_id: ValueOf<typeof BackendNetworkTypes>
    num_quality: number
    num_snr: number
    num_strength: number
    total_mobile_data: number
    zoom: ValueOf<typeof BinSize>
  }
}

export interface GeoSignalsPayload {
  carrierId: string
  zoomLevel: number
  networkType: number
}

export interface MvtPayload {
  carrierId: string
  networkType: number
  zoomLevel: number
  tile_x: string
  tile_y: string
  tile_z: number
}

export interface GeoPayload {
  carrierId: string
  geojson: {
    type: string
    coordinates: number[][][]
  }
  zoomLevel: number
  networkType: number
}

export interface LoadOptions {
  payload: GeoSignalsPayload
  url?: string
  signal?: AbortSignal
}

export const GeoEvent = {
  changeLoadingState: 'changeLoadingState'
}

export type ParsedMvtTile = Feature[] | BinaryFeatures

export class GeodataService extends EventEmitter {
  private loadingCount = 0

  private finalizeTask() {
    if (this.loadingCount > 0) {
      this.loadingCount--
    }
    if (this.loadingCount === 0) {
      this.emit(GeoEvent.changeLoadingState, false)
    }
  }

  async load(options: LoadOptions): Promise<Blob> {
    const url = options.url ?? '/getProviderForArea'
    const payload = options.payload
    const signal = options.signal

    const promise = handleRequest(url, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      },
      signal
    })

    if (this.loadingCount === 0) {
      this.emit(GeoEvent.changeLoadingState, true)
    }
    this.loadingCount++

    const ABORTED_MESSAGE = 'deck manager: request aborted'
    const data = await promise
      .then((response) => {
        this.finalizeTask()
        return response.blob()
      })
      .catch((e) => {
        if (e.message !== ABORTED_MESSAGE) {
          this.finalizeTask()
          throw e
        }
      })

    return data || new Blob()
  }
}
