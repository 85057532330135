import { StateCreator } from 'zustand'

export type SearchHistoryState = {
  searchedPlansHistory: string[]
  searchedAddressHistory: string[]
}

export type SearchHistoryActions = {
  setSearchedPlansHistory: (searchedPlansHistory: string[]) => void
  setSearchedAddressHistory: (searchedAddressHistory: string[]) => void
}

export const searchHistoryInitialState: SearchHistoryState = {
  searchedPlansHistory: [],
  searchedAddressHistory: []
}

const createSearchHistorySlice: StateCreator<
  SearchHistoryState & SearchHistoryActions,
  [],
  [],
  SearchHistoryState & SearchHistoryActions
> = (set) => ({
  ...searchHistoryInitialState,
  setSearchedPlansHistory: async (searchedPlansHistory: string[]) => {
    set({ searchedPlansHistory })
  },
  setSearchedAddressHistory: async (searchedAddressHistory: string[]) => {
    set({ searchedAddressHistory })
  }
})

export default createSearchHistorySlice
