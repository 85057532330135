import * as React from 'react'
import { UsePlan } from '../../services/planService/Plan.service'
import { Plan } from '../../services/planService/Plan'

export function useGetPlansInfo(planIds: Plan['planId'][]) {
  const allPlans = UsePlan.useAllPlans()

  return React.useMemo(() => {
    let filteredPlans: Plan[] = []

    if (planIds && allPlans.data) {
      filteredPlans = allPlans.data.filter((plan) => planIds.includes(plan.planId))
    }

    return { loading: allPlans.isLoading, data: filteredPlans }
  }, [allPlans.data, allPlans.isLoading, planIds])
}
