import { isPlatform } from '@ionic/core'
import { AFEvent, AppsFlyer } from 'appsflyer-capacitor-plugin'
import { Logger } from '../utils/Logger'

let didInit = false
export function trackAppsFlyerEvent(event: AFEvent) {
  if (didInit) {
    AppsFlyer.logEvent(event)
  }
}

function initSdk() {
  if (!process.env.REACT_APP_APPSFLYER_KEY) {
    Logger.warn('AppsFlyer key not found')
    return
  }

  const APPSTORE_ID = '6471450350'
  const ANDROID_ID = 'com.gojimobile.app'

  if (isPlatform('mobileweb') || isPlatform('desktop')) {
    return
  }

  AppsFlyer.initSDK({
    appID: isPlatform('ios') ? APPSTORE_ID : ANDROID_ID, // replace with your app ID.
    devKey: process.env.REACT_APP_APPSFLYER_KEY, // replace with your dev key.
    isDebug: true,
    waitForATTUserAuthorization: 60, // for iOS 14 and higher
    // minTimeBetweenSessions: 6, // default 5 sec
    registerOnDeepLink: false,
    registerConversionListener: true,
    registerOnAppOpenAttribution: false,
    useReceiptValidationSandbox: true, // iOS only
    useUninstallSandbox: true // iOS only
  })
    .then((res) => {
      Logger.debug('AppsFlyer.initSDK', res)
      didInit = true
    })
    .catch((error) => {
      Logger.error('AppsFlyer.initSDK error', error)
    })
}

export async function initAppsFlyer() {
  initSdk()
}
