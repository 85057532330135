import { css, cx } from '@emotion/css'
import { IonRouterLink } from '@ionic/react'
import { ResetMargin } from '../../../utils/styles/MarginUtils.styles'

const JustForYouCardStyles = css`
  color: var(--ion-text-color);
  overflow: hidden;
  
  display: flex;
  gap: 16px;

  h3 {
    font-size: 20px;
    font-weight: 900;
    line-height: 25px;
  }

  .description {
    color: var(--goji-color-description);
    font-size: 14px;
    line-height: 18.9px;
  }

  .card-left {
    padding: 18px 0 18px 20px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 8px;
    min-width: 235px;
    justify-content: center;
  }

  .image-container {
    height: auto;
    display: flex;
    align-items: end;
    min-width: fit-content;

    img {
      flex-grow: 1;
      height: 100%;
    }
  }

  .card-link {
    color: var(--goji-primary-black);
    text-decoration: underline;
    font-size: 14px;
  }
`

const JustForYouCardFrameStyles = css`
  border: 2px solid var(--goji-primary-black);
  border-radius: 10px;
`
interface JustForYouCardFrameProps {
  children?: React.ReactNode | React.ReactNode[]
  className?: string
}
export function JustForYouCardFrame(props: JustForYouCardFrameProps) {
  return <article className={cx(JustForYouCardFrameStyles, props.className)}>{props.children}</article>
}

interface JustForYouCardProps {
  title: string
  description: string
  linkText: string
  link: string
  logo_url: string
  imageAlt: string
  imageContainerClass?: string,
  onClick?: React.MouseEventHandler<HTMLIonRouterLinkElement>
}
export function JustForYouCard(props: JustForYouCardProps) {
  return (
    <IonRouterLink routerLink={props.link} onClick={props.onClick}>
      <JustForYouCardFrame className={JustForYouCardStyles}>
        <div className='card-left'>
          <h3 className={ResetMargin}>{props.title}</h3>
          <div className='description'>{props.description}</div>
          <div>
            <IonRouterLink className='card-link' routerLink={props.link}>
              {props.linkText}
            </IonRouterLink>
          </div>
        </div>
        <div className='image-container'>
          <img className={props.imageContainerClass} alt={props.imageAlt} src={props.logo_url} />
        </div>
      </JustForYouCardFrame>
    </IonRouterLink>
  )
}
