import { Plan } from '../../../services/planService/Plan'

const getDifference = (currentPlan: Plan, key: keyof Plan, plans: Plan[]) => {
  return plans.some((comparedPlan) => {
    return comparedPlan.planId !== currentPlan.planId && comparedPlan[key] !== currentPlan[key]
  })
}

const getAddonsDifference = (currentPlan: Plan, plans: Plan[]) => {
  const addonsIds = currentPlan.addons.map((addon) => addon.id)
  return plans.some((comparedPlan) => {
    return (
      comparedPlan.addons.length !== currentPlan.addons.length ||
      (comparedPlan.planId !== currentPlan.planId &&
        comparedPlan.addons.map((addon) => addon.id).some((addonId) => addonsIds.includes(addonId)))
    )
  })
}

const compareColumns = ['price', 'premiumData', 'data', 'voice', 'sms'] as const
export type CompareColumn = (typeof compareColumns)[number] | 'addons'
export type PlanDiff = {
  [K in CompareColumn]: boolean
} & { plan: Plan }

export const getDifferences = (plans: Plan[]): PlanDiff[] => {
  return plans.map((plan) => {
    const planDiff = {
      plan,
      addons: getAddonsDifference(plan, plans)
    } as PlanDiff

    for (const compareColumn of compareColumns) {
      planDiff[compareColumn] = getDifference(plan, compareColumn, plans)
    }

    return planDiff
  })
}
