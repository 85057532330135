import { css } from '@emotion/css'
import shallow from 'zustand/shallow'
import { LazyPlansCarousel } from '../../../components/PlanResult/LazyPlansCarousel'
import { useBoundStore } from '../../../store'
import { useGetPlansInfo } from '../../../utils/hooks/useGetPlansInfo'
import { ResetMargin } from '../../../utils/styles/MarginUtils.styles'

const SavedPlansStyles = css`
  margin-bottom: 24px;

  h4 {
    margin-bottom: 16px;
    margin-top: 16px;
    font-weight: 900;
    line-height: 22.5px;
  }
`

export function SavedPlans() {
  const [favoritePlanIds] = useBoundStore((state) => [state.favoritePlanIds], shallow)
  const { loading: isLoadingFavoritePlans, data: favoritePlans } = useGetPlansInfo(favoritePlanIds)

  if (isLoadingFavoritePlans) {
    return null
  }

  return (
    <section className={SavedPlansStyles}>
      <h4 className={ResetMargin}>Your saved plans</h4>
      <main>
        <LazyPlansCarousel plans={favoritePlans} />
      </main>
    </section>
  )
}
