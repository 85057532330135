import { isPlatform } from '@ionic/react'
import { APPSTORE_URL, GOOGLE_PLAY_URL } from '../../constants/appStoreUrls'

const useGetStoreUrl = () => {
  const isIOS = isPlatform('ios')

  const storeUrl = isIOS ? APPSTORE_URL : GOOGLE_PLAY_URL

  return storeUrl
}

export default useGetStoreUrl
