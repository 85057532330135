import { css } from '@emotion/css'
import { Address } from '../../store/address'

const LocationsListStyles = css`
  gap: 16px !important;
  display: flex;
  flex-direction: row;

  li {
    list-style: none;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 8px;
  }

  .location-label {
    display: block;
    color: var(--goji-color-description);
    position: relative;
  }
`

interface LocationsListProps {
  addresses: Address[]
  limit?: number
}
export function LocationsList(props: LocationsListProps) {
  const limitedAddresses = props.limit !== undefined ? props.addresses.slice(0, props.limit) : props.addresses
  const remainingCount = props.addresses.length - limitedAddresses.length

  return (
    <>
      <ul className={LocationsListStyles}>
        {limitedAddresses.map((address) => {
          return (
            <li key={address.placeId} className='address saved-address'>
              <img src='/assets/images/welcome/saved-location.svg' alt='Current location icon' />
              <label className='location-label'>Saved Location</label>
              <span />
              <div>{address.addressName}</div>
            </li>
          )
        })}
      </ul>
      <p className={`more-text ${remainingCount > 0 ? '' : 'hidden'}`}>{`${remainingCount} more...`}</p>
    </>
  )
}
