import React from 'react'
import TModal from '../TModal/TModal'
import { css, cx } from '@emotion/css'
import { IonRouterLink, IonRow, IonText, isPlatform } from '@ionic/react'
import CloseButton from '../CloseButton/CloseButton'
import { MainNavigationPaths, RoutePaths } from '../../paths'
import NextButton from '../NextButton/NextButton'
import { Redirect } from 'react-router'
import { modalOpenInitialValue, setOnboarded } from './onboardingStorage'
import useAuthStore from '../../store/auth'
import { carouselOnboardingData } from './carouselOnboardingData'

const DesktopOnboardingModalStyles = css`
  display: flex;
  justify-content: center;
  min-width: 50%;
  min-height: 50%;
  align-items: space-between;

  .modal-root-wrapper {
    width: 70%;
    height: 50%;
  }

  .content-container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    align-items: end;

    .content-onboarding {
      grid-row-start: 1;
      grid-column-start: 1;
      visibility: hidden;

      &.active {
        visibility: visible;
      }

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
    }
  }

  .controls-onboarding {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 30%;
    gap: 1rem;
  }

  .imagen-contianer {
    width: 50%;

    img {
      repeat: no-repeat;
      width: 100%;
    }
  }

  .close-button {
    height: 1rem;
    width: 1rem;
    color: var(--ion-color-medium);
    position: absolute;
    top: 25px;
    right: 29px;
  }

  ion-button {
    display: flex;
    width: 100%;
    text-transform: uppercase;
    height: auto;
    --padding-top: 1rem;
    --padding-bottom: 1rem;
  }

  .sign-in-btn {
    text-align: center;
  }

  ion-router-link {
    text-decoration: underline;
    color: var(--ion-color-dark);
  }

  .carousel-container {
    margin: 0 auto;
  }

  .carousel-dots {
    list-style: none;
    display: inline-block;
    margin: 0;
  }

  .dot {
    margin: 0 0.5rem;
    width: 1rem;
    height: 1rem;
    background-color: #ccc;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
  }

  .dot.active {
    background-color: var(--ion-color-dark);
  }
`

const OnboardingDesktopCarousel: React.FC = () => {
  const [currectIndex, setCurrentIndex] = React.useState(0)
  const [isModalOpen, setIsModalOpen] = React.useState(modalOpenInitialValue)
  const isMobile = isPlatform('ios') || isPlatform('android')

  const currentUser = useAuthStore((state) => state.currentUser)

  if (isMobile && modalOpenInitialValue) {
    return <Redirect to={RoutePaths.onboarding} />
  }

  const onCloseOnboardingModal = () => {
    setIsModalOpen(false)
    setOnboarded()
  }

  const nextButtonHandler = () => {
    if (currectIndex === carouselOnboardingData.length - 1) {
      onCloseOnboardingModal()
      return
    }
    setCurrentIndex((prevIndex) => prevIndex + 1)
  }

  const handleDotClick = (index: number) => {
    setCurrentIndex(index)
  }

  return (
    <TModal isOpen={isModalOpen && !isMobile} className={cx(DesktopOnboardingModalStyles)}>
      <section className='modal-root-wrapper'>
        <CloseButton onClose={onCloseOnboardingModal} />
        <div className='content-container'>
          {[...carouselOnboardingData].map((item, index) => (
            <div className={cx('content-onboarding', index === currectIndex ? 'active' : '')} key={item.id}>
              <IonRow className='ion-justify-content-center'>
                <div className='imagen-contianer'>
                  <img src={item.imageSrc} alt={item.imageAlt} />
                </div>

                <IonText className='ion-text-center'>
                  <h2>{item.title}</h2>
                  <p>{item.paragraph}</p>
                </IonText>
              </IonRow>
              <IonRow>
                <div className='carousel-container'>
                  <ul className='carousel-dots'>
                    {carouselOnboardingData.map((_, index) => (
                      <li
                        key={index}
                        className={`dot ${currectIndex === index ? 'active' : ''}`}
                        onClick={() => handleDotClick(index)}
                      ></li>
                    ))}
                  </ul>
                </div>
              </IonRow>
            </div>
          ))}
        </div>
        <div className='controls-onboarding'>
          <NextButton onClick={nextButtonHandler} desktop>
            Next
          </NextButton>
          {!currentUser && (
            <div className='sign-in-btn'>
              <IonRouterLink routerLink={MainNavigationPaths.signIn}>Sign&nbsp;in Goji</IonRouterLink>
            </div>
          )}
        </div>
      </section>
    </TModal>
  )
}

export default OnboardingDesktopCarousel
