import { css } from '@emotion/css'
import { IonButton, IonImg } from '@ionic/react'
import React from 'react'
import { useQueryClient } from 'react-query'
import CloseButton from '../../components/CloseButton/CloseButton'
import TModal, { TModalHeading } from '../../components/TModal/TModal'
import { SignalStrengthService } from '../../services/SignalStrengthService'
import { BarryWrapperStyles } from './MapPermissionsPopup'
import { locationKey, useLocationPermission, useRefetchPermissions } from './useLocationPermissions'

export const MapPermissionsPopupStyles = css`
  .barry-sitting {
    width: 110.667px;
    height: 116px;
  }
`

export function LocationModal(props: { onClose: () => void; body?: React.ReactNode | React.ReactNode[] }) {
  const queryClient = useQueryClient()
  const { requestLocation } = useLocationPermission()

  const [showSettingsButton, setShowSettingsButton] = React.useState(false)
  const refetchPermissions = useRefetchPermissions()

  async function onClickConfigure() {
    const result = await requestLocation()
    queryClient.setQueryData(locationKey, { isGranted: result.isGranted })

    if (!result.isGranted) {
      if (!result.shouldShowRationale) {
        setShowSettingsButton(true)
        return
      }
      props.onClose()
    }
  }

  return (
    <TModal className={MapPermissionsPopupStyles}>
      <TModalHeading>
        <div />
        <CloseButton onClose={props.onClose} />
      </TModalHeading>
      <div>
        <div className={BarryWrapperStyles}>
          <IonImg className='barry-sitting' src='/assets/images/barry/barry_sitting2.svg' alt='Barry Sitting' />
        </div>
        <h3>Allow Goji to access your device’s location?</h3>
        <div>
          {props.body ??
            'Granting Goji Mobile access to precise location will allow us to display your current location on the map and be used for signal strength results. Only you will be able to see your current location.'}
        </div>
        <div className='ion-padding-vertical' />
        {showSettingsButton ? (
          <IonButton
            onClick={async () => {
              props.onClose()
              await SignalStrengthService.goToSettings()
              refetchPermissions()
            }}
            size='large'
            shape='round'
            expand='block'
          >
            Go to Settings
          </IonButton>
        ) : (
          <IonButton onClick={onClickConfigure} size='large' shape='round' expand='block'>
            Configure Access
          </IonButton>
        )}
      </div>
    </TModal>
  )
}
