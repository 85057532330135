import React from 'react'
import { PlanOverlayLikeStyles } from '../PlanSwipe.styles'
import OverlayBlock, { PlanOverlayType } from './OverlayBlock'

const PlanOverlayLike = React.forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div className={PlanOverlayLikeStyles} ref={ref}>
      <OverlayBlock type={PlanOverlayType.LIKE} />
      <span>Yup, looks interesting!</span>
    </div>
  )
})

export default PlanOverlayLike
