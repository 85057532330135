import React from 'react'

export enum GeocoderTypes {
  COUNTRY = 'country',
  ADMINISTRATIVE_AREA_LEVEL_1 = 'administrative_area_level_1',
  ADMINISTRATIVE_AREA_LEVEL_2 = 'administrative_area_level_2',
  LOCALITY = 'locality',
  POSTAL_CODE = 'postal_code',
  SCHOOL_DISTRICT = 'school_district'
}

const mapGeocoderTypesToFeatureType = {
  [GeocoderTypes.COUNTRY]: 'COUNTRY' as google.maps.FeatureType,
  [GeocoderTypes.ADMINISTRATIVE_AREA_LEVEL_1]: 'ADMINISTRATIVE_AREA_LEVEL_1' as google.maps.FeatureType,
  [GeocoderTypes.ADMINISTRATIVE_AREA_LEVEL_2]: 'ADMINISTRATIVE_AREA_LEVEL_2' as google.maps.FeatureType,
  [GeocoderTypes.LOCALITY]: 'LOCALITY' as google.maps.FeatureType,
  [GeocoderTypes.POSTAL_CODE]: 'POSTAL_CODE' as google.maps.FeatureType,
  [GeocoderTypes.SCHOOL_DISTRICT]: 'SCHOOL_DISTRICT' as google.maps.FeatureType
}

export const isGeocoderType = (type: string) => Object.values(GeocoderTypes).includes(type as GeocoderTypes)

let styleFill: google.maps.FeatureStyleOptions = {
  fillColor: '#090c9b',
  fillOpacity: 0.1,
  strokeColor: '#090c9b',
  strokeOpacity: 0.5,
  strokeWeight: 1
}

const highlightPlaceIdFunc = async (map: google.maps.Map, placeId: string) => {
  const { results } = await new google.maps.Geocoder().geocode({ placeId })
  if (!map || !placeId || !results.length || !results[0].types.length) return

  let geocodeType = results[0].types.find((type) => isGeocoderType(type)) as GeocoderTypes | undefined
  if (!geocodeType) return
  const featureType = mapGeocoderTypesToFeatureType[geocodeType as GeocoderTypes]
  const featureLayer = map.getFeatureLayer(featureType)

  const featureStyle = (params: any) => {
    if (params?.feature?.placeId === placeId) {
      return styleFill
    }
  }

  featureLayer.style = featureStyle
}

const useHighlightRegion = (map?: google.maps.Map, placeId?: string) => {
  React.useEffect(() => {
    if (!map || !placeId) {
      return
    }

    highlightPlaceIdFunc(map, placeId)
  }, [map, placeId])
}

export default useHighlightRegion
