import { PlanResponse } from '../../../services/planService/Plan'
import { ValueOf } from '../../../utils/types/ValueOf'
import { ToolName } from '../tools/const/tool.const'
import { Suggestion } from './Suggestion'
import { ToolComponents } from '../tools/const/tool.const'

export type QuestionPiece = {
  color?: string
  text: string
}

export const MessageType = {
  TEXT: 0,
  STEP: 1,
  TRANSITION: 2,
  TOOL: 3
} as const

export const ChatInteraction = {
  FREE_TEXT: 0,
  ADDRESS: 1
} as const

export type PlanSpecs = Partial<PlanResponse>

export const outOfContextTemplate =
  "I'm having trouble understanding. Could you rephrase your response to help me understand "

export const invalidTemplate = "Sorry, I don't understand. 🧐"

export interface getInvalidResponseParams {
  min?: number | string
  max?: number | string
  options?: string[]
}

export type ChatStep = {
  from: 'bot'
  type: (typeof MessageType)['STEP']
  question: QuestionPiece[]
  subtitle?: string
  interactions: ValueOf<typeof ChatInteraction>[]
  suggestions: Suggestion[]
}

export type PredefinedChatStep = ChatStep & {
  outOfContextResponse: string
  getInvalidResponse?: (validation?: getInvalidResponseParams) => string
  endpoint: string
  nextPaths: Record<string, string | undefined>
}

export type UserTextMessage = {
  type: (typeof MessageType)['TEXT']
  text: string
  from: 'user'
}

export type BotTextMessage = {
  type: (typeof MessageType)['TEXT']
  text: string
  from: 'bot'
  nextMessageKey?: string
}

export type TransitionMessage = {
  type: (typeof MessageType)['TRANSITION']
  text: string
  subtext: string
  imageUrl: string
  imageAlt: string
  from: 'bot'
  nextMessageKey: string | undefined
}

export type BotToolMessage = {
  type: (typeof MessageType)['TOOL']
  from: 'bot'
  tool: ToolName
  toolComponent: keyof typeof ToolComponents
  backendInput: object
}

export type ChatTextMessage = UserTextMessage | BotTextMessage

export type ChatMessage = PredefinedChatStep | ChatTextMessage

export type BotMessage<C extends ChatStep = ChatStep> = BotTextMessage | TransitionMessage | C

export type IChatFlow = Record<string, BotMessage<PredefinedChatStep>> & { entrypoint: BotMessage<PredefinedChatStep> }
