import { PredefinedChatMessageRecord } from '../ChatFlow'
import { IChatFlow, MessageType } from '../types/ChatStep'
import { ChatStatus } from '../types/ChatStorageSource'

function isLastStepFinishing<T extends IChatFlow>(lastMessage: T[keyof T]): boolean {
  return (
    lastMessage.type === MessageType.STEP &&
    '$default' in lastMessage.nextPaths &&
    lastMessage.nextPaths.$default === undefined
  )
}

function isLastMessageFinishing<T extends IChatFlow>(lastMessage: T[keyof T]): boolean {
  return (
    (lastMessage.type === MessageType.TEXT || lastMessage.type === MessageType.TRANSITION) &&
    lastMessage.nextMessageKey === undefined
  )
}

export function getChatStatus<T extends IChatFlow>(chatFlow: T, chatTimeline: PredefinedChatMessageRecord<T>[]): ChatStatus {
  const lastRecord = chatTimeline[chatTimeline.length - 1]
  if ('key' in lastRecord && chatFlow[lastRecord.key]) {
    const lastMessage = chatFlow[lastRecord.key]
    if (isLastStepFinishing(lastMessage) || isLastMessageFinishing(lastMessage)) {
      return 'ended'
    }
  }
  return 'active'
}
