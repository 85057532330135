import * as React from 'react'
import { Amplify, Auth, Hub } from 'aws-amplify'
import useAuthStore, { CurrentUser } from '../store/auth'
import { Logger } from '../utils/Logger'
import { setAmplitudeUserId } from '../utils/amplitude'
import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js'
import shallow from 'zustand/shallow'
import { Browser } from '@capacitor/browser'
import { isPlatform } from '@ionic/core'
import awsconfig from '../aws-exports'

awsconfig.oauth.redirectSignIn = `${window.location.origin}/`
awsconfig.oauth.redirectSignOut = `${window.location.origin}/`
if ((isPlatform('android') || isPlatform('ios')) && !isPlatform('mobileweb')) {
  // TODO: get from env or use only one url in config
  awsconfig.oauth.redirectSignIn = 'com.gojimobile.app://com.gojimobile.app/'
  awsconfig.oauth.redirectSignOut = 'com.gojimobile.app://com.gojimobile.app/'
}

async function urlOpener(url: string, _redirectUrl: string) {
  await Browser.open({ url, windowName: '_self' })
}

Amplify.configure({
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    urlOpener
  }
})

export const getCurrentUser = async (auth: typeof Auth = Auth): Promise<null | CurrentUser> => {
  try {
    const user: CognitoUser = await auth.currentAuthenticatedUser()
    const attributes = await new Promise<CognitoUserAttribute[] | undefined>((res, rej) => {
      user.getUserAttributes((error, result) => {
        if (error) {
          rej(error)
          return
        }
        res(result)
      })
    })

    const email = attributes!.find((attr) => attr.Name === 'email')!.Value
    const token = attributes!.find((attr) => attr.Name === 'email')!.Value

    return { email, token }
  } catch (error) {
    Logger.error(error)
    return null
  }
}

namespace AmplifyComponent {
  export interface Props {
    hub: typeof Hub
    auth: typeof Auth
  }
}

function AmplifyComponent(props: AmplifyComponent.Props) {
  const [setCurrentUser] = useAuthStore((store) => [store.setCurrentUser], shallow)

  React.useEffect(() => {
    const removeListener = props.hub.listen('auth', async ({ payload }) => {
      Logger.debug('auth event', payload)
      const { event } = payload
      if (event === 'autoSignIn') {
        // TODO: handle autoSignIn
      } else if (event === 'signIn') {
        setAmplitudeUserId(payload.data.signInUserSession.accessToken.payload.sub)
        const user = await getCurrentUser(props.auth)
        setCurrentUser(user)
      } else if (event === 'signOut') {
        setCurrentUser(null)
      }
    })

    getCurrentUser(props.auth).then((user) => {
      setCurrentUser(user)
    })

    return () => {
      removeListener()
    }
  }, [setCurrentUser, props.hub, props.auth])

  return null
}

export default AmplifyComponent
