/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:a874e948-921f-4ab7-86ac-b1531e900b49",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Kp6cTZLDL",
    "aws_user_pools_web_client_id": "74p0q2asv9mbv28suskdf9hf2o",
    "oauth": {
        "domain": "tbirda747f358-a747f358-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://main.d3epwm3ho5f9iu.amplifyapp.com/,http://localhost:3001/,https://app.gojimobile.com/,com.gojimobile.app://com.gojimobile.app/",
        "redirectSignOut": "https://main.d3epwm3ho5f9iu.amplifyapp.com/,http://localhost:3001/,https://app.gojimobile.com/,com.gojimobile.app://com.gojimobile.app/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
