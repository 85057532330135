import { cx } from '@emotion/css'
import { IonIcon, IonRouterLink } from '@ionic/react'
import { arrowBack } from 'ionicons/icons'
import { BaseHeader } from './BaseHeader'
import { BackButtonStyles, FavoriteButtonStyles, InnerHeaderStyles } from './SummaryHeaderStyles'

const heart = 'assets/icons/heart.svg'
const heartOutline = 'assets/icons/heartOutline.svg'

const BackButton = ({ onClick }: { onClick: React.MouseEventHandler<HTMLIonRouterLinkElement> }) => {
  return (
    <IonRouterLink onClick={onClick} color='primary' className={BackButtonStyles}>
      <IonIcon color='dark' src={arrowBack} />
    </IonRouterLink>
  )
}

export const FavoriteButton = ({
  isFavorite,
  onClick,
  className
}: {
  isFavorite?: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
  className?: string
}) => {
  return (
    <button className={cx(FavoriteButtonStyles, className)} onClick={onClick}>
      <img src={heart} alt='favorite' className='heart-icon' style={{ display: !!isFavorite ? 'block' : 'none' }} />
      <img
        src={heartOutline}
        alt='not favorite'
        className='heart-icon'
        style={{ display: !isFavorite ? 'block' : 'none' }}
      />
    </button>
  )
}

export const SummaryHeader = ({
  isFavorite,
  onFavoriteClick,
  onBackClick
}: {
  isFavorite?: boolean
  onFavoriteClick: React.MouseEventHandler<HTMLButtonElement>
  onBackClick: () => void
}) => {
  return (
    <BaseHeader className={cx(InnerHeaderStyles)}>
      <BackButton onClick={onBackClick} />
      <div className='title'>Plan Summary</div>
      <FavoriteButton isFavorite={isFavorite} onClick={onFavoriteClick} />
    </BaseHeader>
  )
}
