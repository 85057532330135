import { cx } from '@emotion/css'
import { IonPage } from '@ionic/react'
import React from 'react'
import { useMutation } from 'react-query'
import API from '../../../api'
import { ChatbotContentContainerStyles } from '../ChatbotPage.styles'
import { ChatComponent } from '../components/ChatComponent'
import { ChatTimelineProps } from '../components/ChatTimeline'
import { useServerChat } from '../hooks/useServerChat'
import { ConversationalResponse, ValidatedResults } from '../ServerResponse'
import { BotToolMessage, MessageType, UserTextMessage } from '../types/ChatStep'
import { FreeBotMessage } from '../types/free-chat.types'
import { FreeChatPageStyles } from './FreeChatPageStyles'
import { ChatFlowSelector } from '../ChatFlow'

interface ServerChatPayload {
  answer: string
  context: (
    | {
        userAnswer: string
      }
    | { assistantAnswer: string }
  )[]
}
function useSendAnswerToLLM(timeline: ChatTimelineProps['timeline']) {
  return useMutation('sendAnswerToLLM', async (options: { userAnswer: string }) => {
    const serverPayload: ServerChatPayload = {
      answer: options.userAnswer,
      context: (
        timeline.filter((message) => message.type !== MessageType.TOOL) as (
          | Exclude<FreeBotMessage, BotToolMessage>
          | UserTextMessage
        )[]
      ).map((message) => {
        if (message.type === MessageType.STEP) {
          return { assistantAnswer: message.question.map((q) => q.text).join('') }
        }

        if (message.from === 'bot') {
          return { assistantAnswer: message.text }
        }

        return { userAnswer: message.text }
      })
    }
    try {
      const response: ConversationalResponse = await API.post('/llm/chat', serverPayload as Record<string, any>)
      return response
    } catch (error) {
      return { resultStatus: ValidatedResults.FAILED, error }
    }
  })
}
export function FreeChatPage() {
  const [timeline, setTimeline] = React.useState<ChatTimelineProps['timeline']>([
    ChatFlowSelector.entrypoint,
    ChatFlowSelector.question__start_plan_finder
  ])

  const sendAnswerToLLM = useSendAnswerToLLM(timeline)

  const chat = useServerChat({
    onMessage: async (payload) => {
      setTimeline((prev) => [...prev, payload.messageRecord.message])
    },
    sendAnswerToLLM: sendAnswerToLLM.mutateAsync,
    timeline
  })

  return (
    <IonPage className={cx(FreeChatPageStyles, ChatbotContentContainerStyles)}>
      <ChatComponent
        onRestart={() => {
          // cancelFakeLoading()
          // restartChat()
        }}
        isLoading={sendAnswerToLLM.isLoading}
        chatStatus={'active'}
        chat={chat}
        onChatEnd={() => {
          // TODO: not needed
        }}
      />
    </IonPage>
  )
}
