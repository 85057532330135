import { Location } from 'history'
import { useLocation } from 'react-router'

// For Modal routes, using the `useLocation` hook throws an error
// we are relying on useLocation in our useBoundStore hook so we need
// a workaround to avoid app to crash
//
// TODO: find a better way to not depend on useLocation or make the modal views subroutes
//
export const useSafeLocation = () => {
  let location: Location<unknown> | null = null
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    location = useLocation()
  } catch (error: unknown) {}

  return location
}
