export const carouselOnboardingData = [
  {
    id: 0,
    imageSrc: 'assets/images/barry/barry_map.svg',
    imageAlt: 'Barry with a map',
    title: 'Understand your coverage',
    paragraph:
      'Wanna know where you’re dropping calls? Add your favorite locations to Goji’s Network Map to find out before you decide to switch carriers!'
  },
  {
    id: 1,
    imageSrc: 'assets/images/barry/barry_meter.svg',
    imageAlt: 'Barry with a meter background',
    title: 'Get real-time speed checks',
    paragraph:
      'Use Goji’s Network Meter to check your cellular speeds at different spots in your home or your favorite hangout spots.'
  },
  {
    id: 2,
    imageSrc: 'assets/images/barry/barry_sitting.svg',
    imageAlt: 'Barry sitting',
    title: 'Browse over 1,000 plans',
    paragraph:
      'Goji makes plan shopping easy by pulling wireless plans from over 100 US carriers for you to quickly browse, compare and select what’s best for you.'
  }
]
