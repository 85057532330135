import { css } from '@emotion/css'
import { IonButton, IonIcon, IonModal } from '@ionic/react'
import { close } from 'ionicons/icons'
import React from 'react'

const InfoDialogStyles = css`
  --width: fit-content;
  --max-width: 280px;
  --height: fit-content;
  --border-radius: 10px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;

  .ion-delegate-host {
    padding: 16px;
    color: var(--ion-color-dark);

    .close-top-right-button {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      margin: 16px;
      --padding-start: 0;
      --padding-end: 0;
    }

    .close-bottom-button {
      border-radius: 30px;
      border: 2px solid var(--ion-color-primary);
      width: 100%;
      margin-top: 24px;
      font-weight: 700;
      font-size: 16px;
    }

    .content-wrapper {
      margin-top: 48px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .title {
        margin: 0;
        text-align: start;
        font-size: 22px;
        font-weight: 900;
        color: var(--goji-primary-black);
      }

      > p {
        text-align: start;
        font-size: 16px;
        font-weight: 500;
        color: var(--goji-primary-black);
        margin: 0;
      }
    }
  }
`

interface InfoDialogProps {
  isOpen: boolean
  onClose: () => void
  title?: string
  content?: string | JSX.Element
}

const stopPropagation = (e: React.MouseEvent<HTMLIonModalElement>) => e.stopPropagation()

const InfoDialog = (props: InfoDialogProps) => {
  const modalRef = React.useRef<HTMLIonModalElement>(null)

  return (
    <IonModal
      onClick={stopPropagation}
      ref={modalRef}
      isOpen={props.isOpen}
      onDidDismiss={props.onClose}
      className={InfoDialogStyles}
    >
      <IonButton fill='clear' onClick={() => modalRef.current?.dismiss()} className='close-top-right-button'>
        <IonIcon icon={close} slot='icon-only' color='medium' />
      </IonButton>
      <div className='content-wrapper'>
        <h2 className='title'>{props.title}</h2>
        <p>{props.content}</p>
      </div>
      <IonButton fill='clear' onClick={() => modalRef.current?.dismiss()} className='close-bottom-button'>
        CLOSE
      </IonButton>
    </IonModal>
  )
}

export default InfoDialog
