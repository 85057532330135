import { css, cx } from '@emotion/css'
import { IonIcon, IonRouterLink } from '@ionic/react'
import { useBoundStore } from '../../../store'
import { Logger } from '../../../utils/Logger'
import { Suggestion, SuggestionType, TextSuggestion } from '../types/Suggestion'
import { Geolocation } from '@capacitor/geolocation'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router'
import { useFlag } from '../../../utils/feature-flags/useFlag'
import { bookmark, briefcase, home, navigate } from 'ionicons/icons'
import { LabelType } from '../../../store/address'

const SuggestionStyles = css`
  background: var(--goji-background-color);
  border: 2px solid var(--ion-color-light);
  border-radius: 30px;

  color: var(--goji-primary-black);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: fit-content;
  font-size: 16px;
  padding: 16px;
  .spinner {
    height: 16px;
  }

  :disabled {
    color: var(--ion-color-dark-tint);
    background: rgba(0, 0, 0, 0.04);
    border-color: var(--ion-color-light);
  }

  > ion-icon {
    color: var(--ion-color-medium);
  }

  &.size-large {
    flex-direction: column;
    width: 120px;
    min-height: 100%;
    text-align: center;
    cursor: pointer;
    > ion-icon {
      width: 40px;
      height: 40px;
    }
  }
`
const SuggestionActiveStyles = css`
  border-color: var(--ion-color-primary);
  border-width: 2px;
  margin-bottom: 7px;
  margin-top: -1px;
  background-color: var(--ion-color-primary-tint);
`
function SuggestionTextComponent<T extends Pick<TextSuggestion, 'text'>>(props: {
  suggestion: T
  onSuggestionSelected: (suggestion: T) => void
  active?: boolean
}) {
  return (
    <button
      onClick={() => props.onSuggestionSelected(props.suggestion)}
      className={cx(SuggestionStyles, props.active ? SuggestionActiveStyles : '')}
    >
      {props.suggestion.text}
    </button>
  )
}

function useIsGeolocationDenied() {
  return useQuery({
    queryKey: 'geolocationPermission',
    async queryFn() {
      const permissions = await Geolocation.checkPermissions()
      return Boolean(permissions && permissions.location === 'denied')
    }
  })
}

export interface SuggestionsComponentProps {
  suggestion: Suggestion
  onSuggestionSelected: (suggestion: Suggestion) => void
  active?: boolean
  role?: string
}

function SuggestionComponent(props: SuggestionsComponentProps) {
  const isEnabled = useFlag(props.suggestion.featureFlag)
  const loadingLocation = useBoundStore((state) => state.loadingLocation)
  const geolocationDenied = useIsGeolocationDenied()
  const location = useLocation()

  if (!isEnabled) {
    return null
  }

  if (props.suggestion.type === SuggestionType.TEXT) {
    return (
      <SuggestionTextComponent
        active={props.active}
        onSuggestionSelected={props.onSuggestionSelected}
        suggestion={props.suggestion}
      />
    )
  }

  if (props.suggestion.type === SuggestionType.LINK) {
    return (
      <IonRouterLink routerLink={props.suggestion.routerLink + location.search}>
        <SuggestionTextComponent
          active={props.active}
          onSuggestionSelected={props.onSuggestionSelected}
          suggestion={props.suggestion}
        />
      </IonRouterLink>
    )
  }

  if (props.suggestion.type === SuggestionType.LINK_WITH_ICON) {
    return (
      <IonRouterLink routerLink={props.suggestion.routerLink + location.search}>
        <div className={cx(SuggestionStyles, 'size-' + props.suggestion.size)}>
          <IonIcon icon={props.suggestion.icon} src={!props.suggestion.icon ? props.suggestion.src : undefined} />
          {props.suggestion.text}
        </div>
      </IonRouterLink>
    )
  }

  if (props.suggestion.type === SuggestionType.TEXT_WITH_IMAGE) {
    return (
      <div>
        <img src={props.suggestion.imageUrl} alt={props.suggestion.text} />
        {props.suggestion.text}
      </div>
    )
  }

  if (
    props.suggestion.type === SuggestionType.TEXT_WITH_ICON ||
    props.suggestion.type === SuggestionType.TOOL_SELECTION
  ) {
    return (
      <div
        className={cx(SuggestionStyles, 'size-' + props.suggestion.size)}
        onClick={() => props.onSuggestionSelected(props.suggestion)}
      >
        <IonIcon icon={props.suggestion.icon} src={!props.suggestion.icon ? props.suggestion.src : undefined} />
        {props.suggestion.text}
      </div>
    )
  }

  if (props.suggestion.type === SuggestionType.CURRENT_LOCATION) {
    return (
      <button
        onClick={() => {
          props.onSuggestionSelected(props.suggestion)
        }}
        className={SuggestionStyles}
        disabled={loadingLocation || geolocationDenied.isLoading || geolocationDenied.data}
      >
        <IonIcon icon={navigate} />
        {props.suggestion.text}
      </button>
    )
  }

  if (props.suggestion.type === SuggestionType.LOCATION) {
    let icon = null
    if (!props.suggestion.location.localMemory) {
      switch (props.suggestion.location.labelType) {
        case LabelType.Home:
          icon = home
          break
        case LabelType.Work:
          icon = briefcase
          break
        default:
          icon = bookmark
          break
      }
    }
    return (
      <button
        onClick={() => {
          props.onSuggestionSelected(props.suggestion)
        }}
        className={SuggestionStyles}
      >
        {icon && <IonIcon icon={icon} />}
        {props.suggestion.text}
      </button>
    )
  }

  Logger.warn('Unknown suggestion type', props.suggestion)
  return null
}

export default SuggestionComponent
