import { ToolName } from './tools/const/tool.const'

export type ValidatedResult = 'valid' | 'failed' | 'unknown' | 'outOfContext' | 'invalid' | 'cancelled'

export const ValidatedResults = {
  VALID: 'valid',
  FAILED: 'failed',
  UNKNOWN: 'unknown',
  OUT_OF_CONTEXT: 'outOfContext',
  INVALID: 'invalid',

  //front-end only
  CANCELLED: 'cancelled'
} as const

export type ServerResponse = {
  resultStatus: ValidatedResult
  parsedAnswer?: any
  validation?: {
    options?: string[]
    min?: number | string
    max?: number | string
  }
}

export type ToolCall<I = Record<string, unknown>> = {
  name: ToolName
  input: I
}

export type ConversationalResponse = {
  resultStatus: ValidatedResult
  message: string | null
  toolCalls: ToolCall[]
}
