import { cx } from '@emotion/css'
import { IonCol, IonIcon, IonRow } from '@ionic/react'
import * as React from 'react'
import { DATA_UNLIMITED, SMS_UNLIMITED, VOICE_UNLIMITED } from '../../services/planService/Preferences'
import { PlanInfoStyles, PlanInfoSummaryStyles, TextButtonStyles } from './PlanInfoStyles'
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons'
import { useBoundStore } from '../../store'
import { Plan } from '../../services/planService/Plan'
import { IconSignalVoice } from '../icons/IconSignalVoice'
import { IconSignalCellular } from '../icons/IconSignalCellular'
import { IconText } from '../icons/IconText'
import { IconHotspot } from '../icons/IconHotspot'
import { IconPremiumData } from '../icons/IconPremiumData'
import { IconPerks } from '../icons/IconPerks'
import { IconPlan } from '../icons/IconPlan'
import { IconAddons } from '../icons/IconAddons'
import InfoDialog from '../InfoDialog'

export const PremiumDataAlert = ({
  isOpen,
  setIsOpen
}: {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  className?: string
}) => {
  return (
    <InfoDialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title='What is Premium Data?'
      content='Premium data means your data will not be subject to deprioritization and slowdown when network traffic is high.'
    />
  )
}

export const PlanExtraData = ({
  plan,
  isSummary,
  className
}: {
  plan: Plan
  isSummary?: boolean
  className?: string
  prepend?: React.ReactNode
}) => {
  const [isPremiumDataDialogOpen, setIsPremiumDataDialogOpen] = React.useState(false)

  const planData = {
    hotspotData: plan.hotspotDataLimit,
    hotspotDataUnits: plan.hotspotDataLimitUnits,
    isHotspotUnlimited: plan.isHotspotUnlimited,
    premiumData: plan.premiumData,
    premiumDataUnits: plan.premiumDataUnits,
    isPremiumDataUnlimited: plan.isPremiumDataUnlimited,
    perksString: [
      ...(plan.streamingServices || []).map((service) => service.serviceName),
      ...(plan.roamingCountries?.length ? [`Roaming in ${plan.roamingCountries.length} countries`] : [])
    ]
  }

  return (
    <div className={className}>
      <IonRow className='ion-align-items-center plan-property'>
        <IonCol size='8' size-md='9' className='perk-first-col'>
          <IconHotspot className='perk-icon' />
          <span>Hotspot:</span>
        </IonCol>
        <IonCol size='4' size-md='3' className='ion-text-right'>
          <span style={{ color: planData.hotspotData || planData.isHotspotUnlimited ? 'inherit' : '#878787' }}>
            {planData.isHotspotUnlimited
              ? 'Unlimited'
              : planData.hotspotData
              ? planData.hotspotData + ' ' + (planData.hotspotDataUnits || '')
              : 'Not included'}
          </span>
        </IonCol>
      </IonRow>
      <IonRow className='ion-align-items-center plan-property'>
        <IonCol size='8' size-md='9' className='perk-first-col'>
          <IconPremiumData className='perk-icon' />
          <button
            className={cx('underline', TextButtonStyles)}
            onClick={(e) => {
              e.stopPropagation()
              setIsPremiumDataDialogOpen(true)
            }}
          >
            Premium Data:
          </button>
        </IonCol>
        <IonCol size='4' size-md='3' className='ion-text-right'>
          <span style={{ color: planData.premiumData || planData.isPremiumDataUnlimited ? 'inherit' : '#878787' }}>
            {planData.isPremiumDataUnlimited
              ? 'Unlimited'
              : (planData.premiumData || 0) + ' ' + (planData.premiumDataUnits || '')}
          </span>
        </IonCol>
      </IonRow>
      {planData.perksString.length > 0 && (
        <>
          <IonRow className='ion-align-items-center plan-property'>
            <IonCol size='12' className='perk-first-col'>
              <IconPerks className='perk-icon' />
              <span>Perks:</span>
            </IonCol>
            {planData.perksString.length ? (
              <ul className='perks ion-align-self-start'>
                {planData.perksString.slice(0, !isSummary ? 2 : planData.perksString.length).map((perk) => (
                  <li key={perk}>
                    <span className={cx('perk', !isSummary ? 'clamp' : null)} title={perk}>
                      {perk}
                    </span>
                  </li>
                ))}
              </ul>
            ) : null}
          </IonRow>
          {!isSummary && planData.perksString.length > 2 && (
            <IonRow className='ion-align-items-center plan-property'>
              <IonCol size='12' className='more-perks'>
                <span>+{planData.perksString.length - 2} more </span>
              </IonCol>
            </IonRow>
          )}
        </>
      )}
      <PremiumDataAlert isOpen={isPremiumDataDialogOpen} setIsOpen={setIsPremiumDataDialogOpen} />
    </div>
  )
}

function getDataText(data: number): string {
  if (data === DATA_UNLIMITED) {
    return 'Unlimited'
  } else if (data < 1) {
    return `${Math.round(data * 1000)} MB`
  }
  return `${data} GB`
}

function getVoiceText(voice: number): string {
  if (voice === VOICE_UNLIMITED) {
    return 'Unlimited'
  }
  return voice + ' min'
}

function getSmsText(sms: number): string {
  if (sms === SMS_UNLIMITED) {
    return 'Unlimited'
  }
  return sms + ' msg'
}

interface PlanInfoProps {
  plan: Plan
  alwaysExpanded?: boolean
  isSummary?: boolean
  className?: string
}

function getHotspotText(plan: Plan): { value: string; text: string } {
  if (plan.isHotspotUnlimited) return { value: 'Unlimited', text: 'Hotspot' }
  if (plan.hotspotDataLimit) return { value: `${plan.hotspotDataLimit} ${plan.hotspotDataLimitUnits}`, text: 'Hotspot' }
  return { value: 'Not included', text: 'Hotspot' }
}

function getPremiumDataText(plan: Plan): { value: string; text: string } {
  if (plan.isPremiumDataUnlimited) return { value: 'Unlimited', text: 'Premium/Prioritized Data' }
  return { value: `${plan.premiumData} ${plan.premiumDataUnits}`, text: 'Premium/Prioritized Data' }
}

function getGroupedPlanDetailsText(group: string, value: string): string {
  if (group === 'Data') {
    return `${value} data`
  } else if (group === 'Talk') {
    return `${value} min of calling`
  } else if (group === 'Text') {
    return `${value} texts`
  }
  return `${value} ${group}`
}

const PlanInfo: React.FC<PlanInfoProps> = ({ plan, className, alwaysExpanded, isSummary }) => {
  const [allExpanded] = useBoundStore((state) => [state.allExpanded])
  const [expanded, setExpanded] = React.useState(false)
  const [isPremiumDataDialogOpen, setIsPremiumDataDialogOpen] = React.useState(false)
  const planDetails = {
    ...(plan.data && { Data: getDataText(plan.data) }),
    ...(plan.voice && { Talk: getVoiceText(plan.voice) }),
    ...(plan.sms && { Text: getSmsText(plan.sms) })
  }
  const groupedPlanDetails: Record<string, string> = {}
  let repeated: [string, string] | undefined
  Object.entries(planDetails).forEach(([key, value]) => {
    if ((repeated = Object.entries(groupedPlanDetails).find(([_, val]) => val === value))) {
      groupedPlanDetails[
        `${repeated[0].includes('&') ? repeated[0].replace(' &', ', ') : repeated[0]}${
          repeated[0].includes('&') ? ', ' : ' & '
        }${key}`
      ] = repeated[1]
      delete groupedPlanDetails[repeated[0]]
    } else groupedPlanDetails[key] = value
  })

  const PlanBasics = {
    hotspot: getHotspotText(plan),
    premiumData: getPremiumDataText(plan)
  }

  React.useEffect(() => setExpanded(allExpanded), [allExpanded])

  return (
    <div className={cx('plan-info round-border ion-margin-top', PlanInfoStyles, className)}>
      {(expanded || alwaysExpanded) && !isSummary ? (
        <>
          {Object.entries(groupedPlanDetails)
            .sort((a) => (a[0] === 'Data' ? -1 : 0))
            .map(([group, value]) => (
              <div className='ion-align-items-center plan-property split-in-two' key={group + value}>
                <div className='perk-first-col'>
                  {group === 'Data' ? (
                    <IconSignalCellular className='perk-icon' />
                  ) : group === 'Talk' ? (
                    <IconSignalVoice className='perk-icon' />
                  ) : group === 'Text' ? (
                    <IconText className='perk-icon' />
                  ) : null}
                  {group}:
                </div>
                <div className='ion-text-right'>{value}</div>
              </div>
            ))}
          <PlanExtraData plan={plan} className={PlanInfoStyles} isSummary={isSummary} />
        </>
      ) : null}

      {Object.values(planDetails).length && !alwaysExpanded && !isSummary ? (
        <IonRow
          className='ion-justify-content-between ion-align-items-center expand-collapse-btn'
          onClick={(event) => {
            event.stopPropagation()
            setExpanded(!expanded)
          }}
        >
          {expanded ? 'Show less' : 'Show more'}
          <IonIcon color='medium' icon={expanded ? chevronUpOutline : chevronDownOutline} />
        </IonRow>
      ) : null}

      {isSummary ? (
        <div className={PlanInfoSummaryStyles}>
          <IonRow className='section-header'>
            <IconPlan className='perk-icon' />
            Plan Basics
          </IonRow>
          <IonRow>
            <ul>
              {Object.entries(groupedPlanDetails).map(([group, value]) => (
                <li key={group + value}>{getGroupedPlanDetailsText(group, value)}</li>
              ))}
              {PlanBasics.hotspot.value ? (
                <li>
                  {PlanBasics.hotspot.value} {PlanBasics.hotspot.text}
                </li>
              ) : (
                <li>No included hotspot</li>
              )}

              {PlanBasics.premiumData.value ? (
                <li>
                  {PlanBasics.premiumData.value}{' '}
                  <button
                    className={cx('underline', TextButtonStyles)}
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsPremiumDataDialogOpen(true)
                    }}
                  >
                    {PlanBasics.premiumData.text}
                  </button>
                </li>
              ) : (
                <li>
                  <button
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsPremiumDataDialogOpen(true)
                    }}
                    className={cx('underline', TextButtonStyles)}
                  >
                    No included premium data
                  </button>
                </li>
              )}
            </ul>
          </IonRow>
          {plan.streamingServices?.length || plan.roamingCountries?.length ? (
            <>
              <div className='divider' />
              <IonRow className='section-header'>
                <IconPerks className='perk-icon' />
                Perks & Benefits
              </IonRow>
              <IonRow>
                <ul>
                  {plan.streamingServices?.map((service) => (
                    <li className='streaming-service' key={service.serviceName}>
                      <span>{service.serviceName}*</span>
                      <span className='service-price'>${service.marketValue} value</span>
                    </li>
                  ))}
                  {plan.streamingServices?.length === 1 && (
                    <p className='clarification-streaming-services'>
                      * See carrier website for additional details around streaming duration
                    </p>
                  )}

                  {plan.roamingCountries?.length ? (
                    <li>{`Roaming in ${plan.roamingCountries.length} countries`}</li>
                  ) : null}

                  {plan.streamingServices?.length! > 1 && (
                    <p className='clarification-streaming-services'>
                      * See carrier website for additional details around streaming duration
                    </p>
                  )}
                </ul>
              </IonRow>
            </>
          ) : null}
          {plan.addons.length ? (
            <>
              <div className='divider' />
              <IonRow className='section-header'>
                <IconAddons className='perk-icon' />
                Add-ons
              </IonRow>
              <IonRow>
                <ul>
                  {plan.addons?.map((addon) => (
                    <li key={addon.id}>{addon.addonDescription}</li>
                  ))}
                </ul>
              </IonRow>
            </>
          ) : null}
        </div>
      ) : null}
      <PremiumDataAlert isOpen={isPremiumDataDialogOpen} setIsOpen={setIsPremiumDataDialogOpen} />
    </div>
  )
}

export default PlanInfo
