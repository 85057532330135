export enum ReasonForSwitch {
  Cost = 'PlanCost',
  Network = 'NetworkCoverage',
  Data = 'DataAllowance',
  Hotspot = 'Hotspot',
  InternationalCalling = 'InternationalCalling',
  Roaming = 'Roaming',
  StreamingServices = 'StreamingServices',
  ContractType = 'ContractType',
  Other = 'Other'
}

export enum ContractType {
  Postpaid = 'Postpaid',
  Both = 'Postpaid+Prepaid'
}

export enum ContractTypeV3 {
  Postpaid = 'Postpaid',
  Prepaid = 'Prepaid'
}
