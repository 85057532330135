import { close, heartOutline } from 'ionicons/icons'
import { OverlayBlockStyles } from '../PlanSwipe.styles'
import { IonIcon } from '@ionic/react'

export enum PlanOverlayType {
  LIKE = 'like',
  PASS = 'pass',
  UNSURE = 'unsure'
}

interface OverlayBlockProps {
  type: PlanOverlayType
}

const OverlayBlock = ({ type }: OverlayBlockProps) => {
  return (
    <div className={OverlayBlockStyles}>
      {type === PlanOverlayType.LIKE && (
        <>
          <IonIcon icon={heartOutline} />
          <span>LIKE</span>
        </>
      )}
      {type === PlanOverlayType.PASS && (
        <>
          <IonIcon icon={close} />
          <span>PASS</span>
        </>
      )}
      {type === PlanOverlayType.UNSURE && (
        <>
          <IonIcon src='/assets/images/barry/barry_unsure.svg' className='barry-unsure' />
          <span>Unsure</span>
        </>
      )}
    </div>
  )
}

export default OverlayBlock
