import { css } from '@emotion/css'
import { IonImg, IonRow } from '@ionic/react'

const AppPromotionCardStyles = css`
  border: 2px solid black;
  background-color: var(--ion-color-secondary-background);
  color: white;
  margin: 0;
  border-radius: 10px;
  row-gap: 20px;
  display: flex;
  padding: 0 16px 16px 16px;
  text-align: left;
  align-items: center;
  font-size: 14px;
  position: relative;
  padding-right: 140px;

  .app-promotion {
    position: absolute;
    top: 18px;
    right: 18px;
    height: 144px;
    flex-shrink: 0;
  }
`

export const AppPromotionCard = () => {
  return (
    <div className={AppPromotionCardStyles}>
      <div>
        <h2>Now available for Android and iOS</h2>
        <p>Take Goji Mobile with you wherever you go and ditch the old way of shopping for phone plans.</p>
        <IonRow>
          {/* TODO: redirect to stores with these buttons */}
          <IonImg src='/assets/images/signin/google-play-badge.svg' />
          <IonImg src='/assets/images/signin/app-store-badge.svg' />
        </IonRow>
      </div>
      <IonImg className='app-promotion' src='/assets/images/welcome/app-promotion.webp' alt='App promotion' />
    </div>
  )
}
