import { Address } from '../../../store/address'
import { FeatureFlagType } from '../../../utils/FeatureFlags'
import { ServerResponse, ValidatedResult } from '../ServerResponse'
import { TOOL_NAME } from '../tools/const/tool.const'

export const SuggestionType = {
  TEXT: 0,
  TEXT_WITH_IMAGE: 1,
  TEXT_WITH_ICON: 2,
  MULTISELECT: 3,
  CURRENT_LOCATION: 4,
  LOCATION: 5,
  LINK: 6,
  LINK_WITH_ICON: 7,
  TOOL_SELECTION: 8
} as const

export type TextSuggestion = {
  type: typeof SuggestionType.TEXT
} & SelectableSuggestion

export type TextWithImageSuggestion = {
  type: typeof SuggestionType.TEXT_WITH_IMAGE
  imageUrl: string
} & SelectableSuggestion

export type TextWithIconSuggestion = {
  type: typeof SuggestionType.TEXT_WITH_ICON
  icon?: string
  src?: string
  size?: 'inline' | 'large'
} & SelectableSuggestion

export type CurrentLocationSuggestion = {
  type: typeof SuggestionType.CURRENT_LOCATION
  icon?: string
} & SelectableSuggestion

export type LocationSuggestion = {
  type: typeof SuggestionType.LOCATION
  location: Address
} & SelectableSuggestion

export type LinkSuggestion = {
  type: typeof SuggestionType.LINK
  routerLink: string
} & Omit<SelectableSuggestion, 'value'>

export type LinkWithIconSuggestion = {
  type: typeof SuggestionType.LINK_WITH_ICON
  routerLink: string
  icon?: string
  src?: string
  size?: 'inline' | 'large'
} & SelectableSuggestion

export type ToolSelectionSuggestion = {
  type: typeof SuggestionType.TOOL_SELECTION
  toolName: (typeof TOOL_NAME)[keyof typeof TOOL_NAME]
  icon?: string
  src?: string
  size?: 'inline' | 'large'
} & SelectableSuggestion

export type Suggestion =
  | TextSuggestion
  | TextWithImageSuggestion
  | TextWithIconSuggestion
  | CurrentLocationSuggestion
  | LocationSuggestion
  | LinkSuggestion
  | LinkWithIconSuggestion
  | ToolSelectionSuggestion

type SelectableSuggestion = {
  text: string
  value: {
    parsedAnswer: ServerResponse['parsedAnswer']
    resultStatus?: ValidatedResult
  }
  featureFlag?: FeatureFlagType
}
