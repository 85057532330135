import { css, cx } from '@emotion/css'

const MessageStyles = css`
  display: flex;
  max-width: 93%;
  @media (max-width: 375px) {
    max-width: 90%;
  }

  position: relative;

  &.has-barry {
    margin-top: 50px;
  }

  .barry-face {
    position: absolute;
    top: -50px;
    width: 60px;
    height: 60px;
  }

  &.bot-message .chat-box {
    background-color: var(--ion-color-primary-tint);
  }
  &.my-message {
    align-self: flex-end;
    .chat-box {
      background-color: var(--goji-color-secondary-blue-1);
      color: var(--ion-color-secondary-contrast);
    }
  }

  .chat-box {
    padding: 16px 24px;
    border-radius: 30px;
    word-break: break-word;
  }
`
export interface MessageProps {
  children: React.ReactNode
  from: 'user' | 'bot'
  className?: string
  hasBarry?: boolean
}
function Message(props: MessageProps) {
  return (
    <div
      role='listitem'
      className={cx(
        MessageStyles,
        props.from === 'user' ? 'my-message' : 'bot-message',
        props.hasBarry ? 'has-barry' : null,
        props.className
      )}
    >
      {props.hasBarry && (
        <img src='/assets/images/chat/barry_chat.svg' className='barry-face' alt='goji barry chat face' />
      )}
      <div className='chat-box'>{props.children}</div>
    </div>
  )
}

export default Message
