import { css, cx } from '@emotion/css'
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, isPlatform } from '@ionic/react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router'
import shallow from 'zustand/shallow'
import useAuthStore from '../../store/auth'
import HomePage from '../home/HomePage'
import { MainNavigationPaths } from '../../paths'
import { ALL_PLANS_TAB, RECOMMENDED_TAB } from '../plans/tabs/PlanTab'
import { trackAppsFlyerEvent } from '../../plugin/AppsFlyerPlugin'
import * as React from 'react'
import { withSuspense } from './withSuspense'
import SummaryPage from '../summary/SummaryPage'

const PlansPage = withSuspense(React.lazy(() => import('../plans/PlansPage')))
const MapPage = withSuspense(React.lazy(() => import('../map/MapPage')))
const MeterPage = withSuspense(React.lazy(() => import('../meter/MeterPage')))
const SignInPage = withSuspense(React.lazy(() => import('../sign-in/SignIn')))
const SignUpPage = withSuspense(React.lazy(() => import('../sign-in/SignUp')))
const ProfileMenuPage = withSuspense(React.lazy(() => import('../profile/ProfileMenuPage')))

function getIconUrl(icon: string, dark: boolean) {
  return `/assets/icons/footer/${dark ? 'dark/' : ''}${icon}.svg`
}

function ActivatableIcon(props: { icon: string; active: boolean }) {
  return (
    <>
      <IonIcon style={{ display: !props.active ? 'none' : 'block' }} icon={getIconUrl(props.icon, true)} />
      <IonIcon style={{ display: props.active ? 'none' : 'block' }} icon={getIconUrl(props.icon, false)} />
    </>
  )
}

function trackMapClick() {
  trackAppsFlyerEvent({ eventName: 'af_network_map_interaction' })
}

const TabBarStyles = css`
  width: 100%;
  --border: 1px solid var(--ion-tab-bar-border-color);
  --ion-tab-bar-color: #4d4d4d;

  .tab-selected ion-label {
    font-weight: 700;
  }

  &.is-desktop {
    display: none;
  }
`

export function MainNavigation(props: RouteComponentProps) {
  const [currentUser] = useAuthStore((state) => [state.currentUser], shallow)
  const isMobile = isPlatform('ios') || isPlatform('android')

  return (
    <IonTabs>
      <IonRouterOutlet ionPage>
        <Switch>
          <Route exact path='/' render={() => <Redirect to='/home' />} />
          <Route exact path={MainNavigationPaths.home} render={() => <HomePage />} />
          <Route exact path={MainNavigationPaths.meter} render={() => <MeterPage />} />
          <Route
            exact
            path={MainNavigationPaths.plans}
            render={() => <Redirect to={MainNavigationPaths.plans + '/' + ALL_PLANS_TAB} />}
          />
          <Route
            exact
            path={MainNavigationPaths.plans + '/' + ALL_PLANS_TAB + '/:carrierId/:planId'}
            render={() => <SummaryPage />}
          />
          <Route exact path={MainNavigationPaths.plans + '/:tab'} render={() => <PlansPage />} />
          <Route exact path={MainNavigationPaths.map} component={() => <MapPage />} />
          <Route exact path={MainNavigationPaths.signIn} component={() => <SignInPage />} />
          <Route exact path={MainNavigationPaths.createAccount} component={() => <SignUpPage />} />
          <Route
            exact
            path={MainNavigationPaths.results}
            render={() => <Redirect to={MainNavigationPaths.plans + '/' + RECOMMENDED_TAB} />}
          />
          <Route exact path={MainNavigationPaths.profile} component={() => <ProfileMenuPage />} />
        </Switch>
      </IonRouterOutlet>

      <IonTabBar
        key={props.location.pathname}
        className={cx(TabBarStyles, isMobile ? 'custom-width-limiter' : 'is-desktop')}
        slot='bottom'
      >
        <IonTabButton tab='home' href={MainNavigationPaths.home}>
          <ActivatableIcon
            icon='home'
            active={
              props.location.pathname === MainNavigationPaths.home ||
              props.location.pathname === MainNavigationPaths.root
            }
          />
          <IonLabel>Home</IonLabel>
        </IonTabButton>
        <IonTabButton tab='plans' href={MainNavigationPaths.plans}>
          <ActivatableIcon
            icon='plans'
            active={
              props.location.pathname.startsWith(MainNavigationPaths.plans) ||
              props.location.pathname === MainNavigationPaths.results
            }
          />
          <IonLabel>Plans</IonLabel>
        </IonTabButton>
        <IonTabButton onClick={trackMapClick} tab='map' href={MainNavigationPaths.map}>
          <ActivatableIcon icon='map' active={props.location.pathname === MainNavigationPaths.map} />
          <IonLabel>Map</IonLabel>
        </IonTabButton>
        <IonTabButton tab='meter' href={MainNavigationPaths.meter}>
          <ActivatableIcon icon='meter' active={props.location.pathname === MainNavigationPaths.meter} />
          <IonLabel>Meter</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab='profile'
          href={currentUser?.token ? MainNavigationPaths.profile : MainNavigationPaths.signIn}
        >
          <ActivatableIcon
            icon='profile'
            active={[
              MainNavigationPaths.profile,
              MainNavigationPaths.signIn,
              MainNavigationPaths.createAccount
            ].includes(props.location.pathname)}
          />
          <IonLabel>Profile</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  )
}
