import { css } from '@emotion/css'

export const ChatMenuStyles = css`
  .chatbot-content {
    justify-content: end;
  }
`

export const headerStyles = css`
  --ion-background-color: var(--goji-primary-lime);
  box-shadow: 0px 2px 0px 0px #0000008c;

  .chat-reset {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    height: 22px;
    z-index: 2;
  }

  .chat-title {
    text-align: center;
    font-weight: 900;
    font-size: 18px;
    height: 100%;
  }

  .chat-close {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    height: 22px;
  }
`

export const ChatbotContentContainerStyles = css`
  .chatbot-content {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 24px 18px;
  }
`
export const ChatbotPageStyles = css`
  .chat-toolbar-internal {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    top: 0;
  }
`
