import API, { IApi } from '../../api'
import { Address } from '../../store/address'
import { BackendUser } from '../../types/BackendUser'

export class ProfileService {
  constructor(private api: IApi = API) {}

  static instance = new ProfileService()
  static get() {
    return ProfileService.instance
  }

  async getBackendUser() {
    return await this.api.get<BackendUser>('/profile')
  }

  async getAddresses() {
    return await this.api.get<Address[]>('/profile/addresses')
  }
}
