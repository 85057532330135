import { css } from '@emotion/css'
import { IonSpinner } from '@ionic/react'
import { UseCarrier } from '../../../services/Carrier.service'
import { Carrier } from '../../../store/carrierSelector'

const getCarrier = (carriers: Carrier[], carrierId: string) => {
  return carriers.find((carrier) => carrier.id === carrierId)
}

const CarrierImageStyles = css`
  max-height: 44px;
`
export const CarrierImage = (props: { carrierId: string }) => {
  const carriers = UseCarrier.useAllCarriers()
  const carrier = carriers.data ? getCarrier(carriers.data, props.carrierId) : undefined

  if (carriers.isLoading) {
    return <IonSpinner />
  }

  return carrier?.logo_url ? (
    <img className={CarrierImageStyles} src={carrier.logo_url} alt={carrier.name} />
  ) : (
    <>{carrier?.name}</>
  )
}
