import React, { useCallback, useEffect } from 'react'
import { Address } from '../../../store/address'
import { useBoundStore } from '../../../store'
import { NetworkType, NetworkTypeValue } from '../../../services/NetworkType.service'
import { UseCarrier } from '../../../services/Carrier.service'
import { Carrier } from '../../../store/carrierSelector'
import { getAddressFromPlaceId } from '../../../utils/Address'

interface useMapStateByParamsProps {
  onSelectAddress: (address: Address) => void
  onSwitchNetworkType: (networkType: NetworkTypeValue) => void
  onSelectCarrier: (carrier: Carrier) => void
  map: google.maps.Map | undefined
}

export const useMapStateByParams = ({
  onSelectAddress,
  onSwitchNetworkType,
  onSelectCarrier,
  map
}: useMapStateByParamsProps) => {
  const urlParams = React.useMemo(() => new URLSearchParams(window.location.search), [])
  const [technology, carrierId, placeId] = [
    urlParams.get('technology'),
    urlParams.get('carrier'),
    urlParams.get('placeId')
  ]

  const carriers = UseCarrier.useAllCarriers()
  const [addresses, setAddress] = useBoundStore((state) => [state.addresses, state.setAddress])

  const handleNetworkType = useCallback(() => {
    const uppercaseTechnology = technology?.toUpperCase()
    if (uppercaseTechnology && Object.keys(NetworkType).includes(uppercaseTechnology)) {
      onSwitchNetworkType(NetworkType[uppercaseTechnology as keyof typeof NetworkType] as NetworkTypeValue)
      urlParams.delete('technology')
    }
  }, [technology, urlParams, onSwitchNetworkType])

  const handleCarrier = useCallback(() => {
    if (carrierId && carriers.data?.length) {
      const selectedCarrier = carriers.data.find((c) => c.id === carrierId)
      if (selectedCarrier) {
        onSelectCarrier(selectedCarrier)
        urlParams.delete('carrier')
      }
    }
  }, [carrierId, carriers.data, onSelectCarrier, urlParams])

  const handleAddress = useCallback(async () => {
    if (placeId && map) {
      let address = addresses.find((a) => a.placeId === placeId)
      if (!address) {
        address = await getAddressFromPlaceId(placeId)
        if (!address) return
        setAddress({ ...address, localMemory: true })
      }
      onSelectAddress(address)
      map?.setZoom(15)
      urlParams.delete('placeId')
    }
  }, [placeId, urlParams, addresses, onSelectAddress, map, setAddress])

  useEffect(() => {
    if (technology) handleNetworkType()
  }, [handleNetworkType, technology])

  useEffect(() => {
    if (carrierId) handleCarrier()
  }, [handleCarrier, carrierId])

  useEffect(() => {
    if (placeId) handleAddress()
  }, [handleAddress, placeId])
}
