import React from 'react'
import { Logger } from '../../../utils/Logger'
import { PredefinedChatMessageRecord } from '../ChatFlow'
import { ServerResponse, ValidatedResult, ValidatedResults } from '../ServerResponse'
import {
  BotMessage,
  PredefinedChatStep,
  IChatFlow,
  MessageType,
  invalidTemplate,
  ChatInteraction,
  ChatTextMessage,
  ChatStep
} from '../types/ChatStep'
import { Suggestion, SuggestionType } from '../types/Suggestion'
import { useBoundStore } from '../../../store'
import { getNextStep, getRecordMessage, isChatKeyRecord, isUserMessageRecord } from '../utils/chatFlowIdentify'
import { getAddressFromPlaceId } from '../../../utils/Address'
import { Address, LabelType } from '../../../store/address'
import { trackAmplitudeEvent } from '../../../utils/amplitude'
import { FreeChatMessageRecord } from '../types/free-chat.types'
import { TOOL_NAME } from '../tools/const/tool.const'

export function useSuggestionSelection({
  onMessage,
  onResponse
}: {
  onMessage?: (payload: OnMessagePayload<any>) => void | Promise<void>
  onResponse: (
    serverResponse: { resultStatus: ValidatedResult; address?: Address; parsedAnswer: any },
    options?: { isSuggestionResponse?: boolean; userAnswer?: string }
  ) => void
}) {
  const [setUserLocationAndAddress] = useBoundStore((state) => [state.setUserLocationAndAddress])
  const onCurrentAddressSelected = React.useCallback(async () => {
    const location = await setUserLocationAndAddress()
    if (location) {
      onMessage?.({
        isSuggestionResponse: true,
        messageRecord: {
          message: {
            from: 'user',
            type: MessageType.TEXT,
            text: location.address?.addressName ?? 'My current location'
          }
        }
      })
      onResponse({
        resultStatus: ValidatedResults.VALID,
        address: location.address,
        parsedAnswer: location
      })
    } else {
      onMessage?.({
        messageRecord: {
          message: {
            from: 'user',
            type: MessageType.TEXT,
            text: 'Location not found'
          }
        }
      })
      onResponse({ resultStatus: ValidatedResults.INVALID, parsedAnswer: null })
    }
  }, [onResponse, setUserLocationAndAddress, onMessage])

  const onLocationSelected = React.useCallback(
    (address: Address) => {
      onMessage?.({
        isSuggestionResponse: true,
        messageRecord: {
          message: {
            from: 'user',
            type: MessageType.TEXT,
            text: address.label ?? address.addressName
          }
        }
      })
      onResponse({ resultStatus: ValidatedResults.VALID, address, parsedAnswer: address })
    },
    [onMessage, onResponse]
  )

  const onSuggestionSelected = React.useCallback(
    async (suggestion: Suggestion) => {
      if (suggestion.type === SuggestionType.LINK) {
        return
      }
      if (suggestion.type === SuggestionType.CURRENT_LOCATION) {
        await onCurrentAddressSelected()
        return
      }
      if (suggestion.type === SuggestionType.LOCATION) {
        onLocationSelected(suggestion.location)
        return
      }
      await onMessage?.({
        messageRecord: {
          message: {
            from: 'user',
            type: MessageType.TEXT,
            text: suggestion.text
          },
          toolSuggestion: suggestion.type === SuggestionType.TOOL_SELECTION ? suggestion.value.parsedAnswer : undefined
        }
      })

      onResponse({ resultStatus: ValidatedResults.VALID, ...suggestion.value }, { isSuggestionResponse: true })
    },
    [onMessage, onResponse, onCurrentAddressSelected, onLocationSelected]
  )

  return {
    onSuggestionSelected
  }
}

export function getLastQuestionKey<T extends IChatFlow>(
  chatTimeline: PredefinedChatMessageRecord<T>[],
  chatFlow: T
): string | undefined {
  for (let i = chatTimeline.length - 1; i >= 0; i--) {
    const record = chatTimeline[i]
    if (isChatKeyRecord(chatFlow, record) && chatFlow[record.key]?.type === MessageType.STEP) {
      return String(record.key)
    }
  }
}

export function getLastQuestion<T extends IChatFlow>(
  chatTimeline: PredefinedChatMessageRecord<T>[],
  chatFlow: T
): PredefinedChatStep | undefined {
  const lastQuestionKey = getLastQuestionKey(chatTimeline, chatFlow)
  return lastQuestionKey ? (chatFlow[lastQuestionKey] as PredefinedChatStep) : undefined
}

export type OnMessagePayload<
  R = FreeChatMessageRecord & { toolSuggestion?: (typeof TOOL_NAME)[keyof typeof TOOL_NAME] }
> = {
  messageRecord: R
  isSuggestionResponse?: boolean
}

interface GetMessageRecordFromStatusPayload {
  validatedResult: ValidatedResult
  outOfContextResponse: string
  invalidResponse?: string
  lastQuestion: ChatStep
  userAnswer?: string
}
export function getInvalidMessages({
  outOfContextResponse,
  validatedResult,
  lastQuestion,
  userAnswer,
  invalidResponse
}: GetMessageRecordFromStatusPayload): (ChatTextMessage | ChatStep)[] | undefined {
  switch (validatedResult) {
    case ValidatedResults.FAILED:
      return [
        {
          from: 'bot',
          type: MessageType.STEP,
          question: [{ text: 'Sorry, something went wrong on our end! Could you enter your response again?' }],
          suggestions: lastQuestion.suggestions,
          interactions: lastQuestion.interactions
        }
      ]
    case ValidatedResults.CANCELLED:
      return [
        {
          from: 'bot',
          type: MessageType.STEP,
          question: [{ text: 'Message cancelled. How else can I help you?' }],
          interactions: [ChatInteraction.FREE_TEXT],
          suggestions: []
        }
      ]
    case ValidatedResults.OUT_OF_CONTEXT:
      trackAmplitudeEvent('ChatBarry - Invalid Response', {
        question: lastQuestion.question.map((q) => q.text).join(''),
        userAnswer
      })
      return [
        {
          from: 'bot',
          type: MessageType.STEP,
          question: [{ text: outOfContextResponse }],
          suggestions: lastQuestion.suggestions,
          interactions: lastQuestion.interactions
        }
      ]
    case ValidatedResults.INVALID:
      trackAmplitudeEvent('ChatBarry - Invalid Response', {
        question: lastQuestion.question.map((q) => q.text).join(''),
        userAnswer: userAnswer
      })
      if (!!invalidResponse) {
        return [
          {
            from: 'bot',
            type: MessageType.TEXT,
            text: invalidTemplate
          },
          {
            from: 'bot',
            type: MessageType.STEP,
            question: [{ text: invalidResponse }],
            interactions: lastQuestion.interactions,
            suggestions: lastQuestion.suggestions
          }
        ]
      } else {
        return [
          {
            from: 'bot',
            type: MessageType.TEXT,
            text: outOfContextResponse
          }
        ]
      }
  }
}

export interface UsePredefinedChatAttributes<T extends IChatFlow> {
  sendAnswerToLLM: (payload: { question: PredefinedChatStep; userAnswer: string }) => Promise<ServerResponse>
  timelineRecords: PredefinedChatMessageRecord<T>[]
  onMessage?: (payload: OnMessagePayload<PredefinedChatMessageRecord<T>>) => void | Promise<void>
  onServerResponse: (parsedAnswer: any, lastQuestionKey?: keyof T) => void
  chatFlow: T
}

export function usePredefinedChat<T extends IChatFlow>({
  sendAnswerToLLM,
  chatFlow,
  timelineRecords,
  onMessage,
  onServerResponse
}: UsePredefinedChatAttributes<T>) {
  const [setAddress] = useBoundStore((state) => [state.setAddress])

  const lastQuestionKey = getLastQuestionKey(timelineRecords, chatFlow)
  const lastQuestion = lastQuestionKey ? (chatFlow[lastQuestionKey] as PredefinedChatStep) : undefined

  const moveChatForward = React.useCallback(
    async (
      message: BotMessage<PredefinedChatStep>,
      serverResponse: ServerResponse | undefined,
      isSuggestionResponse?: boolean
    ) => {
      const nextStep = getNextStep(chatFlow, message, serverResponse)
      if (!nextStep) {
        return
      }

      await onMessage?.({ messageRecord: nextStep, isSuggestionResponse })
      const type = chatFlow[nextStep.key].type
      if (type === MessageType.TEXT || type === MessageType.TRANSITION) {
        moveChatForward(chatFlow[nextStep.key], undefined, isSuggestionResponse)
      }
    },
    [chatFlow, onMessage]
  )

  const reactToServerResponse = React.useCallback(
    (serverResponse: ServerResponse, options?: { isSuggestionResponse?: boolean; userAnswer?: string }) => {
      if (!lastQuestion || !lastQuestionKey) {
        Logger.error('could not find last question in [timeline, flow]: ', lastQuestionKey)
        return
      }

      let invalidMessages = getInvalidMessages({
        validatedResult: serverResponse.resultStatus,
        outOfContextResponse: lastQuestion.outOfContextResponse,
        lastQuestion,
        invalidResponse:
          lastQuestion.getInvalidResponse && !!serverResponse.validation
            ? lastQuestion.getInvalidResponse(serverResponse.validation)
            : '',
        userAnswer: options?.userAnswer
      })

      if (
        serverResponse.resultStatus === ValidatedResults.UNKNOWN &&
        lastQuestion.type === MessageType.STEP &&
        !Object.keys(lastQuestion.nextPaths).includes('$default')
      ) {
        invalidMessages = [
          {
            from: 'bot',
            type: MessageType.TEXT,
            text: lastQuestion.outOfContextResponse
          }
        ]
      }

      if (invalidMessages) {
        invalidMessages.forEach((message) => {
          onMessage?.({ messageRecord: { message } as FreeChatMessageRecord })
        })
        return
      }

      onServerResponse(serverResponse.parsedAnswer, lastQuestionKey)
      moveChatForward(lastQuestion, serverResponse, options?.isSuggestionResponse)
    },
    [lastQuestion, lastQuestionKey, moveChatForward, onMessage, onServerResponse]
  )

  const onAddressSend = React.useCallback(
    async (prediction: google.maps.places.AutocompletePrediction) => {
      onMessage?.({
        messageRecord: {
          message: {
            from: 'user',
            type: MessageType.TEXT,
            text: prediction?.description ?? 'Address not found'
          }
        }
      })

      const address = await getAddressFromPlaceId(prediction.place_id)
      if (!!address) {
        setAddress({
          ...address,
          label: address.addressName,
          labelType: LabelType.Other
        })
      }
      reactToServerResponse({ resultStatus: ValidatedResults.VALID, parsedAnswer: address?.addressName })
    },
    [reactToServerResponse, onMessage, setAddress]
  )

  const onSuggestionResponse = React.useCallback(
    (
      serverResponse: { resultStatus: ValidatedResult; address?: Address; parsedAnswer: any },
      options?: { isSuggestionResponse?: boolean; userAnswer?: string }
    ) => {
      reactToServerResponse(
        {
          resultStatus: serverResponse.resultStatus,
          parsedAnswer: serverResponse.address?.addressName ?? serverResponse.parsedAnswer
        },
        options
      )
    },
    [reactToServerResponse]
  )
  const { onSuggestionSelected } = useSuggestionSelection({ onMessage, onResponse: onSuggestionResponse })

  const onChatSend = React.useCallback(
    (text: string) => {
      if (lastQuestion) {
        sendAnswerToLLM({ question: lastQuestion, userAnswer: text }).then((res) =>
          reactToServerResponse(res, { isSuggestionResponse: false, userAnswer: text })
        )
      } else {
        Logger.error('could not find last question in timeline: ', lastQuestion)
      }

      onMessage?.({
        messageRecord: {
          message: {
            text,
            from: 'user',
            type: MessageType.TEXT
          }
        }
      })
    },
    [lastQuestion, reactToServerResponse, onMessage, sendAnswerToLLM]
  )

  const timeline = timelineRecords.map((record) => getRecordMessage(chatFlow, record)).filter((m) => m)

  return {
    timeline,
    onChatSend,
    onAddressSend,
    lastQuestion,
    onSuggestionSelected
  }
}

export function useTimeline<T extends IChatFlow>() {
  const [chatTimeline, setChatTimeline] = React.useState<PredefinedChatMessageRecord<T>[]>([{ key: 'entrypoint' }])
  const [userAnswers, setUserAnswers] = React.useState<Record<keyof T, any>>({} as Record<keyof T, any>)

  const onMessage = React.useCallback((record: PredefinedChatMessageRecord<T>, lastQuestionKey?: keyof T) => {
    if (isUserMessageRecord(record) && lastQuestionKey) {
      setUserAnswers((prev) => ({ ...prev, [lastQuestionKey]: record.message.text }))
    }

    setChatTimeline((prev) => [...prev, record])
  }, [])

  const onRestart = React.useCallback(() => {
    setChatTimeline([{ key: 'entrypoint' }])
  }, [])

  return { chatTimeline, setChatTimeline, setUserAnswers, userAnswers, onMessage, onRestart }
}
