import React from 'react'

export function useScrollToBottom(
  scrollArea: React.RefObject<HTMLIonContentElement>,
  list: unknown[],
  isLoading = false
) {
  React.useEffect(() => {
    if (!scrollArea.current) {
      return
    }

    setTimeout(() => {
      if (!scrollArea.current) {
        return
      }
      scrollArea.current.scrollToBottom(200)
    }, 0)
  }, [list, scrollArea, isLoading])
}
