import { StateCreator } from 'zustand'

export type ExpandedPlansState = {
  allExpanded: boolean
}

export type ExpandedPlansActions = {
  collapseAll: () => void
  expandAll: () => void
}

export const expandedPlansInitialState: ExpandedPlansState = {
  allExpanded: false
}

const createExpandedPlansSlice: StateCreator<
  ExpandedPlansState & ExpandedPlansActions,
  [],
  [],
  ExpandedPlansState & ExpandedPlansActions
> = (set) => ({
  ...expandedPlansInitialState,
  collapseAll: () => {
    set(() => ({ allExpanded: false }))
  },
  expandAll: () => {
    set(() => ({ allExpanded: true }))
  }
})

export default createExpandedPlansSlice
