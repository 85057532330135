import { AwsRum, AwsRumConfig } from 'aws-rum-web'
import { Logger } from './Logger'

export const RumSingleton = {
  rum: null as AwsRum | null
}

export function initRum() {
  const REACT_APP_AWS_CLOUDWATCH_RUM_ENABLED: boolean = process.env.REACT_APP_AWS_CLOUDWATCH_RUM_ENABLED === 'true'

  if (!REACT_APP_AWS_CLOUDWATCH_RUM_ENABLED) {
    return
  }

  try {
    const config: AwsRumConfig = {
      sessionSampleRate: Number(process.env.REACT_APP_AWS_CLOUDWATCH_RUM_SESSION_SAMPLE_RATE) || 0.0,
      identityPoolId: process.env.REACT_APP_AWS_CLOUDWATCH_RUM_IDENTITY_POOL_ID,
      endpoint: process.env.REACT_APP_AWS_CLOUDWATCH_RUM_ENDPOINT,
      telemetries: (process.env.REACT_APP_AWS_CLOUDWATCH_RUM_TELEMETRIES ?? 'errors').split(','),
      allowCookies: true,
      enableXRay: false
    }

    if (!process.env.REACT_APP_AWS_CLOUDWATCH_RUM_APPLICATION_ID) {
      throw new Error('No application ID provided for CloudWatch RUM')
    }

    const APPLICATION_VERSION: string = process.env.REACT_APP_GOJI_VERSION ?? 'unknown'
    const APPLICATION_REGION = 'us-east-1'

    RumSingleton.rum = new AwsRum(
      process.env.REACT_APP_AWS_CLOUDWATCH_RUM_APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    )
  } catch (error) {
    Logger.error('Could not initialize CloudWatch RUM', error)
  }
}
