import { IonButton, IonImg } from '@ionic/react'
import React from 'react'
import { useQueryClient } from 'react-query'
import CloseButton from '../../components/CloseButton/CloseButton'
import TModal, { TModalHeading } from '../../components/TModal/TModal'
import { SignalStrengthService } from '../../services/SignalStrengthService'
import { MapPermissionsPopupStyles } from './LocationPermissionModal'
import { BarryWrapperStyles } from './MapPermissionsPopup'
import { bgLocationKey, useBackgroundLocation, useRefetchPermissions } from './useLocationPermissions'

export function BgLocationModal(props: { onClose: () => void; body?: React.ReactNode | React.ReactNode[] }) {
  const queryClient = useQueryClient()
  const { requestBgLocation } = useBackgroundLocation()
  const [showSettingsButton, setShowSettingsButton] = React.useState(false)
  const refetchPermissions = useRefetchPermissions()

  async function onClickConfigure() {
    const result = await requestBgLocation()
    queryClient.setQueryData(bgLocationKey, { isGranted: result.isGranted })

    if (!result.isGranted) {
      if (!result.shouldShowRationale) {
        setShowSettingsButton(true)
        return
      }
      props.onClose()
    }
  }

  return (
    <TModal className={MapPermissionsPopupStyles}>
      <TModalHeading>
        <div />
        <CloseButton onClose={props.onClose} />
      </TModalHeading>
      <div>
        <div className={BarryWrapperStyles}>
          <IonImg className='barry-sitting' src='/assets/images/barry/barry_sitting2.svg' alt='Barry Sitting' />
        </div>
        <h3>Grant background location access to Goji?</h3>
        <div>
          Setting your location access to <b>Allow all the Time</b> will allow Goji to access your location even when
          the app is closed.
        </div>
        <div className='ion-padding-top' />
        <div>
          This setting will help us improve our Coverage Map data, which in turn will ensure that Goji is able to make
          the best recommendations possible.
        </div>
        <div className='ion-padding-vertical' />

        {showSettingsButton ? (
          <IonButton
            onClick={async () => {
              props.onClose()
              await SignalStrengthService.goToSettings()
              refetchPermissions()
            }}
            size='large'
            shape='round'
            expand='block'
          >
            Go to Settings
          </IonButton>
        ) : (
          <IonButton onClick={onClickConfigure} size='large' shape='round' expand='block'>
            Configure Access
          </IonButton>
        )}
      </div>
    </TModal>
  )
}
