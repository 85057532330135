import { GeoBoundingBox, TileIndex, TileLoadProps } from '@deck.gl/geo-layers/typed/tileset-2d/types'
import { GeoJsonRecord } from './Geodata.service'
import { zLarge, zSmall } from './CustomTileset'

export type TileGeojsonRecord = Omit<GeoJsonRecord, 'properties'> & { properties: { tileIdx: TileIndex } }
function isTileRecord(item: Omit<GeoJsonRecord, 'properties'> & { properties: any }): item is TileGeojsonRecord {
  return item.properties?.tileIdx
}

export function getTileGeojson(tile: TileLoadProps) {
  const { east, west, north, south } = tile.bbox as GeoBoundingBox

  return {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [west, north],
          [east, north],
          [east, south],
          [west, south],
          [west, north]
        ]
      ]
    },
    properties: {
      tileIdx: tile.index
    }
  }
}

export const DeckDebugger = {
  enabled: () => false,
  debugLineWidth(item: GeoJsonRecord | TileGeojsonRecord) {
    if (isTileRecord(item)) {
      return item.properties.tileIdx.z === zLarge ? 300 : 20
    }

    return 1
  },
  getLineColor(item: GeoJsonRecord | TileGeojsonRecord): [number, number, number, number] {
    if (isTileRecord(item)) {
      return item.properties.tileIdx.z === zSmall ? [255, 0, 0, 255] : [0, 0, 255, 255]
    }
    return [255, 255, 255, 255 / 2]
  }
}
