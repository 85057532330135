import { StateCreator } from 'zustand'
import { BestPlansState, Plan } from '../services/planService/Plan'

export const bestPlanInitialState: BestPlansState = {
  compareSelection: new Set(),
  selectedPlanResult: undefined
}

export type BestPlansActions = {
  toggleComparison: (plan: Plan, isComparing: boolean) => void
  clearComparisons: () => void
  setSelectedPlanResult: (selectedPlanResult: Plan) => void
}

const createBestPlansSlice: StateCreator<
  BestPlansState & BestPlansActions,
  [],
  [],
  BestPlansState & BestPlansActions
> = (set, get) => ({
  ...bestPlanInitialState,
  setSelectedPlanResult: (selectedPlanResult: Plan) => {
    set({ selectedPlanResult })
  },
  clearComparisons: () => {
    set({ compareSelection: new Set() })
  },
  toggleComparison: (planResult: Plan, isComparing: boolean) => {
    const newCompareSelection = new Set(get().compareSelection)
    if (isComparing) {
      newCompareSelection.add(planResult.planId)
    } else {
      newCompareSelection.delete(planResult.planId)
    }
    set({
      compareSelection: newCompareSelection
    })
  }
})

export default createBestPlansSlice
