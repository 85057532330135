import { css, cx } from '@emotion/css'
import { Separator } from './Separator/Separator'
import { IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, IonSearchbar, IonSpinner } from '@ionic/react'
import * as React from 'react'
import { useBoundStore } from '../store'
import { getBoundsFromAddresses, getAddressFromPlaceId } from '../utils/Address'
import { Address } from '../store/address'
import Content from './Content'
import { useRecentAddressSearch } from '../utils/hooks/useRecentAddressSearch'
import { useQuery } from 'react-query'
import shallow from 'zustand/shallow'
import useAddressPredictions from '../utils/hooks/usePredictions'

export const CustomSearchbarStyles = css`
  --border-radius: 10px !important;
  --background: var(--ion-color-primary-contrast);
  --color: var(--ion-color-dark);
  --placeholder-color: var(--ion-placeholder-color);
  --icon-color: var(--ion-placeholder-color);
  --clear-button-color: var(--ion-placeholder-color);
  --box-shadow: none;

  padding: 0;

  & > div.searchbar-input-container.sc-ion-searchbar-ios {
    height: 100%;
  }

  & > div input:focus {
    border: solid 2px var(--ion-color-primary);
  }

  & > div > input.sc-ion-searchbar-ios {
    padding-left: 38px !important;
  }

  & > div.sc-ion-searchbar-ios > .searchbar-search-icon {
    margin-left: 8px !important;
  }

  & > div > .searchbar-search-icon {
    width: 18px;
    opacity: 0.5;
    top: 50%;
    transform: translateY(-50%);
  }

  &:not(.ios) .searchbar-cancel-button {
    width: 18px;
    left: 16px !important;
    top: 50%;
    transform: translateY(-50%);
  }
`
const AutocompleteContentStyles = css`
  --background: var(--ion-item-background);
`

const AutocompleteSearchbarStyles = css`
  height: 36px;
  min-height: 36px;
  padding: 0 24px;
`

const getGeocodeResultFromPlaceId = async (placeIds: string[]) => {
  const geocoder = new google.maps.Geocoder()
  const searches = await Promise.all(
    placeIds.map(async (placeId) => {
      const { results } = await geocoder.geocode({ placeId })
      return results.length > 0 ? results[0] : null
    })
  )
  return searches.filter((result) => result !== null) as google.maps.GeocoderResult[]
}

interface AutocompleteProps {
  ref?: React.Ref<HTMLIonSearchbarElement>
  addresses: Address[]
  onAddressSelected: (newAddress: Address) => void
  onClose: () => void
}

/**
 * Autocomplete modal that renders a list of Addresses
 * @returns Autocomplete component
 */
const Autocomplete = React.forwardRef((props: AutocompleteProps, ref: React.Ref<HTMLIonSearchbarElement>) => {
  const [searchTerm, setSearchTerm] = React.useState<string>('')

  const { predictions } = useAddressPredictions({ value: searchTerm, bounds: getBoundsFromAddresses(props.addresses) })

  const cancelSearch = () => {
    clearSearchTerm()
    props.onClose()
  }
  const clearSearchTerm = () => {
    setSearchTerm('')
  }

  const { addRecentAddressSearch } = useRecentAddressSearch()
  const [searchedAddressHistory] = useBoundStore((state) => [state.searchedAddressHistory], shallow)

  const { data: RecentSearches = [] } = useQuery(
    ['recentSearches', searchedAddressHistory],
    () => getGeocodeResultFromPlaceId(searchedAddressHistory),
    { enabled: searchedAddressHistory.length > 0 }
  )

  const onChange = (e: React.SyntheticEvent<HTMLIonSearchbarElement>) => {
    setSearchTerm((e.target as HTMLInputElement).value)
  }

  const onClick = async (item: { place_id: string }) => {
    addRecentAddressSearch(item.place_id)
    cancelSearch()
    const address = await getAddressFromPlaceId(item.place_id)
    if (address) {
      props.onAddressSelected(address)
    }
  }

  const isDisabled = (result: { place_id: string }) => {
    return props.addresses.map((addr) => addr.placeId).includes(result.place_id)
  }

  return (
    <Content className={AutocompleteContentStyles}>
      <IonSearchbar
        ref={ref}
        placeholder='Add an address'
        onInput={onChange}
        onIonCancel={cancelSearch}
        onIonClear={clearSearchTerm}
        debounce={300}
        showCancelButton='always'
        showClearButton='never'
        cancelButtonIcon='/assets/icons/arrow-back.svg'
        className={cx(CustomSearchbarStyles, AutocompleteSearchbarStyles, 'custom ion-margin-vertical modal')}
      />
      <Separator color='var(--ion-color-medium)' />
      {predictions.isLoading ? (
        <IonGrid className='preloader'>
          <IonRow className='ion-justify-content-center'>
            <IonCol className='ion-justify-content-center ion-align-items-center'>
              <IonSpinner className='spinner-loading-search' />
            </IonCol>
          </IonRow>
        </IonGrid>
      ) : null}
      {!predictions.isLoading &&
        predictions.data &&
        predictions.data.length > 0 &&
        predictions.data.map((result, i) => (
          <IonItem
            key={i}
            button
            detail={false}
            onClick={() => onClick(result)}
            disabled={isDisabled(result)}
            lines='none'
            className='search-result-item'
          >
            {searchedAddressHistory.includes(result.place_id) ? (
              <IonIcon src='/assets/icons/time.svg' slot='start' />
            ) : (
              <IonIcon src='/assets/icons/marker.svg' slot='start' color='#000' />
            )}
            <IonLabel>
              <h3>{result.structured_formatting.main_text}</h3>
              <p>{result.structured_formatting.secondary_text}</p>
            </IonLabel>
          </IonItem>
        ))}
      {!searchTerm &&
        RecentSearches.map((result, i) => (
          <IonItem
            key={i}
            button
            detail={false}
            onClick={() => onClick(result)}
            disabled={isDisabled(result)}
            lines='none'
            className='search-result-item'
          >
            <IonIcon src='/assets/icons/time.svg' slot='start'></IonIcon>
            <IonLabel>
              {/* street_number and route */}
              <h3>{`${result.address_components[0]?.long_name} ${result.address_components[1]?.long_name}`}</h3>
              {/* locality, administrative_area_level_1, country */}
              <p>
                {`${result.address_components[2]?.long_name}, ${result.address_components[5]?.long_name}, ${result.address_components[6]?.short_name}`}
              </p>
            </IonLabel>
          </IonItem>
        ))}
      {searchTerm && !predictions.isLoading && !predictions.data?.length ? (
        <p className='ion-padding'>No results</p>
      ) : null}
    </Content>
  )
})

export default Autocomplete
