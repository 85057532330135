import { css, cx } from '@emotion/css'
import { IonIcon } from '@ionic/react'
import { caretDown } from 'ionicons/icons'

export const ControlChipStyles = css`
  cursor: pointer;
  height: 34px;
  font-size: 14px;
  color: var(--ion-text-color) !important;
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #eff6e7;
  border-radius: 10px;
  padding: 5px 8px 5px 12px;
  white-space: nowrap;

  ion-select {
    padding-inline-start: 0;

    &::part(icon) {
      position: relative;
      right: 0;
      opacity: 0.6;
    }

    &::part(text) {
      justify-content: flex-start;
    }
  }

  ion-icon {
    opacity: 0.6;
    font-size: 20px;
  }
`

export const customIconStyles = css`
  height: 18px;
`

interface ControlChipProps {
  icon: string | JSX.Element
  text: string
  title?: string
  endIcon?: string | JSX.Element
  onClick?: () => void
  className?: string
}

function ControlChip(props: ControlChipProps) {
  return (
    <button title={props.title} className={cx(ControlChipStyles, props.className)} onClick={props.onClick}>
      {typeof props.icon === 'string' ? (
        <IonIcon icon={props.icon} />
      ) : (
        <div className={customIconStyles}>{props.icon}</div>
      )}
      {props.text}
      {props.endIcon ? (
        typeof props.endIcon === 'string' ? (
          <IonIcon icon={props.endIcon} />
        ) : (
          props.endIcon
        )
      ) : (
        <IonIcon style={{ fontSize: '12px' }} icon={caretDown} />
      )}
    </button>
  )
}

export default ControlChip
