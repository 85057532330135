import { IonFooter, IonToolbar } from '@ionic/react'
import React from 'react'
import { WidthLimiterStyles } from '../../../../../components/WidthLimiter/WidthLimiter'
import BaseChatInput from './BaseChatInput'
import { FooterStyles } from './ChatInput.styles'

type ChatFooterProps = {
  onFocus?: ChatInputProps['onFocus']
  onBlur?: ChatInputProps['onBlur']
  footerSlot?: React.ReactNode
  children: React.ReactNode
}
export function ChatFooter(props: ChatFooterProps) {
  return (
    <IonFooter onFocus={props.onFocus} onBlur={props.onBlur} className={FooterStyles}>
      {props.footerSlot}
      <IonToolbar className={WidthLimiterStyles}>{props.children}</IonToolbar>
    </IonFooter>
  )
}

export interface ChatInputProps {
  autoCompleteAddress?: boolean
  onSend: (message: string) => void
  state: 'sending' | 'idle' | 'finished'
  disabled?: boolean
  initialText?: string
  placeholder?: string
  footerSlot?: React.ReactNode
  onFocus?: React.FocusEventHandler<HTMLIonFooterElement>
  onBlur?: React.FocusEventHandler<HTMLIonFooterElement>
  value: string
  setValue: (value: string) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}
function ChatInput(props: ChatInputProps, ref: React.Ref<{ focus: () => void }>) {
  const textInput = React.useRef<HTMLInputElement>(null)

  React.useImperativeHandle(ref, () => ({
    focus: () => {
      textInput.current?.focus()
    }
  }))

  const handleSend = React.useCallback(() => {
    if (props.state === 'sending' || props.value.trim() === '') {
      return
    }

    props.onSend((textInput.current as any)?.getValue() ?? '')
    props.setValue('')
  }, [props])

  const { disabled, onKeyDown: onKeyDownProp } = props
  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      onKeyDownProp?.(e)
      if (e.key === 'Enter' && !disabled) {
        handleSend()
      }
    },
    [handleSend, disabled, onKeyDownProp]
  )

  return (
    <ChatFooter footerSlot={props.footerSlot} onFocus={props.onFocus} onBlur={props.onBlur}>
      {props.state === 'finished' ? null : (
        <BaseChatInput
          ref={textInput}
          disabled={props.disabled}
          value={props.value}
          setValue={props.setValue}
          onKeyDown={onKeyDown}
          placeholder={props.placeholder}
          state={props.state}
          onSend={handleSend}
        />
      )}
    </ChatFooter>
  )
}

export default React.forwardRef(ChatInput)
