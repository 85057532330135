import * as React from 'react'
import { IonButton, IonCol, IonFooter, IonIcon, IonRow, IonText, isPlatform } from '@ionic/react'
import Content from '../../components/Content'
import { cx } from '@emotion/css'
import { SummaryPageFooterStyle, SummaryPageStyles } from './SummaryPageStyles'
import { FavoriteButton, SummaryHeader } from '../../components/Header/SummaryHeader'
import { EllipseBackgroundLong } from '../../components/EllipseBackground/EllipseBackgroundLong'
import PlanResultCard from '../../components/PlanResult/PlanResultCard'
import { useBoundStore } from '../../store'
import GoToCarrierModal from '../../components/GoToCarrierModal'
import useAuthStore from '../../store/auth'
import { EllipseBackgroundDesktop } from '../../components/EllipseBackground/EllipseBackgroundDesktop'
import { AppPromotionCard } from '../home/AppPromotionCard.tsx/AppPromotionCard'
import { arrowBack } from 'ionicons/icons'
import CloseButton from '../../components/CloseButton/CloseButton'
import { MapCardSwitch } from '../home/JustForYou/JustForYou'
import { trackAppsFlyerEvent } from '../../plugin/AppsFlyerPlugin'
import { trackAmplitudeEvent } from '../../utils/amplitude'
import { DesktopHeader } from '../home/DesktopHeader/DesktopHeader'
import { HideMobileStyles } from '../../utils/styles/ViewportUtils.styles'
import { useHistory, useParams } from 'react-router'
import { UsePlan } from '../../services/planService/Plan.service'
import { MainNavigationPaths } from '../../paths'

export type SummaryPageDismissActions = 'redirect-to-profile'

const SummaryPage = () => {
  const history = useHistory()
  const currentUser = useAuthStore((state) => state.currentUser)
  const [favoritePlanIds, addFavoritePlanId, removeFavoritePlanId] = useBoundStore((state) => [
    state.favoritePlanIds,
    state.addFavoritePlanId,
    state.removeFavoritePlanId
  ])
  const carrierId = useParams<{ carrierId: string }>().carrierId
  const planId = useParams<{ planId: string }>().planId
  const selectedPlan = UsePlan.usePlan(carrierId, planId).data
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const isFavorite = favoritePlanIds.some((id) => id === selectedPlan?.planId)
  const isMobile = isPlatform('ios') || isPlatform('android')
  const hasPlanLink =
    selectedPlan?.planLink && selectedPlan.planLink.length > 0 && selectedPlan.planLink.startsWith('http')

  const fromPlanResults = Boolean(window.history.state?.state?.fromPlanResults)

  const goBack = () => {
    if (history.location.key !== undefined && history.location.key !== 'default') {
      history.goBack()
    } else {
      history.push(MainNavigationPaths.plans)
    }
  }

  React.useEffect(() => {
    if (!selectedPlan) {
      return
    }
    trackAppsFlyerEvent({
      eventName: 'af_plan_details_viewed',
      eventValue: { planId: selectedPlan?.planId, planName: selectedPlan.planName, planLink: selectedPlan.planLink }
    })
  }, [selectedPlan])

  const onGoToCarrierButtonClick = () => {
    setIsModalOpen(true)
  }

  const redirectToPlanLink = (shouldRedirect: boolean) => {
    setIsModalOpen(false)
    if (shouldRedirect && hasPlanLink) {
      trackAppsFlyerEvent({ eventName: 'af_carrier_site_redirect' })
      trackAmplitudeEvent('Carrier page redirect', {
        planId: selectedPlan?.planId,
        planName: selectedPlan?.planName,
        carrierId: selectedPlan?.carrierId,
        carrierName: selectedPlan?.carrierName
      })

      // giving some time to appsflyer to track
      window.setTimeout(() => {
        window.open(selectedPlan.affiliateLink ?? selectedPlan.planLink, '_blank')
      }, 300)
    }
  }

  const onFavoriteClick = () => {
    if (!currentUser) {
      goBack()
    }
    selectedPlan?.planId && (isFavorite ? removeFavoritePlanId : addFavoritePlanId)(selectedPlan.planId)
  }

  return (
    <>
      <DesktopHeader className={HideMobileStyles} />
      {isMobile ? (
        <SummaryHeader isFavorite={isFavorite} onFavoriteClick={onFavoriteClick} onBackClick={goBack} />
      ) : null}
      <Content className={cx(SummaryPageStyles, 'ion-no-padding')} style={{ overflowX: 'hidden' }}>
        {isMobile ? <EllipseBackgroundLong className='elipse-background' /> : <EllipseBackgroundDesktop />}
        {isMobile ? null : (
          <IonRow className='desktop-subheader ion-align-items-center ion-justify-content-between'>
            {fromPlanResults ? (
              <div className='back-button' onClick={goBack}>
                <IonIcon color='dark' size='small' src={arrowBack} />
                <IonText>Back to plan results</IonText>
              </div>
            ) : (
              <IonText color='dark'>Plan Summary</IonText>
            )}
            <div className='button-right-group'>
              <FavoriteButton isFavorite={isFavorite} onClick={onFavoriteClick} />
              {fromPlanResults ? null : <CloseButton onClose={goBack} />}
            </div>
          </IonRow>
        )}
        {selectedPlan ? (
          <IonRow className={'selected-plan ion-justify-content-center ion-padding' + (isMobile ? '' : '-vertical')}>
            <IonCol>
              <PlanResultCard plan={selectedPlan} isSummary alwaysExpanded />
            </IonCol>
          </IonRow>
        ) : null}

        {!isMobile ? (
          <>
            <IonRow className={'ion-justify-content-center ion-padding' + (isMobile ? '' : '-vertical')}>
              <IonCol>
                <MapCardSwitch />
              </IonCol>
            </IonRow>
            <IonRow className={'ion-justify-content-center ion-padding' + (isMobile ? '' : '-vertical')}>
              <IonCol>
                <AppPromotionCard />
              </IonCol>
            </IonRow>
          </>
        ) : null}
      </Content>
      {hasPlanLink ? (
        <IonFooter className={SummaryPageFooterStyle}>
          <div className='plan-price'>
            <span className='description'>Estimated monthly cost for {selectedPlan?.numLines} lines</span>
            <div className='price-block'>
              <div className='price'>
                <strong>${selectedPlan?.price}</strong>
                /mo.
              </div>
              <div className='taxes'>plus taxes and fees</div>
            </div>
          </div>
          <IonButton
            size='large'
            shape='round'
            expand='block'
            className='purchase-button'
            onClick={onGoToCarrierButtonClick}
          >
            Purchase on carrier site
          </IonButton>

          <div className='hint'>You will be redirected to the carrier's website to complete your plan purchase</div>
        </IonFooter>
      ) : null}
      <GoToCarrierModal isOpen={isModalOpen} onClose={redirectToPlanLink} />
    </>
  )
}

export default SummaryPage
