import { css } from '@emotion/css'

export const MeasurementsStyles = css`
  .lower-measurement .measurement-value {
    font-size: 20px;
  }

  .upper-row,
  .lower-row {
    display: grid;
    gap: 8px;
  }
  .upper-row {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .lower-row {
    padding-top: 8px;
    grid-template-columns: 1fr 1fr;
  }
`
