import { css } from '@emotion/css'

export const SummaryPageStyles = css`
  ion-content .custom-width-limiter {
    overflow-x: hidden;
    position: relative;
    height: 100%;
  }

  .selected-plan {
    margin: 0 10px auto;
  }

  .footer {
    background-color: var(--ion-background-color);
    padding: 12px 26px;

    .purchase-button {
      font-weight: 500;
      font-size: 19px;
      text-transform: uppercase;
      letter-spacing: 0px;
    }

    .hint {
      text-align: center;
      margin-top: 16px;
      color: gray;
    }
  }

  @media screen and (min-width: 768px) {
    .footer {
      text-align: center;
      padding: 12px 16%;
      .purchase-button {
        max-width: 692px;
        margin: auto;
      }
    }
    .custom-width-limiter > *:not(.ellipse-desktop):not(.footer) {
      max-width: 692px;
      margin-left: auto;
      margin-right: auto;
    }
    .desktop-subheader {
      padding-top: 27px;
      margin-bottom: 4px;
      font-weight: 600;
      .back-button {
        cursor: pointer;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
    .button-right-group {
      display: flex;
      gap: 12px;
      .close-button {
        width: 18px;
      }
    }
  }
`

export const SummaryPageFooterStyle = css`
  background-color: var(--ion-background-color);
  padding: 12px 26px;

  .plan-price {
    max-width: 692px;
    width: 100%;
    margin: auto;
    margin-bottom: 16px;
    color: var(--ion-color-dark);
    display: flex;
    gap: 26px;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;

    .description {
      font-weight: 700;
    }

    .price-block {
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      justify-content: end;
      text-align: right;

      .price {
        strong {
          font-size: 18px;
          font-weight: 900;
        }
        font-weight: 700;
      }

      .taxes {
        font-size: 12px;
        font-weight: 500;
        color: var(--ion-color-medium);
      }
    }
  }

  .purchase-button {
    font-weight: 500;
    font-size: 19px;
    text-transform: uppercase;
    letter-spacing: 0px;
    max-width: 692px;
  }

  .hint {
    font-size: 14px;
    text-align: center;
    margin-top: 16px;
    color: gray;
  }
  @media screen and (min-width: 768px) {
    text-align: center;
    padding: 12px 16%;
    .purchase-button {
      max-width: 692px;
      margin: auto;
    }
  }
`
