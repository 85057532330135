import React from 'react'
import { BotToolMessage } from '../types/ChatStep'
import { ToolComponents, ToolName } from './const/tool.const'

export type ToolError = {
  error: unknown
}
export interface Tool<T extends React.ComponentType<any>> {
  toolName: ToolName
  ToolComponent: T
  toProps: () => Promise<React.ComponentProps<T> | ToolError>
}

export function ToolComponent(props: { message: BotToolMessage }) {
  const Comp = ToolComponents[props.message.toolComponent]

  if (!Comp) {
    return null
  }

  return <Comp backendInput={props.message.backendInput as any} />
}
