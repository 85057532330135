import { cx } from '@emotion/css'
import { MeasurementStyles } from './MeasurementStyles'
import { IonIcon } from '@ionic/react'
import InfoDialog from '../../../InfoDialog'
import React from 'react'

export const Measurement: React.FC<{
  title: string
  icon?: string
  iconSrc?: string
  children?: React.ReactNode
  suffix?: React.ReactNode
  className?: string
  isLatency?: boolean
}> = (props) => {
  const [isLatencyInfoOpen, setIsLatencyInfoOpen] = React.useState(false)

  return (
    <div className={cx(MeasurementStyles, props.className)}>
      <div
        className={cx('measurement-title', !!props.isLatency && 'isLatency')}
        onClick={() => setIsLatencyInfoOpen(true)}
      >
        {props.icon && <IonIcon icon={props.icon} />}
        {props.iconSrc && <IonIcon src={props.iconSrc} />}
        {props.title}
      </div>
      <div className={cx('measurement-value', props.suffix ? 'suffixed' : '')}>{props.children}</div>
      <div className='suffix'>{props.suffix}</div>
      {!!props.isLatency && (
        <InfoDialog
          isOpen={isLatencyInfoOpen}
          onClose={() => setIsLatencyInfoOpen(false)}
          title='What is Latency?'
          content={
            <>
              Latency is the delay between sending and receiving data in a network.
              <br />
              <br />
              High latency can lead to noticeable delays in video streaming or even loading web pages. Generally, the
              lower the latency, the better the experience.
            </>
          }
        />
      )}
    </div>
  )
}
