import { css, cx } from '@emotion/css'
import * as React from 'react'
import { useBoundStore } from '../../../store'
import { getBoundsFromAddresses } from '../../../utils/Address'
import { Address } from '../../../store/address'
import MapComponent from '../../Map/Map'
import Marker from '../../Marker'
import { MinimapEventController } from './MinimapEventController'

const rectangleClass = (ratio: number) => {
  return css`
    pointer-events: none;
    background-color: #0066ff;
    width: ${ratio}%;
    height: ${ratio}%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 2;
    opacity: 0.4;
    border-radius: 3px;
  `
}
const minRatio = 0.2
const Rectangle: React.FC<{ parentMap: google.maps.Map; minimap: google.maps.Map }> = ({ parentMap, minimap }) => {
  const [ratio, setRatio] = React.useState(minRatio)

  React.useEffect(() => {
    const listener = parentMap.addListener('bounds_changed', () => {
      const minimapBounds = minimap.getBounds()
      const parentMapBounds = parentMap.getBounds()

      if (!minimapBounds || !parentMapBounds) {
        return
      }
      const parentSize = google.maps.geometry.spherical.computeDistanceBetween(
        parentMapBounds.getNorthEast(),
        parentMapBounds.getSouthWest()
      )
      const minimapSize = google.maps.geometry.spherical.computeDistanceBetween(
        minimapBounds.getNorthEast(),
        minimapBounds.getSouthWest()
      )

      setRatio(parentSize / minimapSize)
    })

    return () => {
      listener.remove()
    }
  }, [parentMap, minimap])

  return <div className={rectangleClass(Math.max(ratio, minRatio) * 100)}></div>
}

const mapSize = 120
const MinimapStyles = css`
  &.hide {
    visibility: hidden;
  }
  margin-bottom: 16px;
  margin-left: 16px;
  position: relative;
  background-color: var(--ion-background-color);
  width: ${mapSize}px;
  height: ${mapSize}px;
  min-width: ${mapSize}px;
  border-radius: 8px;
  padding: 1px;
  overflow: hidden;

  #map {
    border-radius: 8px;
  }
`
const Minimap: React.FC<{ map: google.maps.Map; onClickAddress: (address: Address) => void; className?: string }> = (
  props
) => {
  const [minimap, setMinimap] = React.useState<google.maps.Map>()
  const eventControllerRef = React.useRef<MinimapEventController>()
  const [hideMinimap, setHideMap] = React.useState(false)
  const [addresses, selectedAddress] = useBoundStore((state) => [state.addresses, state.selectedAddress])

  React.useEffect(() => {
    if (!addresses.length) {
      return
    }
    eventControllerRef.current?.setAddresses(addresses)
    minimap && eventControllerRef.current?.syncMap(props.map, minimap)

    const bounds = getBoundsFromAddresses(addresses)
    minimap?.fitBounds(bounds)
  }, [addresses, props.map, minimap])

  if (!addresses.length) {
    return <div />
  }

  return (
    <div className={cx(MinimapStyles, hideMinimap ? 'hide' : '', props.className)}>
      {minimap ? <Rectangle minimap={minimap} parentMap={props.map} /> : null}
      <MapComponent
        zoomControl={false}
        zoom={2}
        maxZoom={4}
        customizeMap={(map) => {
          const minimapEventController = (eventControllerRef.current = MinimapEventController.replaceInstance({
            map,
            parentMap: props.map,
            addresses,
            onChangeVisibility: (visible) => {
              setHideMap(!visible)
            }
          }))

          minimapEventController.listen()
          setMinimap(map)
        }}
      >
        {addresses.map((address) => (
          <Marker
            icon='/assets/icons/ellipsis-marker.svg'
            key={address.placeId}
            onClick={() => props.onClickAddress(address)}
            address={address}
            active={selectedAddress?.placeId === address.placeId}
          />
        ))}
      </MapComponent>
    </div>
  )
}
export default Minimap
