import { Status, Wrapper } from '@googlemaps/react-wrapper'
import shallow from 'zustand/shallow'
import { useBoundStore } from '../../store'
import { LoaderComponent } from './LoaderComponent'

const API_KEY = process.env.REACT_APP_MAPS_API_KEY

const render = (status: Status): any => {
  if (status === Status.LOADING) return <LoaderComponent />
  if (status === Status.FAILURE) return <h3>{status} ...</h3>
  return null
}

export function MapsWrapper(props: { children?: React.ReactNode | React.ReactNode[] }) {
  const [libraries] = useBoundStore((state) => [state.map.libraries], shallow)
  return (
    // TODO: figure out how to import LatLng without needing wrapper re-renders
    <Wrapper apiKey={API_KEY ?? ''} render={render} libraries={libraries}>
      {props.children}
    </Wrapper>
  )
}
