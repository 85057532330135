import { Logger } from './Logger'

type Listener = (...args: any[]) => void

export class EventEmitter {
  private _events: { [key: string]: Listener[] } = {}

  public on(event: string, listener: Listener): void {
    if (!this._events[event]) {
      this._events[event] = []
    }

    this._events[event].push(listener)
  }

  public emit(event: string, ...args: any[]): void {
    if (!this._events[event]) {
      Logger.debug(`No listeners registered for event: "${event}"`)
      return
    }

    for (const listener of this._events[event]) {
      listener(...args)
    }
  }

  public off(event: string, listener: Listener): void {
    if (!this._events[event]) {
      throw new Error(`No listeners registered for event: "${event}"`)
    }

    this._events[event] = this._events[event].filter((l) => l !== listener)

    if (this._events[event].length === 0) {
      delete this._events[event]
    }
  }
}
