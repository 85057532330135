import { RecentSearchService } from '../../services/RecentSearch.service'
import { useBoundStore } from '../../store'

export const useRecentAddressSearch = () => {
  const setRecentAddressSearch = useBoundStore((state) => state.setSearchedAddressHistory)

  const addRecentAddressSearch = async (placeId: string) => {
    const newSearchHistory = await RecentSearchService.addRecentAddressSearch(placeId)
    if (!Array.isArray(newSearchHistory)) return
    setRecentAddressSearch(newSearchHistory)
  }

  return { addRecentAddressSearch }
}
