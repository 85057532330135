import { css } from '@emotion/css'

export const GojiInputStyles = css`
  width: 100%;
  outline: none;
  padding: 17.6px 16px;
  background-color: var(--ion-background-color);
  border: 1px solid var(--goji-primary-black);
  height: 54px;
  &:focus {
    border-color: var(--ion-color-primary);
  }
  border-radius: 42px;

  text-align: left;
`
