import * as React from 'react'
import { Carrier } from '../../store/carrierSelector'
import { css, cx } from '@emotion/css'

const carrierDisplayStyles = css`
  display: flex;
`

export const smallImageStyles = css`
  height: 38px;
  min-height: 38px;
`

interface CarrierDisplayProps {
  carrier: Pick<Carrier, 'logo_url' | 'name'>
  className?: string
  smaller?: boolean
}
const CarrierDisplay: React.FC<CarrierDisplayProps> = (props) => {
  return (
    <div className={cx(carrierDisplayStyles, props.className)}>
      {props.carrier.logo_url ? (
        <img loading='lazy' className={smallImageStyles} src={props.carrier.logo_url} alt={props.carrier.name} />
      ) : (
        props.carrier.name
      )}
    </div>
  )
}

export default CarrierDisplay
