import { Position } from '@capacitor/geolocation'
import { isPlatform } from '@ionic/react'
import React from 'react'
import shallow from 'zustand/shallow'
import { UseCarrier } from '../../../services/Carrier.service'
import { NetworkTypeValue } from '../../../services/NetworkType.service'
import { useGeneralStore } from '../../../store'
import { Carrier } from '../../../store/carrierSelector'
import useNavigationStore from '../../../store/navigation'
import { getBoundsFromAddresses, getAddressFromGeolocation } from '../../../utils/Address'
import { DeckManager } from '../DeckManager'
import { trackAmplitudeEvent } from '../../../utils/amplitude'
import { MinimapEventController, useMinimapStore } from '../BottomTray/MinimapEventController'
import { Address } from '../../../store/address'

export const useOnSelectedAddressChange = (deckManagerRef: React.MutableRefObject<DeckManager | undefined>) => {
  const [selectedAddress] = useGeneralStore((state) => [state.selectedAddress])
  const [setOwner] = useMinimapStore((state) => [state.setOwner])

  const deckManager = deckManagerRef.current
  React.useEffect(() => {
    if (deckManager?.map && selectedAddress) {
      setOwner(MinimapEventController.parentMapId)
      deckManager.map.setZoom(12)
      deckManager.map.panTo(new google.maps.LatLng(selectedAddress.latitude, selectedAddress.longitude))
    }
  }, [selectedAddress, deckManager, setOwner])
}

const useCenterAddr = () => {
  const [addresses, currentAddress] = useGeneralStore((state) => [state.addresses, state.currentAddress])
  const userAddress = currentAddress ? addresses.find((addr) => addr.placeId === currentAddress.placeId) : undefined

  if (userAddress) {
    return userAddress
  }

  return addresses[0]
}

const useCenterAtFirstLoad = () => {
  const [addresses, selectedAddress, setSelectedAddress] = useGeneralStore(
    (state) => [state.addresses, state.selectedAddress, state.setSelectedAddress],
    shallow
  )

  const centerAddr = useCenterAddr()

  React.useEffect(() => {
    if (!selectedAddress || !addresses.map((addr) => addr.placeId).includes(selectedAddress.placeId)) {
      setSelectedAddress(centerAddr)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

function useTrackMapInteraction() {
  const hasUserInteractedWithMap = React.useRef(false)

  const trackMapInteraction = React.useCallback(() => {
    if (!hasUserInteractedWithMap.current) {
      trackAmplitudeEvent('Network Map Interaction')
      hasUserInteractedWithMap.current = true
    }
  }, [])

  return trackMapInteraction
}

const hasBeenWelcomedStorageValue = localStorage.getItem('hasBeenWelcomed')
export const useCoverageMapLogic = () => {
  const deckManagerRef = React.useRef<DeckManager>()
  const [googleMap, setGoogleMap] = React.useState<google.maps.Map>()
  const isMobile = isPlatform('ios') || isPlatform('android')

  const hasBeenWelcomed = React.useRef(hasBeenWelcomedStorageValue)

  const [isWelcomeModalOpen, setWelcomeModalOpen] = React.useState(!isMobile && !hasBeenWelcomed)

  const carriers = UseCarrier.useCarriersSplit(UseCarrier.useAvailableInMyRegion(UseCarrier.useCarriersWithRecords()))

  const [selectedCarrierState, setSelectedCarrier] = React.useState<Carrier | undefined>()

  const [addresses, networkType, setNetworkType, selectedAddress, setSelectedAddress] = useGeneralStore((state) => [
    state.addresses,
    state.networkType,
    state.setNetworkType,
    state.selectedAddress,
    state.setSelectedAddress
  ])

  const bestCarrier = UseCarrier.useBestCarrierForTechnology({ addresses, technology: networkType })

  const selectedCarrier = selectedCarrierState || bestCarrier || carriers.data?.defaultCarriers[0]

  const selectedCarrierId = selectedCarrier?.id
  const carrierIdOperator =
    UseCarrier.useBestCarrierOperatorId({ carrierId: selectedCarrierId, addresses }) ?? selectedCarrierId

  React.useEffect(() => {
    if (carrierIdOperator) {
      deckManagerRef.current?.setCarrierId(carrierIdOperator ?? '')
      deckManagerRef.current?.updateData()
    }
  }, [carrierIdOperator])

  const [loading, setLoading] = React.useState(false)
  const [appExperience] = useNavigationStore((state) => [state.appExperience], shallow)

  useCenterAtFirstLoad()

  const bounds = React.useMemo(() => {
    return getBoundsFromAddresses(addresses)
  }, [addresses])

  const onSwitchNetworkType = React.useCallback(
    (newTechnology: NetworkTypeValue) => {
      setNetworkType(newTechnology)
      deckManagerRef.current?.setNetworkType(newTechnology)
      deckManagerRef.current?.updateData()
    },
    [setNetworkType]
  )

  const focusMarker = React.useCallback(
    (address: Address) => {
      const isAlreadySelected = address.placeId === selectedAddress?.placeId
      setSelectedAddress(address)

      if (isAlreadySelected) {
        deckManagerRef.current?.map.panTo(new google.maps.LatLng(selectedAddress.latitude, selectedAddress.longitude))
      }
    },
    [selectedAddress, setSelectedAddress]
  )

  const onCurrentPositionAdded = React.useCallback(
    async (position: Position) => {
      if (!position) {
        return
      }

      const address = await getAddressFromGeolocation(position)
      if (!address) {
        return
      }

      setSelectedAddress(address)
    },
    [setSelectedAddress]
  )

  const onCloseWelcomeModal = React.useCallback(() => {
    setWelcomeModalOpen(false)
    hasBeenWelcomed.current = 'true'
    localStorage.setItem('hasBeenWelcomed', hasBeenWelcomed.current)
  }, [])

  const trackMapInteraction = useTrackMapInteraction()
  return {
    deckManagerRef,
    googleMap,
    setGoogleMap,
    selectedCarrier,
    setSelectedCarrier,
    loading,
    setLoading,
    appExperience,
    bounds,
    onSwitchNetworkType,
    focusMarker,
    onCurrentPositionAdded,
    onCloseWelcomeModal,
    trackMapInteraction,
    isWelcomeModalOpen
  }
}
