export const IconPerks = ({ color, className }: { color?: string; className?: string }) => {
  return (
    <div className={className}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M14 23.5L7 21.55V23H1V12H8.95L17 15V17H22V21L14 23.5ZM3 21H5V14H3V21ZM13.95 21.4L19.9 19.55V19H12.825L9.7 17.95L10.3 16.05L13.225 17H15V16.35L8.6 14H7V19.5L13.95 21.4Z'
          fill={color ?? 'rgba(0, 0, 0, 0.6)'}
        />
        <path
          d='M15.11 9.5829L17 8.48289L18.89 9.59737L18.395 7.51316L20.06 6.12368L17.87 5.93553L17 3.96711L16.13 5.92105L13.94 6.10921L15.605 7.51316L15.11 9.5829ZM13.295 12L14.27 7.93289L11 5.19737L15.32 4.83553L17 1L18.68 4.83553L23 5.19737L19.73 7.93289L20.705 12L17 9.84342L13.295 12Z'
          fill={color ?? 'rgba(0, 0, 0, 0.6)'}
        />
        <path
          d='M17.0635 8.37522L17.0004 8.33802L16.9371 8.37486L15.2995 9.32798L15.7266 7.54223L15.7445 7.46728L15.6856 7.4176L14.251 6.20795L16.1407 6.04559L16.2142 6.03928L16.2442 5.9719L16.9996 4.27536L17.7557 5.98606L17.7856 6.05373L17.8593 6.06007L19.7468 6.22224L18.3149 7.41719L18.2555 7.46676L18.2734 7.54204L18.7005 9.34053L17.0635 8.37522ZM13.1734 11.9709L13.1053 12.255L13.3579 12.108L17 9.98805L20.6421 12.108L20.8947 12.255L20.8266 11.9709L19.8696 7.97907L23.0802 5.29324L23.3134 5.09818L23.0104 5.0728L18.7646 4.71718L17.1145 0.949849L17 0.688443L16.8855 0.949849L15.2354 4.71718L10.9896 5.0728L10.6866 5.09818L10.9198 5.29324L14.1304 7.97907L13.1734 11.9709Z'
          stroke={color ?? 'rgba(0, 0, 0, 0.6)'}
          strokeWidth='0.25'
        />
      </svg>
    </div>
  )
}
