import { useQuery } from 'react-query'
import M2CatalystSdkPlugin from '../../plugin/M2CatalystSdkPlugin'

export const locationKey = 'shouldShowRationale'
export function useLocationPermission() {
  const hasLocation = useQuery({
    queryKey: locationKey,
    queryFn: async () => {
      return await M2CatalystSdkPlugin.hasLocationPermissions()
    }
  })

  async function requestLocation() {
    const pluginResult = await M2CatalystSdkPlugin.requestLocationPermissions()
    return pluginResult
  }

  return { hasLocation, requestLocation }
}

export function useRefetchPermissions() {
  const { hasBackgroundLocation } = useBackgroundLocation()
  const { hasLocation } = useLocationPermission()

  async function refetch() {
    hasLocation.refetch()
    hasBackgroundLocation.refetch()
  }

  return refetch
}

export const bgLocationKey = 'hasBackgroundLocation'
export function useBackgroundLocation() {
  const hasBackgroundLocation = useQuery({
    queryKey: bgLocationKey,
    queryFn: async () => {
      return await M2CatalystSdkPlugin.hasBackgroundLocation()
    }
  })

  async function requestBgLocation() {
    const pluginResult = await M2CatalystSdkPlugin.requestBackgroundLocation()
    return pluginResult
  }

  return { hasBackgroundLocation, requestBgLocation }
}
