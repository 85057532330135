import { css } from '@emotion/css'

export const PlanOverlayWrapperStyles = css`
  height: 450px !important;
`

export const TinderPlanCardStyles = css`
  height: 100%;
  transition: transform 0.3s ease-out;
  pointer-events: auto;

  ::after {
    pointer-events: none;
    transition: background 0.3s ease-out;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: 1;
  }
  &:not(.isFirst) {
    transform: scale(0.95) translateY(20px);
    ::after {
      background: rgba(0, 0, 0, 0.2);
    }
  }
`

export const PlanOverlayStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 8px;
  padding: 32px;
  font-size: 26px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  pointer-events: none;

  > span {
    width: 70%;
  }
`

export const PlanOverlayLikeStyles = css`
  ${PlanOverlayStyles}
  background: var(--ion-color-primary);
  align-items: start;
  justify-content: start;
`

export const PlanOverlayPassStyles = css`
  ${PlanOverlayStyles}
  background: #ff7e20;
  align-items: end;
  justify-content: start;

  > span {
    text-align: end;
  }
`

export const PlanOverlayUnsureStyles = css`
  ${PlanOverlayStyles}
  background: var(--goji-color-secondary-blue-1);
  align-items: center;
  justify-content: end;

  > span {
    text-align: center;
  }
`

export const OverlayBlockStyles = css`
  border-radius: 8px;
  width: fit-content;
  padding: 8px 24px;
  border: 3px solid white;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  font-size: 56px;
  font-weight: 900;

  > ion-icon {
    --ionicon-stroke-width: 50px;
    &.barry-unsure {
    transform: scale(1.5);
    margin-right: 8px;
  }
`
