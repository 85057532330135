import { StateCreator } from 'zustand'
import { Plan } from '../services/planService/Plan'
import { trackAmplitudeEvent } from '../utils/amplitude'
import produce from 'immer'

export enum PlanInteraction {
  LIKE = 'like',
  PASS = 'pass',
  UNSURE = 'unsure'
}

export type PlansInteractionState = {
  likedPlans: Set<Plan['planId']>
  passedPlans: Set<Plan['planId']>
  unsurePlans: Set<Plan['planId']>
}

export type PlansInteractionActions = {
  setInteraction: (planId: Plan['planId'], interaction: PlanInteraction) => void
}

export const planInteractionInitialState: PlansInteractionState = {
  likedPlans: new Set(),
  passedPlans: new Set(),
  unsurePlans: new Set()
}

const createPlansInteractionSlice: StateCreator<
  PlansInteractionState & PlansInteractionActions,
  [],
  [],
  PlansInteractionState & PlansInteractionActions
> = (set, get) => {
  return {
    ...planInteractionInitialState,
    setInteraction: (planId, interaction) => {
      trackAmplitudeEvent('plan_interaction', {
        planId,
        interaction
      })
      switch (interaction) {
        case PlanInteraction.LIKE:
          set(
            produce((state) => {
              state.likedPlans.add(planId)
              state.passedPlans.delete(planId)
              state.unsurePlans.delete(planId)
            })
          )
          break
        case PlanInteraction.PASS:
          set(
            produce((state) => {
              state.passedPlans.add(planId)
              state.likedPlans.delete(planId)
              state.unsurePlans.delete(planId)
            })
          )
          break
        case PlanInteraction.UNSURE:
          set(
            produce((state) => {
              state.unsurePlans.add(planId)
              state.likedPlans.delete(planId)
              state.passedPlans.delete(planId)
            })
          )
          break
      }
    }
  }
}
export default createPlansInteractionSlice
