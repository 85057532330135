import { css } from '@emotion/css'
import { MOBILE_BREAKPOINT } from '../hooks/useWindowSize'

export const HideDesktopStyles = css`
  @media screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    display: none !important;
  }
`

export const HideMobileStyles = css`
  @media screen and (max-width: ${MOBILE_BREAKPOINT - 1}px) {
    display: none !important;
  }
`
