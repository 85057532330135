export const defaultCarriers = [
  {
    name: 'AT&T',
    logo_url: '/assets/images/carriers/att.svg'
  },
  {
    name: 'T-Mobile',
    logo_url: '/assets/images/carriers/t-mobile.svg'
  },
  {
    name: 'U.S. Cellular',
    logo_url: '/assets/images/carriers/us-cellular.svg'
  },
  {
    name: 'Verizon',
    logo_url: '/assets/images/carriers/verizon.svg'
  }
]
