import { css, cx } from '@emotion/css'
import useMapTrayOnboard from './useMapOnboardingStore'

const handTutorialStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
`

interface HandTutorialProps {
  className?: string
}

const HandTutorial: React.FC<HandTutorialProps> = ({ className }) => {
  const { hasBeenOnboardedOnMapTray, completeOnboard } = useMapTrayOnboard()

  return (
    <>
      {!hasBeenOnboardedOnMapTray && (
        <div className={cx(handTutorialStyles, className)} onClick={completeOnboard}>
          <img src={'/assets/images/hand_tutorial.svg'} alt={'Hand Tutorial'} />
          <p>Swipe up to view more details</p>
        </div>
      )}
    </>
  )
}

export default HandTutorial
