import { useQuery } from 'react-query'
import { getNetworkTypeId, NetworkTypeValue } from './NetworkType.service'
import { GeoJsonRecord } from '../components/CoverageMap/Geodata.service'

export const LocationService = {
  getGeolocation: (): Promise<GeolocationPosition> => {
    return new Promise((res, rej) => {
      navigator.geolocation.getCurrentPosition(res, rej)
    })
  }
}

export const UseLocation = {
  useGeolocation: () => {
    return useQuery({
      queryKey: 'user-geolocation',
      queryFn: LocationService.getGeolocation
    })
  }
}

export function getAverageNetworkPerformance(
  carrierId: string,
  networkType: NetworkTypeValue,
  carriers: GeoJsonRecord[]
) {
  if (!carriers.length) {
    return 0
  }

  const backendNetworkTypes = getNetworkTypeId(networkType)
  const carrierRecords = carriers.filter(
    (c) => c.properties.carrier_id === carrierId && backendNetworkTypes === c.properties.network_type_id
  )

  if (!carrierRecords.length) {
    return 0
  }

  const avgNetworkPerformance =
    carrierRecords.reduce((acc, item) => {
      return item.properties.network_performance + acc
    }, 0) / carrierRecords.length

  return avgNetworkPerformance
}
