import { css, cx } from '@emotion/css'
import { getConfig, IonIcon, IonText, IonToast } from '@ionic/react'
import {
  bookmark,
  bookmarkOutline,
  briefcase,
  briefcaseOutline,
  checkmarkCircleOutline,
  chevronDown,
  chevronUp,
  close,
  home,
  homeOutline
} from 'ionicons/icons'
import * as React from 'react'
import SwiperType, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import shallow from 'zustand/shallow'
import { NetworkType, NetworkTypeValue } from '../../../services/NetworkType.service'
import { useGeneralStore } from '../../../store'
import { Address, LabelType } from '../../../store/address'
import { useNavigateAway } from '../../../utils/hooks/useNavigateAway'
import { ResetMargin } from '../../../utils/styles/MarginUtils.styles'
import { SignalMeterStrength } from '../../SignalMeter/SignalMeterStrength'
import NetworkPerformanceQueryLabel, { getNetworkTypeValue } from '../../SignalStrengthLabel'
import { SwiperContent } from '../../Swiper/SwiperContent'
import { useAddressLabelDialog } from '../../../pages/network-quality/AddressLabelDialog'
import { useUnsavedAddresses } from '../hooks/useUnsavedAddresses'
import useAuthStore from '../../../store/auth'
import { autoLabelAddress } from '../../../pages/network-quality/autolabel-address'
import { UseCarrier } from '../../../services/Carrier.service'
import CarrierCoverageList from './CarrierCoverageList'
import { boardingGestureStyles, UseBottomTrayGesture } from './useBottomTrayGesture'
import HandTutorial from './HandTutorial'

const AddressLabelStyles = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  font-size: 18px;
`

const NetworkPerformanceLabelStyles = css`
  padding: 12px 0 16px;
  span {
    font-weight: 500;
    font-size: 14px;
    color: var(--ion-color-medium);
  }
`

const LocationDetails: React.FC<{ networkType: NetworkTypeValue; address: Address; carrierId: string }> = (props) => {
  const qualityQuery = UseCarrier.useCarrierWithPerformance({
    carrierId: props.carrierId,
    addresses: [props.address]
  })

  return (
    <>
      <h4 className={cx(ResetMargin, 'ion-margin-bottom', AddressLabelStyles)}>{props.address.addressName}</h4>
      <div className={NetworkPerformanceLabelStyles}>
        <span>{props.networkType === NetworkType['4G'] ? '4G' : '5G'} Network performance: </span>
        <NetworkPerformanceQueryLabel qualityQuery={qualityQuery} networkType={props.networkType} />
      </div>
      <SignalMeterStrength quality={getNetworkTypeValue(props.networkType, qualityQuery.data?.networkPerformance)} />
    </>
  )
}

const useDisableSwipeBack = () => {
  React.useEffect(() => {
    const config = getConfig()
    config?.set('swipeBackEnabled', false)
    return () => {
      config?.set('swipeBackEnabled', true)
    }
  }, [])

  const onNavigateAway = React.useCallback(() => {
    const config = getConfig()
    config?.set('swipeBackEnabled', true)
  }, [])
  useNavigateAway(onNavigateAway)
}

const BottomTrayStyles = css`
  animation: none;
  position: relative;
  z-index: 2;
  background-color: var(--ion-background-color);
  border-radius: 16px 16px 0px 0px;
  border: 2px solid var(--ion-color-dark);
  border-bottom: 0;
  transition: transform 0.3s ease-out;

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .horizontal-spacing {
    padding-left: 24px;
    padding-right: 24px;
  }

  .label {
    font-weight: 600;
    color: var(--ion-color-medium);
    display: flex;
    align-items: center;
    gap: 8px;

    ion-icon {
      cursor: pointer;
      min-height: 24px !important;
      min-width: 24px !important;

      &.edit-icon {
        min-height: 20px;
        min-width: 20px;
        margin-left: 4px;
      }
    }
  }

  .address-details {
    padding-bottom: 16px;
    border-bottom: 2px solid var(--ion-color-light);
    cursor: grab;

    .drag-handle {
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      width: 36px;
      height: 4px;
      background-color: var(--ion-color-medium);
      border-radius: 2px;
    }
  }

  .tray-heading {
    padding-top: 20.5px;
    padding-bottom: 20.5px;
    display: flex;
    justify-content: space-between;

    > ion-icon {
      cursor: pointer;
    }
  }

  .swiper-pagination {
    margin-bottom: -8px;
  }
`

const successSaveToastStyles = css`
  --background: #eff6e7;
  --color: #357e38;

  ::part(header) {
    font-weight: 500;
    font-size: 16px;
  }

  ::part(message) {
    font-weight: 400;
    font-size: 16px;
  }

  ::part(icon) {
    align-self: baseline;
    padding-top: 15px;
    height: 24px;
    width: 24px;
  }
`

const handTutorialStyles = css`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
`

function useAddressIcon(address: Address | undefined) {
  return React.useMemo(() => {
    if (!address) return undefined

    if (!address.localMemory) {
      switch (address.labelType) {
        case LabelType.Home:
          return home
        case LabelType.Work:
          return briefcase
        default:
          return bookmark
      }
    }

    switch (address.labelType) {
      case LabelType.Home:
        return homeOutline
      case LabelType.Work:
        return briefcaseOutline
      default:
        return bookmarkOutline
    }
  }, [address])
}

interface BottomTrayProps {
  carrierId: string
  prepend?: React.ReactNode
  className?: string
  children?: React.ReactNode
}

const BottomTray: React.FC<BottomTrayProps> = (props) => {
  const [addresses, setAddress] = useGeneralStore((state) => [state.addresses, state.setAddress], shallow)
  const [currentUser] = useAuthStore((state) => [state.currentUser], shallow)
  const { toggleAddressSaved } = useUnsavedAddresses()

  const [networkType, selectedAddressStore, setSelectedAddress, removeAddress] = useGeneralStore(
    (state) => [state.networkType, state.selectedAddress, state.setSelectedAddress, state.removeAddress],
    shallow
  )

  const selectedAddress = addresses.find((addr) => addr.placeId === selectedAddressStore?.placeId)

  function saveAddress(address: Address) {
    toggleAddressSaved(address)
    setIsAddressSavedToastOpen(true)
  }

  function updateAddress(address: Address) {
    setAddress(
      address,
      addresses.findIndex((addr) => addr.placeId === address.placeId)
    )
  }

  const { openModal } = useAddressLabelDialog({
    isRenaming: !selectedAddress?.localMemory,
    address: selectedAddress,
    onSubmit: (labelType: LabelType, label: string) => {
      if (selectedAddress) {
        const newAddress = { ...selectedAddress, labelType, label }
        if (!selectedAddress?.localMemory) {
          updateAddress(newAddress)
        } else {
          saveAddress(newAddress)
        }
        setSelectedAddress(newAddress)
      }
    }
  })

  const [isAddressSavedToastOpen, setIsAddressSavedToastOpen] = React.useState(false)

  const selectedAddressIcon = useAddressIcon(selectedAddress)

  useDisableSwipeBack()

  const swiperRef = React.useRef<SwiperType>()
  React.useEffect(() => {
    const addrIdx = selectedAddress ? addresses.findIndex((addr) => addr.placeId === selectedAddress.placeId) : -1
    if (addrIdx !== -1) {
      swiperRef.current?.slideTo(addrIdx)
    }
  }, [selectedAddress, addresses])

  React.useEffect(() => {
    if (!selectedAddress && addresses.length) {
      setSelectedAddress(addresses[0])
    }
  }, [addresses, selectedAddress, setSelectedAddress])

  const footerRef = React.useRef<HTMLDivElement>(null)
  const addressDetailsRef = React.useRef<HTMLDivElement>(null)
  const carrierListRef = React.useRef<HTMLDivElement>(null)

  const { handleCollapse, isCollapsed, hasBeenOnboardedOnMapTray } = UseBottomTrayGesture({
    footerRef,
    addressDetailsRef,
    carrierListRef
  })

  if (!addresses.length) {
    return <div />
  }

  return (
    <>
      <footer
        className={cx(BottomTrayStyles, hasBeenOnboardedOnMapTray ? '' : boardingGestureStyles, props.className)}
        ref={footerRef}
      >
        <HandTutorial className={handTutorialStyles} />
        {props.prepend}
        <div className='address-details' ref={addressDetailsRef}>
          <div className='drag-handle' />
          <section className='tray-heading horizontal-spacing'>
            <IonText className='label'>
              {currentUser ? (
                <IonIcon
                  icon={selectedAddressIcon}
                  lazy
                  onClick={(e) => {
                    e.stopPropagation()
                    if (!selectedAddress) return
                    if (selectedAddress.localMemory) {
                      openModal()
                    } else {
                      toggleAddressSaved(autoLabelAddress(selectedAddress))
                    }
                  }}
                />
              ) : null}
              {selectedAddress?.label ?? 'Select an address'}
              {currentUser && !selectedAddress?.localMemory ? (
                <IonIcon
                  src='assets/images/profile/edit.svg'
                  className='edit-icon'
                  onClick={(e) => {
                    e.stopPropagation()
                    openModal()
                  }}
                />
              ) : null}
            </IonText>
            {!!selectedAddress?.localMemory || !currentUser ? (
              <IonIcon
                icon={close}
                onClick={(e) => {
                  e.stopPropagation()
                  const addressIdx = addresses.findIndex((addr) => addr.placeId === selectedAddress?.placeId)
                  removeAddress(addressIdx)
                  setSelectedAddress(addresses.length ? addresses[0] : undefined)
                }}
              />
            ) : (
              <IonIcon src={isCollapsed ? chevronUp : chevronDown} onClick={handleCollapse} />
            )}
          </section>
          <div className='tray-body'>
            <div>
              <Swiper
                touchStartPreventDefault={false}
                className='network-slide'
                navigation
                pagination={{ clickable: true }}
                modules={[Pagination]}
                spaceBetween={50}
                onSwiper={(swiper) => (swiperRef.current = swiper)}
                onSlideChange={(swiper) => {
                  setSelectedAddress(addresses[swiper.activeIndex])
                }}
              >
                {addresses.map((address) => (
                  <SwiperSlide className='horizontal-spacing' key={address.placeId}>
                    <SwiperContent>
                      <LocationDetails carrierId={props.carrierId} networkType={networkType} address={address} />
                    </SwiperContent>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
        <CarrierCoverageList contentRef={carrierListRef} selectedCarrierId={props.carrierId} />
        {props.children}
      </footer>
      <IonToast
        cssClass={successSaveToastStyles}
        isOpen={isAddressSavedToastOpen}
        header='Address saved to your Address Book'
        message='This address will now be factored into your plan results'
        icon={checkmarkCircleOutline}
        position='top'
        animated
        duration={3000}
        onDidDismiss={() => setIsAddressSavedToastOpen(false)}
      />
    </>
  )
}

export default BottomTray
