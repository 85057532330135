import { css } from '@emotion/css'
import { ScrollDetail } from '@ionic/core'
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react'
import React from 'react'
import { useParams } from 'react-router'
import { RoutePaths } from '../../paths'
import { Plan } from '../../services/planService/Plan'
import { UsePlan } from '../../services/planService/Plan.service'
import { useBoundStore } from '../../store'
import { ComparePlansContainerStyles } from './ComparePage.styles'
import CompareTable from './components/CompareTable/CompareTable'
import { LoaderComponent } from '../../components/BackendSyncLoader/LoaderComponent'

const BackButtonStyles = css``

function usePlansFromUrl(allPlans: Plan[] | undefined) {
  const params = useParams<{ plan_ids: string }>()
  const planIds = params.plan_ids.split(',')

  return allPlans?.filter((p) => planIds.includes(p.planId)) || []
}

export function ComparePage() {
  const addresses = useBoundStore((state) => state.addresses)
  const stickyRefs = React.useRef<HTMLIonGridElement>(null)

  const allPlans = UsePlan.usePlansWithPerf()
  const plans = usePlansFromUrl(allPlans.data)

  // Set top for sticky row headers
  const handleScroll = (ev: CustomEvent<ScrollDetail>) => {
    if (ev.detail.deltaY === 0) return
    const stickies = stickyRefs.current?.querySelectorAll('ion-row.sticky')
    stickies?.forEach((sticky) => {
      const y = sticky.getClientRects()[0].y
      ;(sticky.firstChild as HTMLElement).style.top = `${y}px`
    })
  }

  return (
    <IonPage className={ComparePlansContainerStyles}>
      <IonHeader className='ion-padding-horizontal ion-margin-bottom'>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton mode='md' className={BackButtonStyles} defaultHref={RoutePaths.root} />
          </IonButtons>
          <IonTitle>Compare Plans</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen scrollX scrollEvents onIonScroll={handleScroll}>
        {!allPlans.isFetched ? <LoaderComponent /> : <CompareTable plans={plans} addresses={addresses} />}
      </IonContent>
    </IonPage>
  )
}
