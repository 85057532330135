import { css, cx } from '@emotion/css'
import { IonIcon, IonRouterLink } from '@ionic/react'

const ResetButtonStyles = css`
  background-color: transparent;
  color: var(--ion-color-dark);
  width: 40px;
  height: 40px;
  cursor: pointer;

  ion-icon {
    width: 22px;
    height: 25px;
  }
`

interface ResetFlowButtonProps {
  className?: string
  ariaLabel?: string
  routerLink?: string
  onClick?: () => void
}

export const ResetButton: React.FC<ResetFlowButtonProps> = ({ className, ariaLabel, onClick, routerLink }) => {
  return (
    <IonRouterLink
      role='button'
      className={cx(ResetButtonStyles, className)}
      aria-label={ariaLabel || 'Reset button'}
      onClick={onClick}
      routerLink={routerLink}
    >
      <IonIcon src='/assets/icons/refresh.svg' />
    </IonRouterLink>
  )
}
