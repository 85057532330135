import { PlanPreferencesV3 } from '../../../../store/standalone/filters-store/filter-plans'

function isValueDifferent<T>(a: T, b: T) {
  if (Array.isArray(a) && Array.isArray(b)) {
    return a.length !== b.length || a.some((item) => !b.includes(item))
  }
  return a !== b
}

export function getFilterDiff(storePreferences: PlanPreferencesV3, newPreferences: PlanPreferencesV3) {
  const diff: Partial<Record<keyof PlanPreferencesV3, boolean>> = {}
  for (const _key in newPreferences) {
    const key = _key as keyof PlanPreferencesV3

    if (isValueDifferent(storePreferences[key], newPreferences[key])) {
      diff[key] = true
    }
  }
  return diff
}
