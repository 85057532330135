import { css } from '@emotion/css'

export const EllipseHeading = css`
  top: -90px;
`

export const EllipseBackgroundStyles = css`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: -1;
  display: flex;
  justify-content: center;
  max-height: 300px;
  min-height: 300px;
  min-width: 370px;

  svg {
    width: 100%;
    max-height: 300px;
    min-height: 300px;
    color: #e5e832;
  }
`
