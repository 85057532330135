import * as React from 'react'
import { css } from '@emotion/css'
import { useBackgroundLocation, useLocationPermission } from './useLocationPermissions'
import { BgLocationModal } from './BackgroundLocationPermissionModal'
import { LocationModal } from './LocationPermissionModal'

export const BarryWrapperStyles = css`
  display: flex;
  justify-content: center;
`

export function MapPermissionsPopup(props: {
  bgModalBody?: React.ReactNode | React.ReactNode[]
  locationModalBody?: React.ReactNode | React.ReactNode[]
  onClose: () => void
}) {
  const { hasBackgroundLocation } = useBackgroundLocation()
  const { hasLocation } = useLocationPermission()

  const permissionState = getPermissionState()
  function getPermissionState() {
    if (hasBackgroundLocation.isLoading || hasLocation.isLoading) {
      return 'loading'
    }

    if (hasBackgroundLocation.data?.isGranted) {
      return 'granted'
    }

    if (hasLocation.data?.isGranted) {
      return 'show-bg'
    }

    return 'show-location'
  }

  if (permissionState === 'granted' || permissionState === 'loading') {
    return null
  }

  if (permissionState === 'show-bg') {
    return <BgLocationModal body={props.bgModalBody} onClose={props.onClose} />
  }

  return <LocationModal body={props.locationModalBody} onClose={props.onClose} />
}
