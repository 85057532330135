import { css } from '@emotion/css'
import { IonRouterLink } from '@ionic/react'
import { SortingOption } from '../../../components/Header/PlansHeader/PlansHeaderChips'
import { LazyPlansCarousel } from '../../../components/PlanResult/LazyPlansCarousel'
import { PlanResultCardSkeleton } from '../../../components/PlanResult/PlanResultCardSkeleton'
import { MainNavigationPaths } from '../../../paths'
import { UsePlan } from '../../../services/planService/Plan.service'
import { flatMapPages } from '../../../utils/flat-map-pages'
import { ResetMargin } from '../../../utils/styles/MarginUtils.styles'

const Top3PlansStyles = css`
  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16.5px;
  }

  .all-results {
    font-size: 16px;
    line-height: 24px;
    color: var(--ion-text-color);
    text-decoration: underline;
  }

  h4 {
    line-height: 22.5px;
    font-weight: 900;
  }
`

export function Top3Plans() {
  const planResultsQuery = UsePlan.useBestPlans({ sortBy: SortingOption.Featured })
  const planResults = planResultsQuery.isLoading ? [] : flatMapPages(planResultsQuery.data)
  const top3Plans = planResults.slice(0, 3)

  return (
    <section className={Top3PlansStyles}>
      <header>
        <h4 className={ResetMargin}>Top 3 plans for you</h4>
        <IonRouterLink className='all-results' routerLink={MainNavigationPaths.results}>
          All results
        </IonRouterLink>
      </header>
      <main>{!planResultsQuery.isFetched ? <PlanResultCardSkeleton /> : <LazyPlansCarousel plans={top3Plans} />}</main>
    </section>
  )
}
