import { cx } from '@emotion/css'
import * as React from 'react'
import { SeparatorContainerStyles, SeparatorStyles } from './Separator.styles'

interface SeparatorProps {
  color: string
  className?: string
}
export const Separator: React.FC<SeparatorProps> = (props) => {
  return <div className={cx(SeparatorStyles(props.color), props.className)}></div>
}

export const SeparatorContainer: React.FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className
}) => {
  return <div className={cx(SeparatorContainerStyles, className)}>{children}</div>
}
