import { cx } from '@emotion/css'
import { headerStyles } from '../ChatbotPage.styles'
import { IonHeader, IonTitle, IonToolbar } from '@ionic/react'
import { WidthLimiterStyles } from '../../../components/WidthLimiter/WidthLimiter'
import { ResetButton } from '../../../components/ResetButton/ResetButton'
import CloseButton from '../../../components/CloseButton/CloseButton'
import { useOpenFrom } from '../../../components/Header/Header'
import { RoutePaths } from '../../../paths'
import { trackAmplitudeEvent } from '../../../utils/amplitude'

interface ChatHeaderComponentProps {
  className?: string
  title: string
  resetLink?: string
  onResetClick?: () => void
  isResetEnabled?: boolean
}

function handleCloseClick() {
  trackAmplitudeEvent('ChatBarry - Close')
}

const ChatHeaderComponent = ({
  className,
  title,
  resetLink,
  onResetClick,
  isResetEnabled = true
}: ChatHeaderComponentProps) => {
  const openFrom = useOpenFrom()


  function handleResetClick() {
    trackAmplitudeEvent('ChatBarry - Restart')
    onResetClick?.()
  }

  return (
    <IonHeader className={cx(headerStyles, className)}>
      <IonToolbar>
        <div className={cx(WidthLimiterStyles, 'chat-toolbar-internal')}>
          {!!isResetEnabled && (
            <ResetButton className='chat-reset' routerLink={resetLink} onClick={handleResetClick} ariaLabel='reset chat' />
          )}
          <IonTitle className='chat-title'>{title}</IonTitle>
          <CloseButton routerLink={openFrom ?? RoutePaths.root} className='chat-close' onClose={handleCloseClick} />
        </div>
      </IonToolbar>
    </IonHeader>
  )
}

export default ChatHeaderComponent
