import { cx } from '@emotion/css'
import { IonPage } from '@ionic/react'
import React from 'react'
import { ChatbotContentContainerStyles, ChatbotPageStyles } from './ChatbotPage.styles'
import { ChatFlowSelector } from './ChatFlow'
import { ChatComponent } from './components/ChatComponent'
import { usePredefinedChat } from './hooks/useChat'
import { ValidatedResults } from './ServerResponse'

function _sendToLLM() {
  return Promise.resolve({
    resultStatus: ValidatedResults.VALID
  })
}

function ChatMenuPage() {
  const chatTimeline = React.useMemo(() => {
    return [
      {
        key: 'entrypoint' as 'entrypoint'
      },
      {
        key: 'question__start_plan_finder' as 'question__start_plan_finder'
      }
    ]
  }, [])

  const chat = usePredefinedChat({
    sendAnswerToLLM: _sendToLLM,
    chatFlow: ChatFlowSelector,
    onServerResponse: () => {},
    timelineRecords: chatTimeline
  })

  return (
    <IonPage className={cx(ChatbotPageStyles, ChatbotContentContainerStyles)}>
      <ChatComponent title='Chat with Barry' isLoading={false} chat={chat} disableRestart />
    </IonPage>
  )
}
export default ChatMenuPage
