import React, { useImperativeHandle } from 'react'
import PlanResultCard from '../../../../components/PlanResult/PlanResultCard'
import { Plan } from '../../../../services/planService/Plan'
import { createGesture } from '@ionic/react'
import { cx } from '@emotion/css'
import PlanOverlayLike from './PlanOverlay/PlanOverlayLike'
import PlanOverlayPass from './PlanOverlay/PlanOverlayPass'
import PlanOverlayUnsure from './PlanOverlay/PlanOverlayUnsure'
import { PlanOverlayWrapperStyles, TinderPlanCardStyles } from './PlanSwipe.styles'

export type PlanSwipeCardRef = {
  plan: Plan
  swipe: (direction: 'left' | 'right' | 'up') => void
  revert: () => void
}

interface PlanSwipeCardProps {
  plan: Plan
  position: number
  onSwipe: (direction: 'left' | 'right' | 'up') => void
}

const PlanSwipeCard = React.forwardRef<PlanSwipeCardRef, PlanSwipeCardProps>(
  (
    props: { position: number; plan: Plan; onSwipe: (direction: 'left' | 'right' | 'up') => void },
    ref: React.Ref<PlanSwipeCardRef>
  ) => {
    const cardRef = React.useRef<HTMLDivElement>(null)
    const likeOverlayRef = React.useRef<HTMLDivElement>(null)
    const passOverlayRef = React.useRef<HTMLDivElement>(null)
    const unsureOverlayRef = React.useRef<HTMLDivElement>(null)

    useImperativeHandle(ref, () => ({
      swipe: (direction: 'left' | 'right' | 'up') => {
        if (cardRef.current) {
          const windowWidth = window.innerWidth
          const windowHeight = window.innerHeight

          const likeOverlayStyle = likeOverlayRef.current?.style
          const passOverlayStyle = passOverlayRef.current?.style
          const unsureOverlayStyle = unsureOverlayRef.current?.style

          cardRef.current.style.transition = '0.6s ease-out'
          if (direction === 'right') {
            cardRef.current.style.transform = `translateX(${windowWidth * 2}px) rotate(30deg)`
            likeOverlayRef.current?.style.setProperty('transition', 'opacity 0.6s ease-out')
            likeOverlayStyle?.setProperty('opacity', '1')
          } else if (direction === 'left') {
            cardRef.current.style.transform = `translateX(-${windowWidth * 2}px) rotate(-30deg)`
            passOverlayStyle?.setProperty('transition', 'opacity 0.6s ease-out')
            passOverlayStyle?.setProperty('opacity', '1')
          } else if (direction === 'up') {
            cardRef.current.style.transform = `translateY(-${windowHeight * 2}px)`
            unsureOverlayStyle?.setProperty('transition', 'opacity 0.6s ease-out')
            unsureOverlayStyle?.setProperty('opacity', '1')
          }
        }
      },
      revert: () => {
        const likeOverlayStyle = likeOverlayRef.current?.style
        const passOverlayStyle = passOverlayRef.current?.style
        const unsureOverlayStyle = unsureOverlayRef.current?.style
        if (cardRef.current) {
          cardRef.current.style.transition = '0.6s ease-out'
          cardRef.current.style.transform = ''
          cardRef.current.style.opacity = ''
          likeOverlayStyle?.setProperty('opacity', '0')
          passOverlayStyle?.setProperty('opacity', '0')
          unsureOverlayStyle?.setProperty('opacity', '0')
        }
      },
      plan: props.plan
    }))

    React.useEffect(() => {
      if (cardRef.current === null) return
      const style = cardRef.current.style
      const cardWidth = cardRef.current.clientWidth
      const cardHeight = cardRef.current.firstElementChild?.clientHeight ?? 0
      const windowWidth = window.innerWidth
      const windowHeight = window.innerHeight

      const likeOverlayStyle = likeOverlayRef.current?.style
      const passOverlayStyle = passOverlayRef.current?.style
      const unsureOverlayStyle = unsureOverlayRef.current?.style

      const onStartHandler = () => {
        style.transition = 'none'
      }

      const onMoveHandler = (ev: any) => {
        style.transform = `translateX(${ev.deltaX}px) translateY(${ev.deltaY}px) rotate(${ev.deltaX / 20}deg)`
        // style.opacity = `${1 - Math.abs(ev.deltaX) / cardWidth}`
        likeOverlayStyle?.setProperty('opacity', `${Math.max(0, Math.min(1, ev.deltaX / cardWidth))}`)
        passOverlayStyle?.setProperty('opacity', `${Math.max(0, Math.min(1, -ev.deltaX / cardWidth))}`)
        unsureOverlayStyle?.setProperty('opacity', `${Math.max(0, Math.min(1, -ev.deltaY / cardHeight))}`)
      }

      const onEndHandler = (ev: any) => {
        style.transition = '0.3s ease-out'
        if (ev.deltaX > cardWidth * 0.5) {
          style.transform = `translateX(${windowWidth * 2}px)`
          props.onSwipe('right')
        } else if (ev.deltaX < -cardWidth * 0.5) {
          style.transform = `translateX(-${windowWidth * 2}px)`
          props.onSwipe('left')
        } else if (ev.deltaY < -cardHeight * 0.5) {
          style.transform = `translateY(-${windowHeight * 2}px)`
          props.onSwipe('up')
        } else {
          style.transform = ''
        }
        likeOverlayStyle?.setProperty('opacity', '0')
        passOverlayStyle?.setProperty('opacity', '0')
        unsureOverlayStyle?.setProperty('opacity', '0')
      }

      const gestureX = createGesture({
        el: cardRef.current,
        gestureName: 'swipe - X',
        direction: 'x',
        gesturePriority: 1,
        canStart: () => props.position === 0,
        onStart: onStartHandler,
        onMove: onMoveHandler,
        onEnd: onEndHandler
      })
      gestureX.enable()
      const gestureY = createGesture({
        el: cardRef.current,
        gestureName: 'swipe - Y',
        direction: 'y',
        gesturePriority: 0,
        canStart: () => props.position === 0,
        onStart: onStartHandler,
        onMove: onMoveHandler,
        onEnd: onEndHandler
      })
      gestureY.enable()
    }, [cardRef, props])

    return (
      // dark the card if position !== 0
      <div ref={cardRef} className={PlanOverlayWrapperStyles}>
        <PlanResultCard
          className={cx(TinderPlanCardStyles, { isFirst: props.position === 0 })}
          plan={props.plan}
          alwaysExpanded
          hideFavoriteButton
        />
        <PlanOverlayLike ref={likeOverlayRef} />
        <PlanOverlayPass ref={passOverlayRef} />
        <PlanOverlayUnsure ref={unsureOverlayRef} />
      </div>
    )
  }
)

export default PlanSwipeCard
