import { css, cx } from '@emotion/css'
import { IonButton, IonIcon, IonModal, IonSearchbar, IonSpinner, isPlatform } from '@ionic/react'
import * as React from 'react'
import Autocomplete, { CustomSearchbarStyles } from '../../components/Autocomplete'
import M2CatalystSdkPlugin from '../../plugin/M2CatalystSdkPlugin'
import { isNativeAndroid } from '../../store/standalone/permission-store'
import { MapPermissionsPopup } from '../map/MapPermissionsPopup'
import { getAddressFromGeolocation } from '../../utils/Address'
import { Address, LabelType } from '../../store/address'
import { useAddressLabelDialog } from './AddressLabelDialog'
import { Logger } from '../../utils/Logger'
import { Geolocation } from '@capacitor/geolocation'
import { useBoundStore } from '../../store'
import shallow from 'zustand/shallow'
import { autoLabelAddress } from './autolabel-address'
import { MapsWrapper } from '../../components/BackendSyncLoader/MapsWrapper'

const dismissButtonSize = '57.8px'
const SearchbarWrapperStyles = css`
  position: relative;

  &.populated {
    grid-template-columns: 1fr ${dismissButtonSize};
    .location {
      position: relative;
    }
  }

  ion-button.location,
  ion-spinner.location {
    text-transform: none;
    position: absolute;
    top: 0;
    right: 12px;
  }

  ion-spinner.location {
    top: 50%;
    margin-top: -8px;
  }

  ion-button.location,
  ion-button.location-button {
    margin: 0;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    &::part(native) {
      padding: 0;
    }
  }

  .searchbar-search-icon {
    left: 10px;
  }
  .searchbar-input {
    padding: 10px 34px 10px 32px;
    border: 2px solid var(--goji-primary-black);
  }

  ion-searchbar {
    padding-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    .on-map .searchbar-input {
      --border-radius: 60px !important;
      height: 40px;
      border: none;
      margin-top: 4px;
      margin-right: 4px;
      box-shadow: 0px 1px 2px var(--ion-color-light-shade);
    }
  }
`

interface AddressSearchbarProps {
  searchBarPlaceholder?: string
  addresses: Address[]
  onAddressSelected: (address: Address) => void
  onMap?: boolean
}

export function AddressesSearchbar(props: AddressSearchbarProps) {
  const loadingLocation = useBoundStore((state) => state.loadingLocation)
  const searchBar = React.useRef<HTMLIonSearchbarElement>(null)
  const isMobile = isPlatform('ios') || isPlatform('android')
  const onDidPresent = React.useCallback(() => {
    searchBar.current?.setFocus()
  }, [])

  const [currentAddress, setCurrentAddress] = useBoundStore(
    (state) => [state.currentAddress, state.setCurrentAddress],
    shallow
  )

  const [editingAddress, setEditingAddress] = React.useState<Address | undefined>(undefined)

  function onAddressSelected(address: Address) {
    if (props.onMap) {
      props.onAddressSelected(autoLabelAddress(address))
      return
    }

    setEditingAddress(address)
    openModal()
  }

  const { openModal } = useAddressLabelDialog({
    address: editingAddress,
    onSubmit: (labelType: LabelType, label: string) => {
      if (editingAddress) {
        props.onAddressSelected({ ...editingAddress, labelType, label })
      }
    },
    onDidDismiss: () => {
      setEditingAddress(undefined)
    }
  })

  const [searchbarState, setSearchbarState] = React.useState<'idle' | 'showPermissionModal' | 'showAutocompleteModal'>(
    'idle'
  )

  const currentAddressIncluded = currentAddress
    ? props.addresses.find((addr) => addr.placeId === currentAddress.placeId)
    : false

  const onModalDismiss = () => {
    setSearchbarState('idle')
  }

  async function onClickLocationButton() {
    if (!isNativeAndroid || (await M2CatalystSdkPlugin.hasLocationPermissions()).isGranted) {
      // setUserLocationAndAddress()
      try {
        const position = await Geolocation.getCurrentPosition()
        const geolocationAddress = await getAddressFromGeolocation(position)

        if (!geolocationAddress) {
          throw new Error('Failed to get address from geolocation')
        }

        setCurrentAddress(geolocationAddress)
        onAddressSelected(geolocationAddress)
      } catch (e) {
        Logger.error(e)
      }
    } else {
      setSearchbarState('showPermissionModal')
    }
  }

  return (
    <MapsWrapper>
      {searchbarState === 'showPermissionModal' ? (
        <MapPermissionsPopup
          onClose={() => setSearchbarState('idle')}
          locationModalBody={
            <>
              Granting Goji Mobile access to <b>precise location</b> will allow us to recommend better plan results as
              well as display your current location on the Coverage Map. Only you will be able to see your current
              location.
            </>
          }
          bgModalBody={
            <>
              <div>
                Setting your location access to <b>Allow all the Time</b> will allow Goji to access your location even
                when the app is closed.
              </div>
              <div className='ion-padding-top' />
              <div>
                This setting will help us improve our Coverage Map data, which in turn will ensure that Goji is able to
                give you the best plan recommendations possible.
              </div>
            </>
          }
        />
      ) : null}
      <div className={cx(SearchbarWrapperStyles, props.addresses.length ? 'populated' : '')}>
        <IonSearchbar
          searchIcon='/assets/icons/search.svg'
          placeholder={props.searchBarPlaceholder}
          onFocus={() => setSearchbarState('showAutocompleteModal')}
          className={cx(props.onMap && !isMobile ? 'on-map' : '', CustomSearchbarStyles)}
        />
        {!loadingLocation && !currentAddressIncluded ? (
          <IonButton
            fill='clear'
            onClick={onClickLocationButton}
            disabled={loadingLocation}
            className={cx('ion-margin-vertical button-clear location-button')}
          >
            <IonIcon src='/assets/icons/location.svg'></IonIcon>
          </IonButton>
        ) : null}
        {loadingLocation && isMobile ? <IonSpinner className='sm location' /> : null}
      </div>
      <IonModal
        handle={false}
        isOpen={searchbarState === 'showAutocompleteModal'}
        onDidDismiss={onModalDismiss}
        initialBreakpoint={1}
        breakpoints={[0, 1]}
        onDidPresent={onDidPresent}
      >
        <Autocomplete
          addresses={props.addresses}
          onClose={onModalDismiss}
          onAddressSelected={onAddressSelected}
          ref={searchBar}
        />
      </IonModal>
    </MapsWrapper>
  )
}
