export const IconAddons = ({ color, className }: { color?: string; className?: string }) => {
  return (
    <div className={className}>
      <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M5 8.5V6.5H13V8.5H5ZM5 11.5V9.5H13V11.5H5ZM5 14.5V12.5H13V14.5H5ZM14 6.5V4.5H12V2.5H14V0.5H16V2.5H18V4.5H16V6.5H14ZM0 18.5V0.5H11V2.5H2V16.5H16V7.5H18V18.5H0Z'
          fill={color ?? 'rgba(0, 0, 0, 0.6)'}
        />
      </svg>
    </div>
  )
}
