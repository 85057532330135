import { css } from '@emotion/css'

export const ProgressBarStyles = (color: string) => css`
  height: 16px;
  border-radius: 2px;
  --buffer-background: var(--ion-color-light);
  --progress-background: ${color};

  &.ion-color-success-tint {
    --ion-color-base: var(--ion-color-success-tint);
  }

  &::part(track) {
    background-color: var(--buffer-background);
  }
  &::part(progress) {
    background: ${color};
  }
`

export const SignalMeterStrengthStyles = css`
  display: flex;
  width: 100%;

  .tier-text {
    font-family: Aktifo-A, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 12px;
    margin: 0;
    margin-top: 8px;
    height: 100%;
    color: var(--ion-color-medium-tint);
    overflow: hidden;
    white-space: break-spaces;
  }

  .tier-bar {
    position: absolute;
    background-color: var(--ion-background-color);
    width: 2px;
    height: 16px;
    top: -16px;
  }

  .dark-text {
    color: var(--ion-color-dark);
  }

  .signal-meter-tier {
    margin-right: 2px;
    min-width: 40px;
    position: relative;
  }
`

export const SignalMeterStyles = css`
  .signal-meter-container {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 33px;
    margin-bottom: 2em;
  }

  .center-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
  }

  .map-crop-container {
    position: relative;
    width: 50vmin;
    max-width: 300px;
    padding: 5px;
  }
  .map-crop {
    position: relative;
    margin: auto;
    border-radius: 100%;
    max-height: 30vh;
    overflow: hidden;
    aspect-ratio: 1;
    z-index: 2;

    #map {
      border-radius: 100%;
      height: 100%;
      min-height: initial;
    }
  }

  .round-progress-bar {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
  }

  .signal-meter-title {
    margin-top: 8px;
  }

  .signal-label {
    position: absolute;
    color: var(--ion-color-medium);
    font-weight: 400;
    letter-spacing: 0.15px;
  }

  .signal-label-poor {
    left: 0;
    bottom: 0;
    transform: translateX(-50%);
  }
  .signal-label-fair {
    left: 0;
    top: 0;
    transform: translateX(-50%);
  }
  .signal-label-good {
    right: 0;
    top: 0;
    transform: translateX(50%);
  }
  .signal-label-excellent {
    bottom: 0;
    right: 0;
    transform: translateX(50%);
  }
`
