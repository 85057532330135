import { IonContent, IonIcon, IonItem, IonList, IonPopover } from '@ionic/react'
import { ControlChipStyles, customIconStyles } from './ControlChip'
import React from 'react'
import { caretDown } from 'ionicons/icons'
import { css } from '@emotion/css'

const DropdownSelectorStyle = css`
  ion-item {
    --inner-border-width: 0px;
  }
`

interface DropdownOption {
  value: number | string
  label: string
}

interface DropdownOptionsProps {
  value: number | string
  onValueChange: (value: any) => void
  icon: string | JSX.Element
  text?: string
  options: DropdownOption[]
  endIcon?: string | JSX.Element
}

export const DropdownSelector = ({ value, onValueChange, icon, text, endIcon, options }: DropdownOptionsProps) => {
  const popover = React.useRef<HTMLIonPopoverElement>(null)
  const [popoverOpen, setPopoverOpen] = React.useState(false)

  const openPopover = (e: React.MouseEvent) => {
    popover.current!.event = e
    setPopoverOpen(true)
  }
  return (
    <>
      <div className={ControlChipStyles} onClick={openPopover}>
        {typeof icon === 'string' ? <IonIcon icon={icon} /> : <div className={customIconStyles}>{icon}</div>}
        <span>{text}</span>
        <span>{options.find((option) => option.value === value)?.label}</span>
        {endIcon ? (
          typeof endIcon === 'string' ? (
            <IonIcon icon={endIcon} />
          ) : (
            endIcon
          )
        ) : (
          <IonIcon style={{ fontSize: '12px' }} icon={caretDown} />
        )}
      </div>
      <IonPopover
        ref={popover}
        isOpen={popoverOpen}
        onDidDismiss={() => setPopoverOpen(false)}
        className={DropdownSelectorStyle}
      >
        <IonContent>
          <IonList>
            {options.map((option) => (
              <IonItem
                button={true}
                detail={false}
                key={option.value}
                onClick={() => {
                  onValueChange(option.value)
                  setPopoverOpen(false)
                }}
                className=''
              >
                <span>{option.label}</span>
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      </IonPopover>
    </>
  )
}
