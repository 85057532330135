import API from '../../api'
import { updateChatCache } from '../../pages/llm/hooks/useChatStorage'
import { IChatFlow } from '../../pages/llm/types/ChatStep'
import { PredefinedChatStorage, ChatStorageSource } from '../../pages/llm/types/ChatStorageSource'
import { getApiSource } from '../../pages/llm/utils/chatApiSource'
import { getLocalStorageSource } from '../../pages/llm/utils/chatStorageSource'

export class SourceSet<T extends IChatFlow> {
  constructor(public localSource: ChatStorageSource<T>, public remoteSource: ChatStorageSource<T>) {}
}

export class ChatSourceService {
  constructor(public sourceSet = new SourceSet(getLocalStorageSource(), getApiSource())) {}
  async switchFromLocalStorageToApi() {
    const localData = await this.sourceSet.localSource.getStorage()

    if (!localData) {
      return
    }

    await this.sourceSet.remoteSource.setStorage(localData)
    updateChatCache(localData)
    this.removeLocalData()
  }

  async removeLocalData() {
    await this.sourceSet.localSource.setStorage(undefined)
  }
}

export const chatSourceService = new ChatSourceService()

export class ChatService {
  async uploadChatStorage<T extends IChatFlow>(chatStorage: PredefinedChatStorage<T>) {
    await API.put('/profile/chat-state', chatStorage)
  }
}

const chatService = new ChatService()

export default chatService
