import { StateCreator } from 'zustand'
import { Plan } from '../services/planService/Plan'

export type SelectedPlansState = {
  selectedPlan: Plan | null
  favoritePlanIds: Plan['planId'][]
}

export type SelectedPlansActions = {
  setSelectedPlan: (plan: Plan | null) => void
  addFavoritePlanId: (plan: Plan['planId']) => void
  removeFavoritePlanId: (plan: Plan['planId']) => void
}

export const selectedPlansInitialState: SelectedPlansState = {
  selectedPlan: null,
  favoritePlanIds: []
}

const createSelectedPlansSlice: StateCreator<
  SelectedPlansState & SelectedPlansActions,
  [],
  [],
  SelectedPlansState & SelectedPlansActions
> = (set) => ({
  ...selectedPlansInitialState,
  setSelectedPlan: (selectedPlan: Plan | null) => {
    set(() => ({ selectedPlan }))
  },
  addFavoritePlanId: (favoritePlanId: Plan['planId'] | null) => {
    if (favoritePlanId === null) {
      return
    }
    set((state) => {
      const favoritePlanIds = [...state.favoritePlanIds, favoritePlanId]
      return { favoritePlanIds }
    })
  },
  removeFavoritePlanId: (favoritePlanId: Plan['planId'] | null) => {
    if (favoritePlanId === null) {
      return
    }
    set((state) => {
      const favoritePlanIds = state.favoritePlanIds.filter((id) => id !== favoritePlanId)
      return { favoritePlanIds }
    })
  }
})

export default createSelectedPlansSlice
