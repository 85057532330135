import create from 'zustand'
import { StateCreator } from 'zustand'
import { getInitialPreferencesV3, PlanPreferencesV3 } from './filter-plans'

export type FiltersStoreState = PlanPreferencesV3

export type FiltersStoreActions = {}

type FiltersStoreType = FiltersStoreState & FiltersStoreActions

const createNavigationSlice: StateCreator<FiltersStoreType, [], [], FiltersStoreType> = (set, get) =>
  getInitialPreferencesV3()

const useFiltersStore = create(createNavigationSlice)

export default useFiltersStore
