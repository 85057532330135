import { Geolocation, Position } from '@capacitor/geolocation'
import produce from 'immer'
import { hasCurrentLocation, trackUserPropertyFlag } from '../utils/amplitude'
import { StateCreator } from 'zustand'
import { getAddressFromGeolocation, upsertAddress } from '../utils/Address'
import { Logger } from '../utils/Logger'

export enum LabelType {
  Home = 'HOME',
  Work = 'WORK',
  Other = 'OTHER'
}

export type Address = {
  placeId: string
  latitude: number
  longitude: number
  addressName: string

  localMemory?: boolean // if true, it means that the address is not saved in the backend
  labelType: LabelType // if not provided, it will be considered as 'Other'
  label: string // if not provided, it will be considered as addressName.split(',')[0]
}

export type AddressState = {
  loadingLocation: boolean
  currentAddress: Address | undefined
  currentAddressError: boolean
  addresses: Address[]
  editingIndex?: number
  selectedAddress?: Address
}

export type AddressActions = {
  setUserLocationAndAddress: () => Promise<(Position & { address?: Address }) | undefined>
  setCurrentAddress: (address: Address | undefined) => void
  setAddress: (address: Address, index?: number) => void
  removeAddress: (index: number) => void
  editAddress: (index: number) => void
  setSelectedAddress: (state: Partial<AddressState['selectedAddress']>) => void
}

export const addressInitialState: AddressState = {
  currentAddress: undefined,
  selectedAddress: undefined,
  loadingLocation: false,
  addresses: [],
  currentAddressError: false
}

const createAddressSlice: StateCreator<AddressState & AddressActions, [], [], AddressState & AddressActions> = (
  set,
  get
) => {
  return {
    ...addressInitialState,
    setUserLocationAndAddress: async (): Promise<(Position & { address?: Address }) | undefined> => {
      set(() => ({ loadingLocation: true }))

      try {
        const position = await Geolocation.getCurrentPosition()
        const geolocationAddress = await getAddressFromGeolocation(position)

        set((state) => {
          const shouldAddLocation = geolocationAddress
            ? !state.addresses.some((addr) => addr.placeId === geolocationAddress.placeId)
            : false

          const addresses = shouldAddLocation ? [...state.addresses, geolocationAddress!] : state.addresses

          return {
            currentAddress: geolocationAddress,
            addresses,
            loadingLocation: false,
            selectedAddress: geolocationAddress
          }
        })

        return { ...position, address: geolocationAddress }
      } catch (error) {
        Logger.error(error)
        set(() => ({ loadingLocation: false, currentAddressError: true }))
        // No point in resetting from outside since triggers a toast
        setTimeout(() => {
          set(() => ({ currentAddressError: false }))
        }, 5000)
      }
    },
    setCurrentAddress(address) {
      set(() => {
        trackUserPropertyFlag(hasCurrentLocation, true)
        return { currentAddress: address }
      })
    },
    setAddress: async (address, index?: number) => {
      if (!address.placeId) return

      set(
        produce((state) => {
          upsertAddress(address, state.addresses, index)
        })
      )

      set(
        produce((state) => {
          state.addresses = state.addresses.sort((a: Address, b: Address) => {
            if (a.labelType === LabelType.Home) return -1
            if (b.labelType === LabelType.Home) return 1
            if (a.labelType === LabelType.Work) return -1
            if (b.labelType === LabelType.Work) return 1
            if (a.localMemory && !b.localMemory) return 1
            if (!a.localMemory && b.localMemory) return -1
            return 0
          })
        })
      )
    },
    removeAddress: (index: number) => {
      const addresses = [...get().addresses]
      const addressDeletedPlaceId = addresses[index].placeId
      const currentAddressPlaceId = get().currentAddress?.placeId
      addresses.splice(index, 1)
      if (currentAddressPlaceId && currentAddressPlaceId === addressDeletedPlaceId) {
        set(() => {
          trackUserPropertyFlag(hasCurrentLocation, true)
          return { addresses, currentAddress: undefined }
        })
      }
      set(
        produce((state) => {
          state.addresses = addresses
        })
      )
    },
    editAddress: (index: number) => {
      set(
        produce((state) => {
          state.editingIndex = index
        })
      )
    },
    setSelectedAddress: (next: Partial<AddressState['selectedAddress']>) =>
      set(
        produce((state) => {
          state.selectedAddress = next
        })
      )
  }
}

export default createAddressSlice
