import throttle from 'lodash/throttle'
import * as React from 'react'
import shallow from 'zustand/shallow'
import { useGeneralStore } from '../../store'
import { useFFProviderLoader } from '../../utils/FeatureFlags'
import { useBackendUser } from '../../pages/home/hooks/useBackendUser'
import { BackendSyncService, defaultSyncService } from '../../services/backend-sync/backend-sync.service'
import { LoaderComponent } from './LoaderComponent'
import useAuthStore from '../../store/auth'

function subscribeToUserChanges(backendSyncService: BackendSyncService) {
  return useGeneralStore.subscribe(
    (state) => [
      state.currentPlanId,
      state.userCarrierId,
      state.useUnlimitedDataPlan,
      state.targetBudget,
      state.phoneLinesNeeded,
      state.reasonsForSwitch,
      state.favoritePlanIds,
      state.searchedPlansHistory,
      state.searchedAddressHistory
    ],
    throttle(() => backendSyncService.pushProfileToBackend(useGeneralStore.getState()), 1000),
    { equalityFn: shallow }
  )
}

function subscribeToAddressesChanges(backendSyncService: BackendSyncService) {
  return useGeneralStore.subscribe(
    (state) => state.addresses,
    throttle(() => backendSyncService.pushAddressesToBackend(useGeneralStore.getState().addresses), 1000),
    { equalityFn: shallow }
  )
}

function useProfileLoader(syncService: BackendSyncService) {
  const backendUser = useBackendUser(syncService)
  const currentUser = useAuthStore((store) => store.currentUser)

  React.useEffect(() => {
    if (!currentUser) {
      return
    }

    const profileSubscription = subscribeToUserChanges(syncService)
    const addressesSubscription = subscribeToAddressesChanges(syncService)

    return () => {
      profileSubscription()
      addressesSubscription()
    }
  }, [syncService, currentUser])

  return { isLoading: backendUser.isLoading }
}

const BackendLoader = (props: BackendSyncLoader.Props): React.ReactElement => {
  const ffProviderLoader = useFFProviderLoader(!!props.useFeatureFlags)
  const profileLoader = useProfileLoader(props.backendSyncService)

  if (profileLoader.isLoading || ffProviderLoader.isLoading) {
    return <LoaderComponent />
  }

  const FeatureFlagProvider = ffProviderLoader.data?.provider

  if (!FeatureFlagProvider) {
    return <>{props.children}</>
  }

  return <FeatureFlagProvider>{props.children}</FeatureFlagProvider>
}

namespace BackendSyncLoader {
  export type Props = {
    children: React.ReactNode
    backendSyncService: BackendSyncService
    useFeatureFlags?: boolean
  }
}
function BackendSyncLoader(
  props: Omit<BackendSyncLoader.Props, 'backendSyncService' | 'api'> & {
    backendSyncService?: BackendSyncService
  }
) {
  const syncService = props.backendSyncService ?? defaultSyncService

  return (
    <BackendLoader useFeatureFlags={props.useFeatureFlags} children={props.children} backendSyncService={syncService} />
  )
}
export default BackendSyncLoader
