import { timeOutline } from 'ionicons/icons'
import { Measurement } from '../Measurement/Measurement'
import { MeasurementsStyles } from './MeasurementsStyles'

export const formatValue = (value: number) => {
  if (!value) return '--'
  return value < 100 ? value.toFixed(1) : value.toFixed(0)
}

export const Measurements: React.FC<{ speed: number; uploadSpeed: number; avgLatency: number }> = ({
  speed,
  uploadSpeed,
  avgLatency
}) => {
  return (
    <section className={MeasurementsStyles}>
      <div className='upper-row'>
        <Measurement
          suffix={speed ? 'Mbps' : null}
          className='speed-measurement'
          title='Download'
          iconSrc='assets/icons/signal-meter/download.svg'
        >
          {speed ? <div>{formatValue(speed)}</div> : null}
        </Measurement>
        <Measurement
          suffix={uploadSpeed ? 'Mbps' : null}
          className='speed-measurement'
          title='Upload'
          iconSrc='assets/icons/signal-meter/upload.svg'
        >
          {uploadSpeed ? <div>{formatValue(uploadSpeed)}</div> : null}
        </Measurement>

        <Measurement suffix={avgLatency ? 'ms' : null} title='Latency' isLatency icon={timeOutline}>
          {avgLatency ? <div>{formatValue(avgLatency)}</div> : null}
        </Measurement>
      </div>
    </section>
  )
}
