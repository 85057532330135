import * as React from 'react'
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonButton,
  IonIcon,
  IonTitle,
  IonRow,
  IonImg,
  IonRouterLink
} from '@ionic/react'
import { useBoundStore } from '../../store'
import { useHistory, useLocation } from 'react-router'
import { cx } from '@emotion/css'
import { HeaderButton, HeaderStyles } from './HeaderStyles'
import { arrowBack } from 'ionicons/icons'
import { useSafeLocation } from '../../utils/hooks/useSafeLocation'
import { RoutePaths } from '../../paths'

const BackButton: React.FC<{ backTo?: string | React.ReactNode }> = ({ backTo }) => {
  const location = useLocation()

  if (backTo && typeof backTo !== 'string') {
    return <>{backTo}</>
  }

  return backTo ? (
    <IonRouterLink routerLink={backTo + location.search} color='primary' className={HeaderButton}>
      <IonIcon color='dark' src={arrowBack} />
    </IonRouterLink>
  ) : (
    <IonBackButton color={'primary'} defaultHref='/' mode='md' />
  )
}

export function useOpenFrom() {
  const location = useSafeLocation()
  const queryParams = new URLSearchParams(location?.search)
  const openFrom = queryParams.get('open-from')
  return openFrom
}

export function useFinishOnOpenFrom() {
  const location = useSafeLocation()
  const queryParams = new URLSearchParams(location?.search)
  const finishOnOpenFrom = queryParams.has('finish-on-open-from')
  return finishOnOpenFrom
}

interface HeaderProps {
  backTo?: string | React.ReactNode
  hideReset?: boolean
}
const Header: React.FC<HeaderProps> = ({ backTo, hideReset }) => {
  const history = useHistory()
  const reset = useBoundStore((state) => state.reset)
  const openFrom = useOpenFrom()

  const onClickTitle = () => {
    history.replace('/')
  }

  return (
    <IonHeader className={cx(HeaderStyles, 'ion-padding custom-width-limiter')}>
      <IonToolbar>
        <IonButtons slot='start'>
          <BackButton backTo={backTo} />
        </IonButtons>

        <IonTitle onClick={onClickTitle}>
          <IonRow className='ion-justify-content-center ion-align-items-center'>
            <IonImg src='/assets/images/goji.svg' />
          </IonRow>
        </IonTitle>

        <IonButtons slot='end'>
          {hideReset || openFrom ? (
            <div className='reset-placeholder' />
          ) : (
            <IonButton
              onClick={() => {
                reset()
                history.replace(RoutePaths.carrierSelector)
              }}
              color='dark'
            >
              <IonIcon src='/assets/icons/refresh.svg' />
            </IonButton>
          )}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  )
}

export default Header
