import { cx } from '@emotion/css'
import { EllipseBackgroundStyles } from './EllipseBackground.styles'

export function EllipseBackgroundDesktop({ className }: { className?: string }) {
  return (
    <div className={cx(EllipseBackgroundStyles, 'ellipse-desktop', className)}>
      <svg width='1400' height='252' viewBox='0 0 1400 252' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <ellipse cx='700.5' cy='-194' rx='1094.5' ry='446' transform='rotate(180 700.5 -194)' fill='#E5E832' />
      </svg>
    </div>
  )
}
