import { css } from '@emotion/css'

export const MeasurementStyles = css`
  background-color: var(--goji-color-secondary-blue);
  min-height: 65px;
  min-width: 0;
  border-radius: 8px;
  padding: 8px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .suffix {
    font-size: 12px;
    color: var(--ion-color-medium);
  }

  .measurement-title {
    flex-wrap: wrap-reverse;
    color: var(--ion-color-medium);
    display: flex;
    align-items: center;
    justify-content: center;

    ion-icon {
      margin-right: 4px;
    }

    &.isLatency {
      text-decoration: underline;
    }
  }

  .measurement-title {
    font-size: 14px;
  }

  .measurement-value {
    &.suffixed {
      margin-top: 8px;
    }
    margin-top: 2px;
    font-weight: 600;
    font-size: 22px;
    color: var(--ion-color-dark);
  }

  &.lower-measurement .measurement-value {
    font-weight: 500;
    margin-top: 6px;
    font-size: 18px;
  }
`
