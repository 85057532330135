export const IconPlan = ({ color, className }: { color?: string; className?: string }) => {
  return (
    <div className={className}>
      <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M4 14.5H6V12.5H4V14.5ZM4 10.5H6V8.5H4V10.5ZM4 6.5H6V4.5H4V6.5ZM8 14.5H14V12.5H8V14.5ZM8 10.5H14V8.5H8V10.5ZM8 6.5H14V4.5H8V6.5ZM0 18.5V0.5H18V18.5H0ZM2 16.5H16V2.5H2V16.5Z'
          fill={color ?? 'rgba(0, 0, 0, 0.6)'}
        />
      </svg>
    </div>
  )
}
