import { css } from '@emotion/css'
import { IonButton, IonIcon, isPlatform } from '@ionic/react'
import { close } from 'ionicons/icons'
import React from 'react'
import useGetStoreUrl from '../utils/hooks/useGetStoreUrl'

const installPromptStyles = css`
  position: fixed;
  z-index: 1000;
  top: var(--ion-safe-area-top);
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  background-color: white;
  color: var(--ion-color-dark);
  padding: 16px 18px;

  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);

  .install-prompt-content {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    flex: 1;
    max-width: 400px;
    .close-btn {
      margin: 0;
      ::part(native) {
        color: var(--ion-color-medium);
        padding: 0;

        width: 32px;
        height: 32px;
      }
    }
    > span {
      font-size: 14px;
      font-weight: 500;
    }
  }

  .download-btn {
    --border-radius: 30px;
    --padding-start: 24px;
    --padding-end: 24px;
    --padding-top: 16px;
    --padding-bottom: 16px;

    font-weight: 600;
    font-size: 14px;
  }
`

const installPromptDismissedKey = 'install-prompt-dismissed'

const useInstallPromptDismissed = () => {
  const [dismissed, setDismissed] = React.useState(() => {
    const dismissed = sessionStorage.getItem(installPromptDismissedKey)
    return dismissed === 'true'
  })

  const dismiss = () => {
    setDismissed(true)
    localStorage.setItem(installPromptDismissedKey, 'true')
  }

  return { dismissed, dismiss }
}

const InstallPrompt = () => {
  const isMobileWeb = isPlatform('mobileweb')
  const { dismissed, dismiss } = useInstallPromptDismissed()
  const url = useGetStoreUrl()

  if (!isMobileWeb || dismissed) {
    return null
  }

  return (
    <div className={installPromptStyles}>
      <div className='install-prompt-content'>
        <IonButton className='close-btn' fill='clear' color='dark' onClick={dismiss}>
          <IonIcon slot='icon-only' icon={close} />
        </IonButton>
        <img src='/assets/images/goji_app.svg' alt='Goji App' />
        <span>Finding plans is easier and faster on the Goji&nbsp;app</span>
      </div>
      <IonButton className='download-btn' fill='solid' onClick={() => window.open(url, '_blank')}>
        OPEN
      </IonButton>
    </div>
  )
}

export default InstallPrompt
