import React from 'react'
import { PlanOverlayUnsureStyles } from '../PlanSwipe.styles'
import OverlayBlock, { PlanOverlayType } from './OverlayBlock'

const PlanOverlayUnsure = React.forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div className={PlanOverlayUnsureStyles} ref={ref}>
      <OverlayBlock type={PlanOverlayType.UNSURE} />
      <span>I don’t know...</span>
    </div>
  )
})

export default PlanOverlayUnsure
