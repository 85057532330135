import { css, cx } from '@emotion/css'
import React from 'react'
import { ChatStep } from '../types/ChatStep'
import { Suggestion } from '../types/Suggestion'
import SuggestionComponent from './SuggestionComponent'
import Message from './Message'

const ChatStepComponentStyles = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  align-items: center;
  height: 100%;

  .message {
    position: relative;
  }

  .subtitle {
    padding-top: 16px;
    color: var(--ion-color-medium);
    font-size: 14px;
  }

  .suggestions {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    &.large-suggestions {
      justify-content: center;
    }
  }
`

export interface ChatStepComponentProps {
  status: 'active' | 'loading' | 'answered'
  step: ChatStep
  onSuggestionSelected: (suggestion: Suggestion) => void
  suggestionSlot?: React.ReactNode
}
function ChatStepComponent(props: ChatStepComponentProps) {
  return (
    <article className={ChatStepComponentStyles}>
      {props.step.question.length === 0 ? null : (
        <Message from={'bot'} className={'message'}>
          {props.step.question.map((questionPiece, idx) => {
            return (
              <span key={`question-${idx}`} style={{ color: questionPiece.color }}>
                {questionPiece.text}
              </span>
            )
          })}
          {props.step.subtitle ? <div className='subtitle'>{props.step.subtitle}</div> : null}
        </Message>
      )}

      {props.status === 'active' ? (
        <div
          className={cx(
            'suggestions',
            props.step.suggestions.every((s) => 'size' in s && s.size === 'large') ? 'large-suggestions' : null
          )}
        >
          {props.step.suggestions.map((suggestion, idx) => {
            return (
              <SuggestionComponent
                onSuggestionSelected={props.onSuggestionSelected}
                key={idx}
                suggestion={suggestion}
              />
            )
          })}
          {props.suggestionSlot}
        </div>
      ) : null}
    </article>
  )
}

export default ChatStepComponent
