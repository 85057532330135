import { StateCreator } from 'zustand'

export interface Carrier {
  id: string
  name: string
  logo_url?: string
  amplitude_name: string
  es_id?: string
  available_nationwide?: boolean
  carrier_availability?: string[]
  has_data: boolean
  network_operators?: string[]
  is_visible_on_map?: boolean
}

export interface CarrierApiResult {
  logo_name?: string
  es_id?: string
  name: string
  amplitude_name: string
  id: string
}

export type CarrierSelectorState = {
  userCarrierId?: string
}

export type CarrierSelectorActions = {
  setCarrierId: (carrierId: string | undefined) => void
}

export const carrierSelectorInitialState: CarrierSelectorState = {
  userCarrierId: undefined
}

const createCarrierSelectorSlice: StateCreator<
  CarrierSelectorState & CarrierSelectorActions,
  [],
  [],
  CarrierSelectorState & CarrierSelectorActions
> = (set, get) => ({
  ...carrierSelectorInitialState,
  setCarrierId: (carrierId: string | undefined) => {
    set(() => ({ userCarrierId: carrierId }))
  }
})

export default createCarrierSelectorSlice
