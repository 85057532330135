import * as React from 'react'
import { css, cx } from '@emotion/css'
import { ResetMargin } from '../../../utils/styles/MarginUtils.styles'
import { HomePageTitleStyles } from '../HomePage.styles'
import { TopPicksStyles, TOP_PICKS_HEIGHT } from './TopPicksStyles'
import { withSuspense } from '../../main-navigation/withSuspense'
import { TopPickBanner } from './TopPickBanner'
import { MOBILE_BREAKPOINT } from '../../../utils/hooks/useWindowSize'

const LoadingBannerContainerStyles = css`
  min-height: ${TOP_PICKS_HEIGHT}px;
  @media screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    margin-bottom: 63.6px;
  }
`
const TopPickSlides = withSuspense(
  React.lazy(() => import('./TopPickSlides')),
  <div className={LoadingBannerContainerStyles}>
    <TopPickBanner topPick={{} as any} loading />
  </div>
)

const barryImageBackground = '/assets/images/barry/barry_walk.svg'

export function TopPicks() {
  return (
    <div className={TopPicksStyles}>
      <div className='barry-image-background'>
        <img src={barryImageBackground} alt='Barry walking' />
      </div>
      <h3 className={cx(ResetMargin, HomePageTitleStyles, 'ion-margin-bottom', 'top-picks-title')}>Our top picks</h3>
      <TopPickSlides />
    </div>
  )
}
