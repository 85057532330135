import * as React from 'react'
import { IonContent } from '@ionic/react'

const ContentRender: React.ForwardRefRenderFunction<
  HTMLIonContentElement,
  React.ComponentPropsWithoutRef<typeof IonContent>
> = ({ children, ...props }, ref) => {
  return (
    <IonContent ref={ref} {...props}>
      <div className='custom-width-limiter'>{children}</div>
    </IonContent>
  )
}

const Content = React.forwardRef(ContentRender)

export default Content
