import { css } from '@emotion/css'
import { IonIcon, IonRouterLink } from '@ionic/react'
import shallow from 'zustand/shallow'
import { AddressesSearchbar } from '../../pages/network-quality/AddressSearchbar'
import { MainNavigationPaths, RoutePaths } from '../../paths'
import { useGeneralStore } from '../../store'
import { Address } from '../../store/address'
import { BaseHeader } from '../Header/BaseHeader'
import { useUnsavedAddresses } from './hooks/useUnsavedAddresses'

export function EditIcon(props?: { className?: string }): JSX.Element {
  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
      <g clipPath='url(#clip0_5050_127777)'>
        <path
          d='M10.545 6.765L11.235 7.455L4.44 14.25H3.75V13.56L10.545 6.765ZM13.245 2.25C13.0575 2.25 12.8625 2.325 12.72 2.4675L11.3475 3.84L14.16 6.6525L15.5325 5.28C15.825 4.9875 15.825 4.515 15.5325 4.2225L13.7775 2.4675C13.6275 2.3175 13.44 2.25 13.245 2.25ZM10.545 4.6425L2.25 12.9375V15.75H5.0625L13.3575 7.455L10.545 4.6425Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_5050_127777'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

const MapAddressesHeaderStyles = css`
  z-index: 2;
  display: flex;
  gap: 24px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;

  ion-toolbar ion-header {
    border-bottom-left-radius: 10px !important;
  }

  ion-searchbar {
    &,
    input {
      font-size: 14px !important;
      height: 36px !important;
      min-height: 36px;
    }
  }
  font-size: 14px;

  .edit-locations {
    color: var(--ion-color-dark);
    // min-width: 118px;

    .edit-icon {
      width: 24px;
      height: 24px;
    }
  }
  color: #9e9e9e;

  .location-button {
    height: 18px;
    width: 18px;
  }

  .center-icon {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .searchbar-wrapper {
    width: 100%;
    & > * {
      width: 100%;
    }
  }
`

export function MapAddressesHeader() {
  const [addresses, setSelectedAddress] = useGeneralStore(
    (state) => [state.addresses, state.setSelectedAddress],
    shallow
  )
  const { setLocalAddress: setAddressInBuffer } = useUnsavedAddresses()

  function onAddressSelected(address: Address) {
    setAddressInBuffer(address, undefined)
    setSelectedAddress(address)
  }

  return (
    <BaseHeader className={MapAddressesHeaderStyles} border={true}>
      <div className='searchbar-wrapper'>
        <AddressesSearchbar
          addresses={addresses}
          onAddressSelected={onAddressSelected}
          searchBarPlaceholder='Search location'
          onMap
        />
      </div>
      <IonRouterLink
        className='edit-locations'
        routerLink={RoutePaths.addressBook + '?back-to=' + MainNavigationPaths.map}
      >
        <IonIcon className='edit-icon' src={'/assets/icons/address-book.svg'} />
      </IonRouterLink>
    </BaseHeader>
  )
}
