import { PlanPreferencesV2 } from '../../../services/planService/getRecommendedPlans'
import { DATA_UNLIMITED } from '../../../services/planService/Preferences'
import { ContractType, ReasonForSwitch } from '../../../services/wizard/reason-for-switch'
import { ChatFlow } from '../ChatFlow'

function getMinData(chatAnswers: Record<keyof typeof ChatFlow, any>): number {
  return chatAnswers.all__data_allowance === 'Unlimited' ? DATA_UNLIMITED : chatAnswers.all__data_allowance
}

function getCurrentPlanFlowPreferences(chatAnswers: Record<keyof typeof ChatFlow, any>): PlanPreferencesV2 {
  return {
    reasonsForSwitch: [...(chatAnswers.current_plan__unhappy_reasons ?? []), ...(chatAnswers.all__plan_needs ?? [])],
    minData: getMinData(chatAnswers),
    maxPrice: chatAnswers.current_plan__unhappy_reasons?.includes(ReasonForSwitch.Cost)
      ? chatAnswers.current_plan__target_budget
      : undefined,
    numLines: chatAnswers.current_plan__num_lines,
    contractType: chatAnswers.current_plan__switch_to_prepaid === 'No' ? ContractType.Postpaid : ContractType.Both,
    carrierId: chatAnswers.current_plan__current_carrier?.carrierId
  }
}

function getNewPlanFlowPreferences(chatAnswers: Record<keyof typeof ChatFlow, any>): PlanPreferencesV2 {
  return {
    reasonsForSwitch: chatAnswers.all__plan_needs,
    minData: getMinData(chatAnswers),
    maxPrice: (chatAnswers as any).new_plan__max_budget,
    numLines: (chatAnswers as any).new_plan__num_lines,
    contractType:
      (chatAnswers as any).new_plan__save_with_prepaid === ContractType.Postpaid
        ? ContractType.Postpaid
        : ContractType.Both,
    carrierId: undefined
  }
}

export function chatAnswersToPreference(chatAnswers: Record<keyof typeof ChatFlow, any>): PlanPreferencesV2 {
  // keeping for backwards compatibility
  if ((chatAnswers as any).all_new_or_replacement === 'New') {
    return getNewPlanFlowPreferences(chatAnswers)
  } else {
    return getCurrentPlanFlowPreferences(chatAnswers)
  }
}
