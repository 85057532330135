import { css, cx } from '@emotion/css'
import { IonRouterLink } from '@ionic/react'
import * as React from 'react'

const CloseButtonStyles = css`
  color: inherit;
  &[role='button'] {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const CloseButton: React.FC<{ onClose?: React.MouseEventHandler<Element>; className?: string; routerLink?: string }> = (
  props
) => {
  const svg = (
    <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'>
      <path
        d='M11 13.3333L2.83333 21.5C2.52778 21.8055 2.13889 21.9583 1.66667 21.9583C1.19444 21.9583 0.805555 21.8055 0.499999 21.5C0.194444 21.1944 0.041666 20.8055 0.041666 20.3333C0.041666 19.8611 0.194444 19.4722 0.499999 19.1666L8.66667 11L0.499999 2.83329C0.194444 2.52774 0.041666 2.13885 0.041666 1.66663C0.041666 1.1944 0.194444 0.805515 0.499999 0.499959C0.805555 0.194404 1.19444 0.041626 1.66667 0.041626C2.13889 0.041626 2.52778 0.194404 2.83333 0.499959L11 8.66663L19.1667 0.499959C19.4722 0.194404 19.8611 0.041626 20.3333 0.041626C20.8056 0.041626 21.1944 0.194404 21.5 0.499959C21.8056 0.805515 21.9583 1.1944 21.9583 1.66663C21.9583 2.13885 21.8056 2.52774 21.5 2.83329L13.3333 11L21.5 19.1666C21.8056 19.4722 21.9583 19.8611 21.9583 20.3333C21.9583 20.8055 21.8056 21.1944 21.5 21.5C21.1944 21.8055 20.8056 21.9583 20.3333 21.9583C19.8611 21.9583 19.4722 21.8055 19.1667 21.5L11 13.3333Z'
        fill='currentColor'
      />
    </svg>
  )
  const className = cx('close-button', CloseButtonStyles, props.className)

  if (props.routerLink) {
    return (
      <IonRouterLink className={className} routerLink={props.routerLink} onClick={props.onClose}>
        {svg}
      </IonRouterLink>
    )
  }
  return (
    <div role='button' onClick={props.onClose} className={className}>
      {svg}
    </div>
  )
}

export default CloseButton
