import { PredefinedChatKeyRecord, PredefinedChatMessageRecord } from '../ChatFlow'
import { ServerResponse } from '../ServerResponse'
import { BotMessage, IChatFlow, MessageType, PredefinedChatStep } from '../types/ChatStep'
import { FreeChatUserMessageRecord } from '../types/free-chat.types'

export function isChatKeyRecord<T extends IChatFlow>(
  chatFlow: T,
  record: PredefinedChatMessageRecord<T>
): record is PredefinedChatKeyRecord<T> {
  return 'key' in record && record.key in chatFlow
}

export function isUserMessageRecord<T extends IChatFlow>(
  record: PredefinedChatMessageRecord<T>
): record is FreeChatUserMessageRecord {
  return !('key' in record) && record.message.type !== MessageType.STEP && record.message.from === 'user'
}

export function getRecordMessage<T extends IChatFlow>(chatFlow: T, record: PredefinedChatMessageRecord<T>) {
  if ('message' in record) {
    return record.message
  }
  return chatFlow[record.key]
}

function getNextPathKey(serverResponse: ServerResponse | undefined, message: BotMessage<PredefinedChatStep>) {
  if (message.type === MessageType.TEXT || message.type === MessageType.TRANSITION) {
    return message.nextMessageKey
  }

  if (serverResponse === undefined) {
    return message.nextPaths.$default
  }

  return message.nextPaths[serverResponse.parsedAnswer] ?? message.nextPaths.$default
}

export function getNextStep<T extends IChatFlow>(
  chatFlow: T,
  chatStep: BotMessage<PredefinedChatStep>,
  serverResponse: ServerResponse | undefined
): PredefinedChatKeyRecord<T> | undefined {
  const nextPathKey = getNextPathKey(serverResponse, chatStep)

  if (nextPathKey === undefined) {
    return undefined
  }

  const message = chatFlow[nextPathKey]

  if (!message) {
    throw new Error(
      'no message found for next path ' + nextPathKey + '. Current step: ' + JSON.stringify(chatStep, null, 2)
    )
  }

  return { key: nextPathKey }
}
