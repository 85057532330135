import debounce from 'lodash/debounce'
import { ProfileService } from '../../../services/backend-sync/profile.service'
import chatService from '../../../services/chat/Chat.service'
import { IChatFlow } from '../types/ChatStep'
import { PredefinedChatStorage, ChatStorageSource } from '../types/ChatStorageSource'

export function getApiSource<T extends IChatFlow>(): ChatStorageSource<T> {
  return {
    staleTime: Infinity,
    getStorage: async () => {
      const profile = await ProfileService.get().getBackendUser()
      return profile.chat_state as PredefinedChatStorage<T>
    },
    setStorage: debounce(
      async (value) => {
        await chatService.uploadChatStorage(value)
      },
      100,
      { trailing: true, leading: false }
    ) as (value: PredefinedChatStorage<T> | undefined) => Promise<void>
  }
}
