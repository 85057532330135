import { css, cx } from '@emotion/css'
import { ResetMargin } from '../../../utils/styles/MarginUtils.styles'
import { TransitionMessage } from '../types/ChatStep'
import { IonImg } from '@ionic/react'
import React from 'react'

type TransitionMessageProps = {
  message: TransitionMessage
}

const TransitionMessageStyles = css`
  text-align: center;
  display: grid;
  justify-items: center;
  margin-top: 12.67px;
  margin-bottom: 24px;

  .transition-title {
    font-size: 20px;
    font-weight: 700;
    padding-top: 10.67px;
  }

  .transition-image {
    width: 50px;
    height: 50px;

    &.loading {
      background-color: var(--ion-color-primary);
      border-radius: 50%;
    }
  }

  .subtext {
    color: var(--ion-color-medium);
    font-size: 14px;
    font-weight: 500;
    padding-top: 2px;
  }
`

export function TransitionMessageComponent(props: TransitionMessageProps) {
  return (
    <div className={TransitionMessageStyles} role='listitem'>
      <IonImg
        className={'transition-image loading'}
        src={props.message.imageUrl}
        alt={props.message.imageAlt}
        onIonImgDidLoad={(e) => e.target.classList.remove('loading')}
      />
      <h3 className={cx(ResetMargin, 'transition-title')}>{props.message.text}</h3>
      <p className={cx(ResetMargin, 'subtext')}>{props.message.subtext}</p>
    </div>
  )
}
