export const NetworkType = {
  '4G': 0,
  '5G': 1
} as const

export type ValueOf<T> = T[keyof T]

export type INetworkType = typeof NetworkType
export type NetworkTypeValue = ValueOf<INetworkType>

export const BackendNetworkTypes = {
  '2g': 1,
  '3g': 2,
  '4g': 3,
  '5g-sa': 4,
  '5g-nsa': 5
}

export const getNetworkTypeId = <K extends keyof INetworkType>(networkType: INetworkType[K]): number => {
  if (networkType === NetworkType['4G']) {
    return BackendNetworkTypes['4g']
  }

  // 5g
  return BackendNetworkTypes['5g-sa'] /*BackendNetworkTypes['5g-nsa']*/
}
