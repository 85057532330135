import { StateCreator } from 'zustand'
import { IChatFlow } from '../pages/llm/types/ChatStep'
import { PredefinedChatStorage } from '../pages/llm/types/ChatStorageSource'
import { ValueOf } from '../services/NetworkType.service'
import { UnlimitedDataPreference } from '../services/planService/Plan'

export type QuestionsState = {
  phoneLinesNeeded: number
  useUnlimitedDataPlan: ValueOf<typeof UnlimitedDataPreference> | undefined
  targetBudget: number | undefined
  chatStorage: PredefinedChatStorage<IChatFlow> | undefined
}

export type QuestionsActions = {
  setPhoneLinesNeeded: (value: number) => void
  setTargetBudget: (value: number | undefined) => void
  setUseUnlimitedDataPlan: (value: ValueOf<typeof UnlimitedDataPreference> | undefined) => void
}

export const questionsInitialState: QuestionsState = {
  phoneLinesNeeded: 1,
  targetBudget: undefined,
  useUnlimitedDataPlan: undefined,
  chatStorage: undefined
}

const createQuestionsSlice: StateCreator<
  QuestionsState & QuestionsActions,
  [],
  [],
  QuestionsState & QuestionsActions
> = (set, get) => ({
  ...questionsInitialState,
  setUseUnlimitedDataPlan: (useUnlimitedDataPlan: ValueOf<typeof UnlimitedDataPreference> | undefined) => {
    set({
      useUnlimitedDataPlan
    })
  },
  setPhoneLinesNeeded: (phoneLinesNeeded: number) => {
    set({
      phoneLinesNeeded
    })
  },
  setTargetBudget: (targetBudget: number | undefined) => {
    set({
      targetBudget
    })
  }
})

export default createQuestionsSlice
