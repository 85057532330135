import { PluginListenerHandle, registerPlugin } from '@capacitor/core'

export interface SignalStrengthPluginMethods {
  checkSignalStrengthPermissions(): Promise<{ isGranted: boolean }>
  shouldShowPermissionRationale(): Promise<{ shouldShowRationale: boolean }>
  goToSettings(): Promise<{ isGranted: boolean }>
  requestSignalStrengthPermissions(): Promise<void>
  listenForSignalStrengthChanges(): void
  unlistenForSignalStrengthChanges(): void
  addListener(
    eventName: 'signalStrengthChanged',
    listenerFunc: (signalStrengthInfo: SignalStrengthChangedEvent) => void
  ): Promise<PluginListenerHandle> & PluginListenerHandle
}

export interface SignalStrengthChangedEvent {
  signalStrength: number
}

export function registerSignalStrengthPlugin() {
  return registerPlugin<SignalStrengthPluginMethods>('SignalStrengthPlugin')
}
