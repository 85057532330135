import React from 'react'
import { cx } from '@emotion/css'
import { useHistory } from 'react-router'
import { IonButton, IonHeader, IonImg, IonRouterLink, IonRow } from '@ionic/react';
import { DesktopHeaderStyles, HeaderLinkStyles } from './DesktopHeader.styles'
import useAuthStore from '../../../store/auth'
import { MainNavigationPaths } from '../../../paths'

interface HeaderLinkProps {
  name: string
  path: string
}
const HeaderLink: React.FC<HeaderLinkProps> = ({ name, path }) => {
  const history = useHistory()
  const isCurrentPath = history.location.pathname.includes(path)

  return (
    <IonRouterLink className={cx(HeaderLinkStyles, isCurrentPath ? 'active' : '')} routerLink={path}>
      {name}
    </IonRouterLink>
  )
}

export const DesktopHeader = (props: { className?: string }) => {
  const [currentUser] = useAuthStore((state) => [state.currentUser])
  const isUserLoggedIn = Boolean(currentUser?.token)

  return (
    <>
      <IonHeader
        className={cx(DesktopHeaderStyles, 'ion-justify-content-between ion-align-items-center', props.className)}
      >
        <IonRow className='ion-align-items-center'>
          <IonRouterLink routerLink={MainNavigationPaths.home}>
            <IonImg src='/assets/images/goji.svg' alt='Goji logo' />
          </IonRouterLink>
          <HeaderLink name='Plans' path={MainNavigationPaths.plans} />
          <HeaderLink name='Coverage Map' path={MainNavigationPaths.map} />
          <HeaderLink name='Network Meter' path={MainNavigationPaths.meter} />
        </IonRow>
        <IonRow className='ion-align-items-center'>
          {isUserLoggedIn ? (
            <HeaderLink name='Profile' path={MainNavigationPaths.profile} />
          ) : (
            <HeaderLink name='Sign In' path={MainNavigationPaths.signIn} />
          )}
          <IonButton className='btn-download' shape='round' routerLink={MainNavigationPaths.meter}>
            Download Goji Mobile
          </IonButton>
        </IonRow>
      </IonHeader>
    </>
  )
}
