import { isPlatform } from '@ionic/core'
import create from 'zustand'
import { StateCreator } from 'zustand'

const isMobile = isPlatform('ios') || isPlatform('android')

export type NavigationState = {
  appExperience: 'mobile' | 'desktop'
}

export type NavigationActions = {}

const createNavigationSlice: StateCreator<
  NavigationState & NavigationActions,
  [],
  [],
  NavigationState & NavigationActions
> = (_set, _get) => ({
  appExperience: isMobile ? 'mobile' : 'desktop'
})

const useNavigationStore = create(createNavigationSlice)

export default useNavigationStore
