import { normalizeNetworkPerformance } from '../../utils/signalStrength'
import { Plan } from './Plan'
import { DATA_UNLIMITED } from './Preferences'
import { ContractType, ReasonForSwitch } from '../wizard/reason-for-switch'

export type SortedPlan = Pick<
  Plan,
  | 'planId'
  | 'networkPerformance4G'
  | 'networkPerformance5G'
  | 'costPerLineScore'
  | 'dataAllowanceScore'
  | 'perksScore'
  | 'popularityScore'
  | 'telcoTypeScore'
  | 'price'
  | 'data'
  | 'isDataUnlimited'
  | 'carrierId'
  | 'costPerLine'
  | 'contractType'
  | 'numLines'
>

export type PlanPreferences = {
  reasonsForSwitch: ReasonForSwitch[]
  numLines?: number
  maxPrice?: number
  minData?: number
}

export type PlanPreferencesV2 = PlanPreferences & {
  contractType: ContractType
  carrierId?: string
}

export function getRecommendedPlansV2<T extends SortedPlan>(
  plans: T[],
  preferenceParam: PlanPreferencesV2 | undefined,
  passedPlans?: Set<string>
) {
  const preference: PlanPreferencesV2 = preferenceParam ?? { reasonsForSwitch: [], contractType: ContractType.Both }
  const customWeights = getCustomWeights(preference)

  const weightedPlans = plans.reduce((acc, plan) => {
    if (passedPlans?.has(plan.planId)) {
      return acc
    }

    // This gives us the opportunity to show warnings to the user with the reasons why plans are not recommended
    // Could be a good idea to show them in case the list is empty
    const unfitReasons = getUnfitReasons(plan, preference)

    if (unfitReasons.length === 0) {
      acc.push({
        plan,
        customOverallScore: calculateCustomOverallScore(plan, customWeights)
      })
    }
    return acc
  }, [] as { plan: T; customOverallScore: number }[])

  weightedPlans.sort((a, b) => b.customOverallScore - a.customOverallScore)

  return weightedPlans.map((weightedPlan) => weightedPlan.plan)
}

export function getRecommendedPlans<T extends SortedPlan>(plans: T[], preference: PlanPreferences): T[] {
  const customWeights = getCustomWeights(preference)

  let filteredPlans = plans.filter((plan) => withinUserPreferences(plan, preference))

  filteredPlans.sort((a, b) => {
    // TODO: could compare 4g to 5g score. Needs other implementation
    const aCustomOverallScore = calculateCustomOverallScore(a, customWeights)
    const bCustomOverallScore = calculateCustomOverallScore(b, customWeights)

    return aCustomOverallScore > bCustomOverallScore ? -1 : 1
  })

  return filteredPlans
}

export function capFirstPlansNoRepeatCarrier<T extends SortedPlan>(plans: T[], nPlans: number = 10): T[] {
  const carrierCount: Record<string, number> = {}
  const cappedPlans: T[] = []

  for (const plan of plans) {
    const lastCarrierCount = carrierCount[plan.carrierId] ?? 0
    if (lastCarrierCount < 2) {
      cappedPlans.push(plan)
    }

    carrierCount[plan.carrierId] = lastCarrierCount + 1
    if (cappedPlans.length >= nPlans) {
      break
    }
  }

  return cappedPlans
}

function getCustomWeights(preferences: PlanPreferencesV2 | PlanPreferences): ScoreWeightsType {
  const { reasonsForSwitch } = preferences

  const weights: ScoreWeightsType = { ...ScoreWeights }
  if (reasonsForSwitch.includes(ReasonForSwitch.Cost)) {
    weights.CostPerLine = 0.5
  }

  if (reasonsForSwitch.includes(ReasonForSwitch.Data)) {
    weights.DataAllowance = 0.5
  }

  if (reasonsForSwitch.includes(ReasonForSwitch.Network)) {
    weights.NetworkPerformance = 0.5
  }

  if ('contractType' in preferences && preferences.contractType === ContractType.Postpaid) {
    weights.PostpaidBoost = 1
  }

  return weights
}

const ScoreWeights = {
  CostPerLine: 0.3,
  DataAllowance: 0.2,
  NationwideSignalCount: 0.3,
  NetworkPerformance: 0.4,
  Popularity: 0.2,
  Perks: 0.1,
  PostpaidBoost: 0
} as const
type ScoreWeightsType = Record<keyof typeof ScoreWeights, number>

function calculateCustomOverallScore<T extends SortedPlan>(plan: T, weights: ScoreWeightsType = ScoreWeights) {
  const networkPerformanceScore = plan.networkPerformance4G ? normalizeNetworkPerformance(plan.networkPerformance4G) : 0

  return (
    (plan.telcoTypeScore ?? 0) * (plan.networkPerformance4G ? 0.2 : 0) +
    weights.CostPerLine * (plan.costPerLineScore ?? 0) +
    weights.DataAllowance * (plan.dataAllowanceScore ?? 0) +
    weights.Popularity * (plan.popularityScore ?? 0) +
    weights.Perks * (plan.perksScore ?? 0) +
    weights.NetworkPerformance * networkPerformanceScore +
    weights.PostpaidBoost * (plan.contractType === ContractType.Postpaid ? 1 : 0)
  )
}

type UnfitReason = 'price' | 'data'
export function getUnfitReasons<T extends SortedPlan>(plan: T, preference: PlanPreferencesV2): UnfitReason[] {
  const unfitReasons: UnfitReason[] = []
  if (preference.maxPrice && plan.costPerLine > preference.maxPrice) {
    unfitReasons.push('price')
  }

  const planData = plan.data ?? (preference.minData === DATA_UNLIMITED ? 0 : undefined)
  if (preference.minData !== undefined && planData !== undefined && planData < preference.minData) {
    unfitReasons.push('data')
  }

  return unfitReasons
}

export function withinUserPreferences<T extends SortedPlan>(
  plan: T,
  preferences: PlanPreferences | PlanPreferencesV2
): boolean {
  if (preferences.maxPrice && plan.costPerLine > preferences.maxPrice / (preferences.numLines ?? 1)) {
    return false
  }

  const planData = plan.data ?? (preferences.minData === DATA_UNLIMITED ? 0 : undefined)
  if (preferences.minData !== undefined && planData !== undefined && planData < preferences.minData) {
    return false
  }

  return true
}
