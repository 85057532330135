import * as React from 'react'
import { css } from '@emotion/css'
import { range } from 'lodash'

const NetworkPerformanceDiagramStyles = css`
  --square-width: 18px;
  --square-height: 16px;
  --square-gap: 2px;

  display: flex;
  justify-content: end;
  gap: var(--square-gap);
  justify-content: flex-end;

  .square {
    display: block;
    height: var(--square-height);
    width: var(--square-width);

    &:first-of-type {
      border-radius: calc(var(--square-height) / 2) 0 0 calc(var(--square-height) / 2);
    }
    &:last-of-type {
      border-radius: 0 calc(var(--square-height) / 2) calc(var(--square-height) / 2) 0;
    }
  }

  .gray {
    background-color: lightgray;
  }
  .red {
    background-color: #ff4f58;
  }
  .orange {
    background-color: #fbc02d;
  }
  .green {
    background-color: var(--ion-color-primary);
  }
`

const NetworkPerformanceDiagram: React.FC<{ performance: number }> = ({ performance }) => {
  let squareColors =
    performance <= 0
      ? []
      : performance < 2.5
      ? ['red']
      : performance < 5
      ? ['orange', 'orange']
      : performance < 7.5
      ? ['green', 'green', 'green']
      : ['green', 'green', 'green', 'green']

  return (
    <div className={NetworkPerformanceDiagramStyles}>
      {range(4).map((_color, index) => (
        <div key={index} className={`square ${squareColors[index] ?? 'gray'}`}></div>
      ))}
    </div>
  )
}

export default NetworkPerformanceDiagram
