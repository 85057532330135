import React from 'react'

export function useFakeLoading(timeout = 1000) {
  const timeoutRef = React.useRef<NodeJS.Timeout>()

  const [fakeLoading, setFakeLoading] = React.useState(false)

  const triggerFakeLoading = React.useCallback(() => {
    setFakeLoading(true)

    return new Promise((resolve) => {
      timeoutRef.current = setTimeout(() => {
        resolve(undefined)
        setFakeLoading(false)
      }, timeout)
    })
  }, [setFakeLoading, timeout])

  const cancelFakeLoading = React.useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      setFakeLoading(false)
    }
  }, [setFakeLoading])

  return { fakeLoading, triggerFakeLoading, cancelFakeLoading }
}
