import { css } from '@emotion/css'

export const CarrierInfoStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const ComparePlansContainerStyles = css`
  --min-height: 100%;
  --min-width: 100%;
  ion-header {
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.55);
    padding-bottom: 8.5px;
    padding-top: 8.5px;
  }
  ion-content {
    --offset-top: 0px !important;
  }
  ion-button.close-btn.button-has-icon-only.button-clear {
    --color: var(--ion-color-primary);
    --border-radius: 5px;
  }
  ion-button.close-btn::part(native) {
    background: var(--ion-color-primary-tint) !important;
  }
  ion-title {
    font-family: Aktifo-A;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    padding: 0;
  }

  ion-grid {
    margin-top: 14px;
    border-left: solid 1px var(--ion-color-secondary-shade);
    display: grid;
  }

  ion-grid ion-row:first-child ion-col {
    padding-top: 18px;
  }

  ion-row.sticky-header {
    border-top: solid 1px var(--ion-color-secondary-shade);
    position: sticky;
    top: 0;
    will-change: transform;
    z-index: 100;
  }

  ion-row.sticky-header ion-label {
    font-weight: 600;
  }

  ion-row.sticky-header ion-img {
    width: fit-content;
    margin-bottom: 2px;
  }

  ion-row.sticky-header ion-text {
    color: #151515;
    font-family: Aktifo-A;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }

  ion-row.sticky-header a {
    font-family: Aktifo-A;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: none;
    padding: 4px 0;
    display: inline-block;
  }

  ion-row:not(.sticky) ion-col:first-child {
    position: sticky;
    left: 0;
    will-change: transform;
    z-index: 99;
  }

  ion-row.border-bottom {
    border-bottom: solid 1px var(--ion-color-secondary-shade);
  }

  ion-col {
    border-right: solid 1px var(--ion-color-secondary-shade);
    background-color: var(--ion-color-primary-contrast);
    padding: 8px;
  }

  ion-col.dark {
    background-color: var(--ion-color-secondary-tint);
    color: var(--ion-color-medium);
  }

  ion-col.highlight {
    background-color: var(--goji-color-secondary-blue);
  }

  ion-row.addons ion-img {
    width: 24px;
    float: left;
    margin-right: 8px;
  }
  ion-row.sticky {
    height: 36px;
    position: relative;
    border-bottom: none;
  }
  ion-row.sticky ion-col:first-child {
    position: fixed;
    left: 0px;
    width: 100%;
    border-right: solid 1px var(--ion-color-secondary-shade);
    z-index: 98;
  }

  ion-row.sticky + ion-row {
    border-top: solid 1px var(--ion-color-secondary-shade);
  }

  ion-row:not(.sticky) {
    flex-wrap: nowrap;
  }

  ion-row:not(.sticky) ion-col:first-child {
    min-width: 120px !important;
  }
  ion-row:not(.sticky) ion-col {
    min-width: 148px !important;
  }

  ion-row.coverage .coverage-bar-container {
    min-width: 16px;
    width: 100%;
    height: 8px;
    margin: 7px 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .smaller-font {
    font-size: 14px !important;
  }

  .see-details {
    background: none;
    color: var(--ion-color-primary);
    width: fit-content;
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
  }

  @media screen and (min-width: 768px) {
    ion-content ion-grid,
    ion-content ion-grid ion-col {
      max-width: 1165px;
    }
    .see-details {
      height: 24px;
      letter-spacing: normal;
      color: var(--ion-color-primary);
    }
  }
`
