import { css } from '@emotion/css'

export const SeparatorStyles = (color: string) => {
  return css`
    border-top: 2px solid ${color};
    z-index: 2;
    position: absolute;
    left: 0;
    width: 100%;
  `
}

export const SeparatorContainerStyles = css`
  z-index: 20;
  position: relative;
`
