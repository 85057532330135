import { StateCreator } from 'zustand'

export type NetworkMeterResultsState = {
  hasNetworkMeterResults: boolean
  latency: number
  jitter: number
  uploadSpeed: number
  downloadSpeed: number
  networkTested: string
}

export type NetworkMeterResultsActions = {
  setHasNetworkMeterResults: (hasResults: boolean) => void
  setLatency: (latency: number) => void
  setJitter: (jitter: number) => void
  setUploadSpeed: (uploadSpeed: number) => void
  setDownloadSpeed: (downloadSpeed: number) => void
  setNetworkTested: (networkTested: string) => void
}

export const networkMeterResultsInitialState: NetworkMeterResultsState = {
  hasNetworkMeterResults: false,
  latency: 0,
  jitter: 0,
  uploadSpeed: 0,
  downloadSpeed: 0,
  networkTested: 'Unknown'
}

const createNetworkMeterResultsSlice: StateCreator<
  NetworkMeterResultsState & NetworkMeterResultsActions,
  [],
  [],
  NetworkMeterResultsState & NetworkMeterResultsActions
> = (set, get) => ({
  ...networkMeterResultsInitialState,

  setHasNetworkMeterResults: (hasNetworkMeterResults: boolean) => {
    set(() => ({ hasNetworkMeterResults }))
  },

  setLatency: (latency: number) => {
    set(() => ({ latency }))
  },
  setJitter: (jitter: number) => {
    set(() => ({ jitter }))
  },
  setUploadSpeed: (uploadSpeed: number) => {
    set(() => ({ uploadSpeed }))
  },
  setDownloadSpeed: (downloadSpeed: number) => {
    set(() => ({ downloadSpeed }))
  },
  setNetworkTested: (networkTested: string) => {
    set(() => ({ networkTested }))
  }
})

export default createNetworkMeterResultsSlice
