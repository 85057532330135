import { useQuery } from 'react-query'
import useAddressPredictions from '../../utils/hooks/usePredictions'
import { getAddressFromPlaceId, predictionToAddress } from '../../utils/Address'
import { IonButton, IonSpinner } from '@ionic/react'
import CoverageMapCard from './CoverageMapCard'
import { CoverageMapCardBackendInput } from './CoverageMapToolHandler'
import RegionCoverageMapCard from './RegionCoverageMapCard'
import { isGeocoderType } from '../Map/useHighlightRegion'
import { useBoundStore } from '../../store'
import CoverageCard from './CoverageCard'

export type CoverageMapCardToolProps = {
  backendInput: CoverageMapCardBackendInput
}

export function CoverageMapCardToolComponent(props: CoverageMapCardToolProps) {
  const { predictions } = useAddressPredictions({
    value: props.backendInput.placeId ? '' : props.backendInput.address ?? '',
    types: []
  })
  const addresses = useBoundStore((state) => state.addresses)
  const addressQuery = useQuery({
    enabled: !!predictions.isFetched || !!props.backendInput.placeId || !!props.backendInput.knownLocation,
    queryKey: ['address-string', predictions, props.backendInput],
    keepPreviousData: true,
    queryFn: async () => {
      if (props.backendInput.knownLocation) {
        const knownAddress = addresses.find((address) => address.label === props.backendInput.knownLocation)
        if (knownAddress) {
          return { address: knownAddress, types: [] }
        }
      }
      if (props.backendInput.placeId) {
        const [address, geolocation] = await Promise.all([
          getAddressFromPlaceId(props.backendInput.placeId),
          new google.maps.Geocoder().geocode({ placeId: props.backendInput.placeId })
        ])
        if (!address) {
          return undefined
        }
        return { address: address, types: geolocation.results[0].types }
      }
      if (!predictions.data?.length) {
        return undefined
      }
      const address = { ...(await predictionToAddress(predictions.data[0])), localMemory: true }
      return { address: address, types: predictions.data[0].types }
    }
  })

  if (addressQuery.isLoading || predictions.isLoading) {
    return <IonSpinner />
  }

  if (!addressQuery.data || addressQuery.isError) {
    return (
      <div>
        Failed to load address <IonButton onClick={() => predictions.refetch()}>Try again</IonButton>
      </div>
    )
  }

  const title = props.backendInput.networkType
    ? `${props.backendInput.networkType.toUpperCase()} Coverage for ${
        addressQuery.data.address.label ?? addressQuery.data.address.addressName
      }`
    : undefined
  const requestedCarrierId =
    props.backendInput.carrierStatus === 'valid' ? props.backendInput.carrierId ?? undefined : undefined

  if (props.backendInput.wantsBestCarrier) {
    return (
      <CoverageCard
        address={addressQuery.data.address}
        technology={props.backendInput.networkType.toUpperCase() as '5G' | '4G'}
        title={title}
      />
    )
  }

  if (addressQuery.data.types.some((type) => isGeocoderType(type))) {
    return (
      <RegionCoverageMapCard
        address={addressQuery.data.address}
        requestedCarrierId={requestedCarrierId}
        technology={props.backendInput.networkType.toUpperCase() as '5G' | '4G'}
        title={title}
      />
    )
  }

  return (
    <CoverageMapCard
      address={addressQuery.data.address}
      requestCarrierId={requestedCarrierId}
      technology={props.backendInput.networkType.toUpperCase() as '5G' | '4G'}
      title={title}
    />
  )
}
