import React, { useImperativeHandle } from 'react'
import PlanSwipeCard, { PlanSwipeCardRef } from './PlanSwipeCard'
import { css } from '@emotion/css'
import { Plan } from '../../../../services/planService/Plan'
import PlanSwipeCardSkeleton from './PlanSwipeCardSkeleton'

const TinderPlansDeckStyles = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    position: absolute;
    width: 100%;
    max-width: 400px;
    height: 100%;
    transition: 1.3s ease-out;
  }
`

export type PlansSwipeDeckRef = {
  revertOne: () => void
  revertAll: () => void
  swipe: (direction: 'left' | 'right' | 'up') => void
}

interface PlansSwipeDeckProps {
  plans: Plan[]
  isLoading?: boolean
  onSwipe?: (plan: Plan, direction: 'left' | 'right' | 'up') => void
  onEnd?: () => void
}

const PlansSwipeDeck = React.forwardRef<PlansSwipeDeckRef, PlansSwipeDeckProps>(
  (props: PlansSwipeDeckProps, ref: React.Ref<PlansSwipeDeckRef>) => {
    const [alreadySwipedCardsRefs, setAlreadySwipedCardsRefs] = React.useState<PlanSwipeCardRef[]>([])
    const cardRefs = React.useRef<PlanSwipeCardRef[]>([])

    const handleSwipe = (direction: 'left' | 'right' | 'up', cardRef: PlanSwipeCardRef) => {
      setAlreadySwipedCardsRefs([...alreadySwipedCardsRefs, cardRef])
      props.onSwipe?.(cardRef.plan, direction)

      if (alreadySwipedCardsRefs.length === props.plans.length - 1) {
        props.onEnd?.()
      }
    }

    useImperativeHandle(ref, () => ({
      revertOne: () => {
        const lastSwipedCardRef = alreadySwipedCardsRefs.pop()
        if (lastSwipedCardRef) {
          lastSwipedCardRef.revert()
          setAlreadySwipedCardsRefs([...alreadySwipedCardsRefs])
        }
      },
      revertAll: () => {
        alreadySwipedCardsRefs.forEach((cardRef) => cardRef.revert())
        setAlreadySwipedCardsRefs([])
      },
      swipe: (direction: 'left' | 'right' | 'up') => {
        const cardRef = cardRefs.current[alreadySwipedCardsRefs.length]
        if (cardRef) {
          cardRef.swipe(direction)
          handleSwipe(direction, cardRef)
        }
      }
    }))

    return (
      <div className={TinderPlansDeckStyles}>
        {props.plans
          .map((plan, index) => (
            <PlanSwipeCard
              position={index - alreadySwipedCardsRefs.length}
              key={plan.planId}
              plan={plan}
              ref={(cardRef) => {
                cardRefs.current[index] = cardRef!
              }}
              onSwipe={(direction) => {
                handleSwipe(direction, cardRefs.current[index])
              }}
            />
          ))
          .reverse()}
        {props.isLoading &&
          Array.from({ length: 3 })
            .map((_, index) => <PlanSwipeCardSkeleton key={index} notFirst={index !== 0} />)
            .reverse()}
      </div>
    )
  }
)

export default PlansSwipeDeck

//
