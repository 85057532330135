import { Position } from '@capacitor/geolocation'
import { autoLabelAddress } from '../pages/network-quality/autolabel-address'
import { Address } from '../store/address'

export const getLatLngFromPosition = (position?: Position): google.maps.LatLng | undefined => {
  if (!position) return undefined
  return new google.maps.LatLng(position.coords.latitude, position.coords.longitude)
}

export const getAddressFromGeolocation = async (position: Position): Promise<Address | undefined> => {
  const location = getLatLngFromPosition(position)
  const { results } = await new google.maps.Geocoder().geocode({
    // TODO: bind to available dataset regions
    // componentRestrictions: {
    //    country: 'US'
    // },
    location
  })

  if (results?.length) {
    const resultWithStreetAddress = results.find((result) => result.types.includes('route'))
    const address = {
      placeId: resultWithStreetAddress ? resultWithStreetAddress.place_id : results[0].place_id,
      addressName: resultWithStreetAddress ? resultWithStreetAddress.formatted_address : results[0].formatted_address,
      latitude: position.coords.latitude,
      longitude: position.coords.longitude
    }

    return Promise.resolve(autoLabelAddress(address))
  }

  return Promise.resolve(undefined)
}

export const getAddressFromPlaceId = async (placeId: string): Promise<Address | undefined> => {
  const { results } = await new google.maps.Geocoder().geocode({ placeId })
  if (results?.length) {
    return Promise.resolve(
      autoLabelAddress({
        placeId,
        addressName: results[0].formatted_address,
        latitude: results[0].geometry.location.lat(),
        longitude: results[0].geometry.location.lng()
      })
    )
  }

  return Promise.resolve(undefined)
}

// TODO: implement the states in the Address type and BE to avoid calling this function
export const getStatesFromAddresses = async (addresses: Address[]): Promise<string[]> => {
  const placeIds = addresses.map((address) => address.placeId)
  const result = await Promise.all(placeIds.map((placeId) => new google.maps.Geocoder().geocode({ placeId })))

  return result
    .map((res) => {
      const state = res.results[0].address_components.find((component) =>
        component.types.includes('administrative_area_level_1')
      )
      return state?.short_name
    })
    .filter((state) => state !== undefined) as string[]
}

export const getLatLngFromPlaceId = async (placeId: string): Promise<google.maps.LatLng | undefined> => {
  const { results } = await new google.maps.Geocoder().geocode({
    // TODO: bind to available dataset regions
    // componentRestrictions: {
    //   country: 'US'
    // },
    placeId
  })

  if (results?.length) {
    return Promise.resolve(results[0].geometry.location)
  }

  return Promise.resolve(undefined)
}

export const getCenterFromAddresses = (addresses: Address[]): google.maps.LatLng => {
  const bounds = getBoundsFromAddresses(addresses)
  return bounds.getCenter()
}

export const getBoundsFromAddresses = (addresses: Address[]): google.maps.LatLngBounds => {
  const bounds = new google.maps.LatLngBounds()

  addresses.forEach((address) => {
    bounds.extend(new google.maps.LatLng(address.latitude, address.longitude))
  })

  return bounds
}

export const getBoundsFromPlaceId = async (placeId: string): Promise<google.maps.LatLngBounds | undefined> => {
  const { results } = await new google.maps.Geocoder().geocode({
    placeId
  })

  const bounds = new google.maps.LatLngBounds()

  if (results?.length) {
    bounds.extend(
      new google.maps.LatLng(
        results[0].geometry.viewport.getNorthEast().lat(),
        results[0].geometry.viewport.getNorthEast().lng()
      )
    )
    bounds.extend(
      new google.maps.LatLng(
        results[0].geometry.viewport.getSouthWest().lat(),
        results[0].geometry.viewport.getSouthWest().lng()
      )
    )
    return bounds
  }
}

export const predictionToAddress = async (prediction: google.maps.places.AutocompletePrediction): Promise<Address> => {
  const latLng = await getLatLngFromPlaceId(prediction.place_id)
  return autoLabelAddress({
    placeId: prediction.place_id,
    addressName: [prediction.structured_formatting.main_text, prediction.structured_formatting.secondary_text]
      .filter((s) => s)
      .join(', '),
    latitude: latLng?.lat() ?? 0,
    longitude: latLng?.lng() ?? 0
  })
}

export function upsertAddress(address: Address, addresses: Address[], index?: number): Address[] {
  if (index === undefined && addresses.every((addr) => addr.placeId !== address.placeId)) {
    addresses.push(address)
  } else if (index !== undefined) {
    addresses[index] = address
  }

  return addresses
}
