import { Capacitor } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'
import React from 'react'

const useKeyboard = () => {
  const [isKeyboardVisible, setIsKeyboardVisible] = React.useState(false)

  React.useEffect(() => {
    if (Capacitor.isPluginAvailable('Keyboard')) {
      const keyboardShowListener = Keyboard.addListener('keyboardDidShow', () => {
        setIsKeyboardVisible(true)
      })
      const keyboardHideListener = Keyboard.addListener('keyboardDidHide', () => {
        setIsKeyboardVisible(false)
      })

      return () => {
        keyboardShowListener.remove()
        keyboardHideListener.remove()
      }
    }
  }, [])

  return { isKeyboardVisible, openKeyboard: Keyboard.show, closeKeyboard: Keyboard.hide }
}

export default useKeyboard
