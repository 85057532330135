import { JustForYouCardFrame } from './JustForYouCard'
import {
  useNetworkTypeQuery,
  formatNetworkType
} from '../../../components/SignalMeter/MobileBrowserNetworkMeter/useNetworkType'
import { css, cx } from '@emotion/css'
import { ResetMargin } from '../../../utils/styles/MarginUtils.styles'
import { Measurements } from '../../../components/SignalMeter/MobileBrowserNetworkMeter/Measurements/Measurements'
import { useBoundStore } from '../../../store'
import shallow from 'zustand/shallow'
import { SpeedResult } from '../../../components/SignalMeter/MobileBrowserNetworkMeter/SpeedResult/SpeedResult'
import { IonRouterLink } from '@ionic/react'
import { MainNavigationPaths } from '../../../paths'

const NetworkMeterResultsCardStyles = css`
  padding: 16px;

  .card-title {
    font-size: 18px;
    line-height: 22.5px;
    margin-bottom: 16px;
  }

  .network-label {
    color: var(--ion-color-medium);
  }

  .measurements {
    margin-top: 18.5px;
    margin-bottom: 5px;
  }

  .test-again {
    color: var(--ion-text-color);
    text-decoration: underline;
    font-size: 14px;
    line-height: 16.8px;
  }

  .speed-result {
    font-size: 14px;
    line-height: 18.9px;
  }
`

export function NetworkMeterResultsCard() {
  const networkTypeQuery = useNetworkTypeQuery()
  const [latency, downloadSpeed, uploadSpeed] = useBoundStore(
    (state) => [state.latency, state.downloadSpeed, state.uploadSpeed],
    shallow
  )

  return (
    <JustForYouCardFrame className={NetworkMeterResultsCardStyles}>
      <h3 className={cx(ResetMargin, 'card-title')}>Your meter results</h3>
      <div className='text-center'>
        <span className='network-label'>Network:</span> {formatNetworkType(networkTypeQuery.data || '')}
      </div>

      <div className='measurements'>
        <Measurements avgLatency={latency} uploadSpeed={uploadSpeed} speed={downloadSpeed} />

        <div className='speed-result'>
          <SpeedResult downloadSpeed={downloadSpeed} />
        </div>
      </div>

      <IonRouterLink routerLink={MainNavigationPaths.meter} className='test-again'>
        Test again
      </IonRouterLink>
    </JustForYouCardFrame>
  )
}
