import { css, cx } from '@emotion/css'
import { GridColorScale, noSignalColor } from '../../utils/signalStrength'
import { ResetMargin } from '../../utils/styles/MarginUtils.styles'
import CloseButton from '../CloseButton/CloseButton'
import TModal from '../TModal/TModal'

const DiamondStyles = (color: string) => css`
  width: 17px;
  height: 17px;
  transform: rotate(45deg);
  background-color: ${color};
  opacity: 64%;
`
const Diamond: React.FC<{ color: string }> = (props) => {
  return <div className={DiamondStyles(props.color)}></div>
}

const LegendTableStyles = css`
  font-size: 14px;

  border-collapse: collapse;
  border: 1px solid var(--ion-color-light);
  th,
  td {
    border: 1px solid var(--ion-color-light);
    padding: 8px;
    background-color: var(--ion-background-color);
  }
`
const LegendTable: React.FC = () => {
  return (
    <table className={LegendTableStyles}>
      <thead>
        <tr>
          <th></th>
          <th>Signal quality</th>
        </tr>
      </thead>
      <tbody>
        {/* Awesome row */}
        <tr>
          <td>
            <Diamond color={GridColorScale[8]} />
          </td>
          <td rowSpan={3}>
            <b>Awesome signal</b> with high reliability! Go ahead and stream those high-resolution videos.
          </td>
        </tr>
        <tr>
          <td>
            <Diamond color={GridColorScale[7]} />
          </td>
        </tr>
        <tr>
          <td>
            <Diamond color={GridColorScale[6]} />
          </td>
        </tr>
        {/* Meh row */}
        <tr>
          <td>
            <Diamond color={GridColorScale[5]} />
          </td>
          <td rowSpan={2}>
            <b>Meh signal</b> with marginal reliability. You can probably do some low-resolution video chat and
            streaming.
          </td>
        </tr>
        <tr>
          <td>
            <Diamond color={GridColorScale[4]} />
          </td>
        </tr>
        {/* Weak row */}
        <tr>
          <td>
            <Diamond color={GridColorScale[3]} />
          </td>
          <td rowSpan={2}>
            <b>Weak signal</b> with low reliability. You can do phone calls and some basic browsing, but forget about
            video.
          </td>
        </tr>
        <tr>
          <td>
            <Diamond color={GridColorScale[2]} />
          </td>
        </tr>
        {/* Terrible row */}
        <tr>
          <td>
            <Diamond color={GridColorScale[1]} />
          </td>
          <td rowSpan={2}>
            <b>Terrible signal</b> with poor reliability. You're likely only able to send text messages and emojis.
          </td>
        </tr>
        <tr>
          <td>
            <Diamond color={GridColorScale[0]} />
          </td>
        </tr>
        {/* No signal row */}
        <tr>
          <td>
            <Diamond color={noSignalColor} />
          </td>
          <td>
            <b>No signal</b>. Nada. Zilch. It's a dead zone which means you can't even send a text.
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const LegendModalStyles = css`
  background-color: var(--ion-background-color);
  max-height: 90vh;
  overflow-y: auto;
  .legend-modal-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 21px;
  }

  th {
    line-height: 125%;
    font-weight: 600;
  }
  tbody {
    background-color: var(--ion-color-primary-contrast);
  }
`
const LegendModal: React.FC<{ onClose: () => void; isOpen: boolean }> = (props) => {
  return (
    <TModal isOpen={props.isOpen} className={LegendModalStyles}>
      <section className='legend-modal-heading'>
        <h1 className={cx(ResetMargin, 'title')}>Legend</h1>
        <CloseButton onClose={props.onClose} />
      </section>
      <LegendTable />
      <section className='ion-margin-top'>
        Network performance is measured by both signal strength and signal quality. For example: strong signal quality
        will be irrelevant if the signal strength is poor.
      </section>
    </TModal>
  )
}

export default LegendModal
