import React from 'react'

export function usePreloadImages(imageUrls: string[]) {
  React.useEffect(() => {
    if (!imageUrls.length) {
      return
    }

    for (const imageUrl of imageUrls) {
      const img = new Image()
      img.src = imageUrl
    }
  }, [imageUrls])
}
