import { cx } from '@emotion/css'
import { IonItem, IonLabel } from '@ionic/react'
import React from 'react'
import { getAddressFromPlaceId } from '../../../../../utils/Address'
import useKeyboard from '../../../../../utils/hooks/useKeyboard'
import useAddressPredictions from '../../../../../utils/hooks/usePredictions'
import { predictionsStyles } from './ChatInput.styles'
import ChatInput, { ChatInputProps } from './ChatInput'
import { useBoundStore } from '../../../../../store'

export interface ChatAddressInputProps {
  onSend: (address: google.maps.places.AutocompletePrediction | string) => void
  type: 'address' | 'text'
  state: ChatInputProps['state']
  autocompleteService?: google.maps.places.AutocompleteService
  disabled?: boolean
  initialText?: string
  placeholder?: string
}

export interface ChatAddressInputRef {
  focus: () => void
  clear: () => void
}

function ChatAddressInput(props: ChatAddressInputProps, ref: React.Ref<{ focus: () => void }>) {
  const [value, setValue] = React.useState(props.initialText ?? '')
  const textInput = React.useRef<HTMLInputElement>(null)
  const [showPredictions, setShowPredictions] = React.useState(false)
  const isKeyboardVisible = useKeyboard().isKeyboardVisible
  const [selectedAddress, setSelectedAddress] = React.useState<google.maps.places.AutocompletePrediction | null>(null)
  const isLoadingCurrentLocation = useBoundStore((state) => state.loadingLocation)

  React.useImperativeHandle(ref, () => ({
    focus: () => {
      textInput.current?.focus()
    },
    clear: () => {
      setValue('')
      setSelectedAddress(null)
    }
  }))

  const handleSend = () => {
    props.onSend(props.type === 'address' ? selectedAddress! : value)
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') {
      setSelectedAddress(null)
    }
  }

  const { predictions } = useAddressPredictions({
    value: props.type === 'address' ? value : '',
    service: props.autocompleteService
  })

  const footerSlot =
    props.type === 'address' ? (
      <div className={cx(predictionsStyles, !showPredictions && 'hidden', isKeyboardVisible && 'with-keyboard')}>
        {predictions.data?.map((result, i) => (
          <IonItem
            data-testid={`prediction-${i}`}
            key={result.place_id}
            button
            detail={false}
            lines='none'
            onClick={(e) => {
              onClickPrediction(result)
            }}
          >
            <IonLabel>
              <h3>{result.structured_formatting.main_text}</h3>
              <p>{result.structured_formatting.secondary_text}</p>
            </IonLabel>
          </IonItem>
        ))}
      </div>
    ) : undefined

  const onClickPrediction = async (prediction: google.maps.places.AutocompletePrediction) => {
    const address = await getAddressFromPlaceId(prediction.place_id)
    setValue(address?.addressName ?? '')
    setSelectedAddress(prediction)
    setShowPredictions(false)
  }

  return (
    <ChatInput
      value={value}
      setValue={setValue}
      onKeyDown={props.type === 'address' ? onKeyDown : undefined}
      footerSlot={footerSlot}
      disabled={props.type === 'address' ? selectedAddress === null || isLoadingCurrentLocation : undefined}
      state={props.state}
      placeholder={props.placeholder}
      onSend={handleSend}
      onFocus={() => setShowPredictions(true)}
      onBlur={(event) => {
        if (event.eventPhase === Event.AT_TARGET) {
          setShowPredictions(false)
        }
      }}
      ref={textInput}
    />
  )
}

export default React.forwardRef(ChatAddressInput)
