import { css, cx } from '@emotion/css'
import { IonCard, IonItemDivider, IonSkeletonText } from '@ionic/react'

const TinderPlanSkeletonStyles = css`
  margin: 0;
  height: 450px !important;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  border: 2px solid var(--ion-color-dark);
  padding: 32px 16px;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: 1;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    gap: 8px;

    ion-skeleton-text {
      min-height: 20px;
    }

    ion-item-divider {
      min-height: 2px !important;
    }

    .row {
      display: flex;
      gap: 16px;
      justify-content: space-between;
    }
  }

  &:not(.first-card) {
    transform: scale(0.95) translateY(20px);
    ::after {
      background: rgba(0, 0, 0, 0.2);
    }
  }
`

interface PlanSwipeCardSkeletonProps {
  notFirst?: boolean
}

const PlanSwipeCardSkeleton = ({ notFirst }: PlanSwipeCardSkeletonProps) => {
  return (
    <div>
      <IonCard
        className={cx(TinderPlanSkeletonStyles, {
          'first-card': !notFirst
        })}
      >
        <div className='card-content'>
          <div className='row'>
            <IonSkeletonText animated style={{ width: '40%', height: '50px' }} />
            <IonSkeletonText animated style={{ width: '20%', height: '50px' }} />
          </div>
          <IonSkeletonText animated style={{ width: '80%' }} />
          <IonSkeletonText animated style={{ width: '90%' }} />
          <IonItemDivider />
          <IonSkeletonText animated style={{ width: '70%' }} />
          <IonSkeletonText animated style={{ width: '60%' }} />
          <IonSkeletonText animated style={{ width: '60%' }} />
          <IonSkeletonText animated style={{ marginLeft: '20px', width: '40%' }} />
          <IonSkeletonText animated style={{ marginLeft: '20px', width: '50%' }} />
          <IonSkeletonText animated style={{ marginLeft: '20px', width: '30%' }} />
        </div>
      </IonCard>
    </div>
  )
}

export default PlanSwipeCardSkeleton
