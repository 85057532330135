import { css } from '@emotion/css'

export const HeaderButton = css`
  height: 48px !important;
  width: 48px;
  margin: 0 !important;
  border-radius: 5px;

  ion-icon {
    width: 24px;
    height: 24px;
  }
`

export const HeaderStyles = css`
  h1 {
    margin: 0;
    margin-left: 4px;
  }

  ion-title {
    cursor: pointer;
  }
  .reset-placeholder {
    width: 35.59px;
  }
`
