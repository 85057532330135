import * as React from 'react'
import { useGeneralStore } from '../../../store'
import { css, cx } from '@emotion/css'
import TopBar from './../TopBar/TopBar'
import BottomTray from './../BottomTray/BottomTray'
import Minimap from './../BottomTray/Minimap'
import LoadingPopup from './../LoadingPopup'
import { DesktopWelcomeMapModal } from './../DesktopWelcomeMapModal'
import { useCoverageMapLogic, useOnSelectedAddressChange } from './useCoverageMap'
import InnerCoverageMap from './InnerCoverageMap'
import NetworkMapSidebar from '../side-bar'
import { useIsMobileSize } from '../../../utils/hooks/useWindowSize'
import { IonBackdrop } from '@ionic/react'
import useMapOnboardingStore from '../BottomTray/useMapOnboardingStore'
import shallow from 'zustand/shallow'
import { useMapStateByParams } from './useMapStateByParams'

export const CoverageMapStyles = css`
  position: relative;

  .coverage-map-map {
    height: 100%;
    border-top: solid 1px var(--ion-color-light);
  }

  height: 100%;
`

const ControlsWrapperStyles = css`
  position: absolute;
  bottom: 0;
  width: 100%;

  pointer-events: none;
  & > * {
    pointer-events: all;
  }

  display: grid;
  align-items: end;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  @media screen and (max-width: 911px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'left right'
      'fullwidth fullwidth';

    .minimap {
      grid-area: left;
    }

    .bottom-tray {
      width: 100%;
      grid-area: fullwidth;
    }
  }
  @media screen and (min-width: 911px) {
    grid-template-columns: 1fr 1fr 1fr;
    .bottom-tray {
      width: 692px;
      max-width: 692px;
      border: 2px solid #000000;
      transform: translateY(2px);
    }
  }
`

const mapSidebarStyles = css`
  position: absolute;
  top: -100%;
  right: 0;
`

const backdropStyles = css`
  opacity: 0.5;
`

const CoverageMap = () => {
  const {
    deckManagerRef,
    googleMap,
    setGoogleMap,
    selectedCarrier,
    setSelectedCarrier,
    loading,
    setLoading,
    appExperience,
    bounds,
    onSwitchNetworkType,
    focusMarker,
    onCurrentPositionAdded,
    onCloseWelcomeModal,
    trackMapInteraction,
    isWelcomeModalOpen
  } = useCoverageMapLogic()
  useOnSelectedAddressChange(deckManagerRef)

  const [networkType, addresses, map] = useGeneralStore(
    (state) => [state.networkType, state.addresses, state.map],
    shallow
  )
  const isMobile = useIsMobileSize()

  const { hasBeenOnboardedOnMapTray, completeOnboard } = useMapOnboardingStore()

  useMapStateByParams({
    onSelectAddress: focusMarker,
    onSwitchNetworkType,
    onSelectCarrier: setSelectedCarrier,
    map: deckManagerRef.current?.map
  })

  return (
    <>
      {!hasBeenOnboardedOnMapTray && !!addresses.length && (
        <IonBackdrop className={backdropStyles} visible onIonBackdropTap={completeOnboard} />
      )}
      <div
        onMouseDown={trackMapInteraction}
        onTouchStart={trackMapInteraction}
        className={cx(CoverageMapStyles, appExperience)}
      >
        {loading ? <LoadingPopup /> : null}
        <InnerCoverageMap
          deckManagerRef={deckManagerRef}
          setGoogleMap={setGoogleMap}
          onClickMarker={focusMarker}
          selectedCarrier={selectedCarrier}
          setLoading={setLoading}
          networkType={networkType}
          addresses={addresses}
          zoom={map.zoom}
        />
        <TopBar
          addresses={addresses}
          onChangeCarrier={(carrier) => {
            setSelectedCarrier(carrier)
          }}
          onSwitchNetworkType={onSwitchNetworkType}
          selectedTechnology={networkType}
          selectedCarrier={selectedCarrier}
        />
        <div className={ControlsWrapperStyles}>
          {googleMap ? <Minimap className='minimap' onClickAddress={focusMarker} map={googleMap} /> : undefined}
          <BottomTray
            className='bottom-tray'
            carrierId={selectedCarrier?.id ?? ''}
            children={
              <>
                {!!isMobile && (
                  <NetworkMapSidebar
                    className={mapSidebarStyles}
                    onCurrentLocation={onCurrentPositionAdded}
                    onZoomChanged={(zoomChange) => {
                      const newZoom = (deckManagerRef.current?.map.getZoom() || 0) + zoomChange
                      deckManagerRef.current?.map.setZoom(newZoom)
                    }}
                    onRecenter={() => {
                      if (addresses.length) {
                        deckManagerRef.current?.map.fitBounds(bounds)
                      }
                    }}
                  />
                )}
              </>
            }
          />
          {!isMobile && (
            <NetworkMapSidebar
              onCurrentLocation={onCurrentPositionAdded}
              onZoomChanged={(zoomChange) => {
                const newZoom = (deckManagerRef.current?.map.getZoom() || 0) + zoomChange
                deckManagerRef.current?.map.setZoom(newZoom)
              }}
              onRecenter={() => {
                if (addresses.length) {
                  deckManagerRef.current?.map.fitBounds(bounds)
                }
              }}
            />
          )}
        </div>
        <DesktopWelcomeMapModal isOpen={isWelcomeModalOpen} onClose={onCloseWelcomeModal} />
      </div>
    </>
  )
}

export default CoverageMap
