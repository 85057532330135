import { Plan } from '../../../services/planService/Plan'
import { SMS_UNLIMITED, VOICE_UNLIMITED } from '../../../services/planService/Preferences'
import { ContractTypeV3, ReasonForSwitch } from '../../../services/wizard/reason-for-switch'

export type DataFilter = 'include-premium' | 'include-hotspot' | 'unlimited-talk' | 'unlimited-text'
export type PlanPreferencesV3 = {
  reasonsForSwitch: ReasonForSwitch[]
  carrierIds: string[]
  contractTypes: ContractTypeV3[]
  numLines?: number
  minPrice?: number
  maxPrice?: number
  minData?: number
  maxData?: number
  minNetworkPerformance?: number
  dataFilters: DataFilter[]
  streamingServices: string[]
  roamingCountries: string[]
  minCoverage?: number
}

function withinDataFilters(plan: Plan, preferences: PlanPreferencesV3) {
  const planData = plan.data ?? 0
  if (preferences.minData !== undefined && preferences.minData > planData) {
    return false
  }

  if (preferences.maxData !== undefined && preferences.maxData < planData) {
    return false
  }

  if (
    preferences.minCoverage !== undefined &&
    (plan.networkPerformance4G ?? 0) < preferences.minCoverage &&
    (plan.networkPerformance5G ?? 0) < preferences.minCoverage
  ) {
    return false
  }

  if (preferences.dataFilters.length > 0) {
    for (const filter of preferences.dataFilters) {
      if (filter === 'include-premium' && !plan.premiumData) {
        return false
      }

      if (filter === 'include-hotspot' && !plan.hotspotDataLimit) {
        return false
      }

      if (filter === 'unlimited-talk' && plan.voice !== VOICE_UNLIMITED) {
        return false
      }

      if (filter === 'unlimited-text' && plan.sms !== SMS_UNLIMITED) {
        return false
      }
    }
  }
  return true
}

export function getInitialPreferencesV3(): PlanPreferencesV3 {
  return {
    reasonsForSwitch: [],
    carrierIds: [],
    streamingServices: [],
    roamingCountries: [],
    contractTypes: [ContractTypeV3.Prepaid, ContractTypeV3.Postpaid],
    dataFilters: [],
    minData: undefined,
    maxData: undefined,
    minNetworkPerformance: undefined,
    minPrice: undefined,
    maxPrice: undefined,
    numLines: 1
  }
}

export function filterPlansV3(plans: Plan[], preferences: PlanPreferencesV3) {
  return plans.filter((plan) => {
    const numLines = preferences.numLines ?? 1
    if (numLines && numLines !== plan.numLines) {
      return false
    }

    if (preferences.carrierIds.length > 0 && !preferences.carrierIds.includes(plan.carrierId)) {
      return false
    }

    const planContractType = plan.contractType === 'Prepaid' ? ContractTypeV3.Prepaid : ContractTypeV3.Postpaid

    if (!preferences.contractTypes.includes(planContractType)) {
      return false
    }

    if (preferences.numLines && preferences.numLines !== plan.numLines) {
      return false
    }

    if (preferences.minPrice && preferences.minPrice > plan.price) {
      return false
    }

    if (preferences.maxPrice && preferences.maxPrice < plan.costPerLine) {
      return false
    }

    if (!withinDataFilters(plan, preferences)) {
      return false
    }

    // TODO: test this. Should use service key instead?
    if (
      preferences.streamingServices.length > 0 &&
      !(plan.streamingServices ?? []).some((planStreamingService) =>
        preferences.streamingServices.includes(planStreamingService.serviceName)
      )
    ) {
      return false
    }

    if (
      preferences.roamingCountries.length > 0 &&
      !(plan.roamingCountries ?? []).some((planRoamingCountry) =>
        preferences.roamingCountries.includes(planRoamingCountry)
      )
    ) {
      return false
    }

    return true
  })
}
