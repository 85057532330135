import React from 'react'
import { useHistory } from 'react-router'
import { ALL_PLANS_TAB } from '../../pages/plans/tabs/PlanTab'
import { MainNavigationPaths } from '../../paths'
import { Plan } from '../../services/planService/Plan'

const generatePlanPath = (carrierId: string, planId: string) => {
  return `${MainNavigationPaths.plans}/${ALL_PLANS_TAB}/${carrierId}/${planId}`
}

export const useNavigateToPlan = () => {
  const history = useHistory()

  const navigateToPlan = React.useCallback(
    (plan: Plan, planScrollIdx?: number) => {
      history.replace(history.location.pathname + history.location.search, { planScrollIdx })
      history.push(generatePlanPath(plan.carrierId, plan.planId), {
        fromPlanResults: history.location.pathname.startsWith(MainNavigationPaths.plans)
      })
    },
    [history]
  )

  return { navigateToPlan, planScrollIdx: (history.location.state as any)?.planScrollIdx as number }
}
