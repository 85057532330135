import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList, useIonModal, useIonPopover } from '@ionic/react'
import { css, cx } from '@emotion/css'
import React from 'react'
import { briefcaseSharp, caretDown, closeSharp, homeSharp } from 'ionicons/icons'
import { Address, LabelType } from '../../store/address'
import { useGeneralStore } from '../../store'
import shallow from 'zustand/shallow'

const MODAL_WIDTH = 360
export const AddressLabelModalStyles = css`
  --height: auto;
  --max-width: ${MODAL_WIDTH}px;
  --border-radius: 10px;
`

const modalWrapperStyles = css`
  color: var(--goji-primary-black);

  .close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    --padding-start: 0;
    --padding-end: 0;
  }

  ion-icon {
    color: var(--ion-color-medium);
  }
  padding: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .modal-content {
    display: flex;
    flex-direction: column;
    h2 {
      margin-top: 26px;
    }
    p {
      margin-top: 8px;
    }

    > ion-item {
      --padding-start: 0;
      --inner-padding-end: 0;

      .address-label-input {
        width: 100%;
        display: flex;
        align-items: center;
        height: 48px;
        border: 2px solid var(--goji-primary-black);
        &.active {
          border: 2px solid var(--ion-color-primary);
        }
        border-radius: 10px;
        padding: 0 16px;
      }
    }
    .address-label-label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      color: var(--ion-color-medium);
      margin-top: 8px;
    }
  }
  .button-container {
    margin-top: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    ion-button {
      height: 52px;
      font-size: 16px;
      flex: 1;
      --border-radius: 30px;
      --border-width: 2px;
      --border-color: var(--ion-color-primary);
    }
  }

  ion-icon [slot='start'] {
    margin-right: 8px;
  }
`

const itemIconStyles = css`
  margin-inline-end: 8px;
  color: var(--ion-color-medium);
`

const popoverStyles = css`
  --offset-y: -2px;
  --width: calc(${MODAL_WIDTH}px - 60px);

  ::part(content) {
    box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
`

const startIconLabelStyles = css`
  padding-right: 8px;
  padding-bottom: 2px; // adjust icon position to align with capital letters in input
`

const StartIconLabel: React.FC<{ labelType: LabelType }> = ({ labelType }) => {
  const icon = labelType === 'HOME' ? homeSharp : labelType === 'WORK' ? briefcaseSharp : ''

  return <IonIcon icon={icon} className={startIconLabelStyles} />
}

const PopoverList: React.FC<{
  currentlySelectedLabelType: LabelType
  setLabelType: (labelType: LabelType) => void
}> = ({ currentlySelectedLabelType, setLabelType }) => {
  const addresses = useGeneralStore((state) => state.addresses, shallow)

  return (
    <IonList>
      <IonItem
        disabled={
          currentlySelectedLabelType === LabelType.Home ||
          addresses.some((address) => address.labelType === LabelType.Home)
        }
        button
        lines='none'
        onClick={(evt) => setLabelType(LabelType.Home)}
      >
        <IonIcon icon={homeSharp} size='small' slot='start' className={itemIconStyles} />
        <IonLabel>Home</IonLabel>
      </IonItem>
      <IonItem
        disabled={
          currentlySelectedLabelType === LabelType.Work ||
          addresses.some((address) => address.labelType === LabelType.Work)
        }
        button
        lines='none'
        onClick={(evt) => setLabelType(LabelType.Work)}
      >
        <IonIcon icon={briefcaseSharp} size='small' slot='start' className={itemIconStyles} />
        <IonLabel>Work</IonLabel>
      </IonItem>
    </IonList>
  )
}

interface AddressLabelDialogProps {
  isRenaming?: boolean
  address?: Address
  onDismiss?: () => void
  onSubmit?: (labelType: LabelType, label: string) => void
}

export const AddressLabelDialog: React.FC<AddressLabelDialogProps> = (props) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const ionInputRef = React.useRef<HTMLIonInputElement>(null)
  const [value, setValue] = React.useState<string>(props.address?.label || '')
  const [labelType, setLabelType] = React.useState<LabelType>(props.address?.labelType || LabelType.Other)

  const [present, dismiss] = useIonPopover(PopoverList, {
    labelType: labelType,
    setLabelType: (labelType: LabelType) => {
      setLabelType(labelType)
      setValue('')
      dismiss()
    }
  })

  function openPopover() {
    setIsExpanded(true)
    present({
      onDidDismiss: () => setIsExpanded(false),
      side: 'top',
      event: undefined,
      reference: 'trigger',
      trigger: 'input-address-label',
      cssClass: popoverStyles,
      alignment: 'center',
      showBackdrop: false,
      keyboardClose: false,
      arrow: false
    })
  }

  const toggleInputFocus = () => {
    const inputElement = ionInputRef.current?.children[0] as HTMLInputElement | undefined
    if (isExpanded) {
      dismiss()
      inputElement?.blur()
      return
    }

    openPopover()
  }

  const handleSubmit = (isSkip: boolean = false) => {
    if (props.onSubmit) {
      if (isSkip) {
        props.onSubmit(LabelType.Other, props.address?.addressName.split(',')[0] || '')
      } else {
        props.onSubmit(
          labelType,
          labelType === 'OTHER' ? value.trim() : labelType.charAt(0) + labelType.slice(1).toLowerCase()
        )
      }
    }
  }

  return (
    <div
      className={modalWrapperStyles}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          handleSubmit()
        }
      }}
    >
      <IonButton className='close-button' fill='clear' size='small' onClick={props.onDismiss}>
        <IonIcon slot='icon-only' icon={closeSharp} />
      </IonButton>
      <div className='modal-content'>
        <h2>{props.isRenaming ? 'Rename your address' : 'Name your address'}</h2>
        <p>You can always name this address later using your Address Book in your profile.</p>
        <IonItem lines='none' detail={false} button={!isExpanded} onClick={toggleInputFocus}>
          <div
            id='input-address-label'
            className={cx('address-label-input', isExpanded ? 'active' : '')}
            onClick={(evt) => isExpanded && evt.stopPropagation()}
          >
            {labelType !== 'OTHER' && <StartIconLabel labelType={labelType as LabelType} />}
            <IonInput
              value={labelType === 'OTHER' ? value : labelType.charAt(0) + labelType.slice(1).toLowerCase()}
              onIonInput={(e) => {
                setValue(String(e.target.value))
                setLabelType(LabelType.Other)
              }}
              maxlength={50}
              onKeyUp={(e) => {
                setLabelType(LabelType.Other)
                if (e.key === 'Enter') {
                  toggleInputFocus()
                }
              }}
              ref={ionInputRef}
              placeholder={props.address?.addressName.split(',')[0]}
            />
            <IonIcon icon={caretDown} />
          </div>
        </IonItem>
        <IonLabel className='address-label-label' title={props.address?.addressName}>
          {props.address?.addressName}
        </IonLabel>
      </div>
      <div className='button-container'>
        {!props.isRenaming && (
          <IonButton
            fill='outline'
            onClick={() => {
              handleSubmit(true)
            }}
          >
            SKIP
          </IonButton>
        )}
        <IonButton
          disabled={labelType === 'OTHER' && !value.trim()}
          fill='solid'
          onClick={() => {
            handleSubmit()
          }}
        >
          DONE
        </IonButton>
      </div>
    </div>
  )
}

export const useAddressLabelDialog = ({
  isRenaming,
  address,
  onDismiss,
  onSubmit,
  onDidDismiss
}: AddressLabelDialogProps & { onDidDismiss?: () => void }) => {
  const [present, dismiss] = useIonModal(AddressLabelDialog, {
    isRenaming,
    address,
    onDismiss: () => {
      onDismiss?.()
      dismiss()
    },
    onSubmit: (labelType: LabelType, label: string) => {
      onSubmit?.(labelType, label)
      dismiss()
    }
  })

  const openModal = () => {
    present({
      cssClass: AddressLabelModalStyles,
      mode: 'md',
      showBackdrop: true,
      backdropDismiss: true,
      onDidDismiss: onDidDismiss
    })
  }

  return { openModal, dismiss }
}
