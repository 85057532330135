import React from 'react'
import { useQuery } from 'react-query'
import { createGoogleAutocompleteService } from '../../services/googleAutocompleteService'

interface UseAddressPredictionsOptions {
  value: string
  bounds?: google.maps.LatLngBounds
  types?: string[]
  service?: google.maps.places.AutocompleteService
}

const useAddressPredictions = ({
  value,
  bounds,
  types = ['address'],
  service = createGoogleAutocompleteService()
}: UseAddressPredictionsOptions) => {
  const search = React.useCallback(
    async (value: string, bounds?: google.maps.LatLngBounds) => {
      if (!value) {
        return Promise.resolve([])
      }

      const result = await new Promise<google.maps.places.AutocompletePrediction[] | null>((resolve) => {
        return service.getPlacePredictions(
          {
            input: value,
            types: types.length ? types : undefined,
            componentRestrictions: { country: 'us' },
            locationBias: bounds
          },
          resolve
        )
      })

      return result ?? []
    },
    [service, types]
  )

  const predictions = useQuery({
    queryKey: ['predictions', value, bounds],
    enabled: !!value,
    queryFn: () => search(value, bounds)
  })

  return { predictions, search }
}

export default useAddressPredictions
