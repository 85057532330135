import { cx } from '@emotion/css'
import { WidthLimiterStyles } from '../../../components/WidthLimiter/WidthLimiter'
import { ChatInteraction } from '../types/ChatStep'
import { usePredefinedChat } from '../hooks/useChat'
import React from 'react'
import ChatTimeline, { ChatTimelineProps } from './ChatTimeline'
import ChatAddressInput, { ChatAddressInputRef } from './PlanSwipe/ChatInput/ChatAddressInput'
import { ChatFooter, ChatInputProps } from './PlanSwipe/ChatInput/ChatInput'
import { IonButton, IonContent } from '@ionic/react'
import { useScrollToBottom } from '../hooks/useScrollToBottom'
import { ChatStatus } from '../types/ChatStorageSource'
import ChatHeaderComponent from './ChatHeaderComponent'

function getChatInputState(isLoading: boolean, chatStatus: ChatStatus): ChatInputProps['state'] {
  if (chatStatus === 'ended') {
    return 'finished'
  }

  return isLoading ? 'sending' : 'idle'
}

export interface ChatComponentProps {
  autocompleteService?: google.maps.places.AutocompleteService
  endLink?: string
  onChatEnd?: () => void
  onRestart?: () => void
  chatStatus?: ChatStatus
  title?: string
  isLoading: boolean
  disableRestart?: boolean

  chat: {
    onChatSend: ReturnType<typeof usePredefinedChat>['onChatSend']
    onAddressSend: ReturnType<typeof usePredefinedChat>['onAddressSend']
    onSuggestionSelected: ReturnType<typeof usePredefinedChat>['onSuggestionSelected']
    lastQuestion: ChatTimelineProps['lastQuestion']
    timeline: ChatTimelineProps['timeline']
  }
}
export function ChatComponent(props: ChatComponentProps) {
  const chatStatus = props.chatStatus ?? 'active'
  const chatInputRef = React.useRef<ChatAddressInputRef>(null)

  const isResetEnabled = props.chat.timeline.length > 1 && !props.disableRestart

  const scrollArea = React.useRef<HTMLIonContentElement>(null)
  useScrollToBottom(scrollArea, props.chat.timeline, props.isLoading)

  const isAddressInput = props.chat.lastQuestion?.interactions.includes(ChatInteraction.ADDRESS)
  const isFreeTextInput = props.chat.lastQuestion?.interactions.includes(ChatInteraction.FREE_TEXT)

  return (
    <>
      <ChatHeaderComponent
        title={props.title ?? 'Plan Finder'}
        onResetClick={() => {
          chatInputRef.current?.clear()
          props.onRestart?.()
        }}
        isResetEnabled={isResetEnabled}
      />
      <IonContent ref={scrollArea}>
        <div className={cx(WidthLimiterStyles, 'chatbot-content')}>
          <ChatTimeline
            lastQuestion={props.chat.lastQuestion}
            onSuggestionSelected={props.chat.onSuggestionSelected}
            isLoading={props.isLoading}
            timeline={props.chat.timeline}
          />
        </div>
      </IonContent>
      {chatStatus === 'ended' ? (
        <ChatFooter>
          <IonButton
            className='finish-button'
            size='large'
            shape='round'
            expand='block'
            routerLink={props.endLink}
            onClick={props.onChatEnd}
          >
            LET’S GO!
          </IonButton>
        </ChatFooter>
      ) : (
        (isAddressInput || isFreeTextInput) && (
          // Using ChatAddressInput for both text and address so react can keep
          // the same input dom element focused when the input type changes between questions
          <ChatAddressInput
            ref={chatInputRef}
            key='chat-input'
            autocompleteService={props.autocompleteService}
            placeholder='Or type your response...'
            state={getChatInputState(props.isLoading, chatStatus)}
            type={isAddressInput ? 'address' : 'text'}
            onSend={isAddressInput ? (props.chat.onAddressSend as any) : props.chat.onChatSend}
          />
        )
      )}
    </>
  )
}
