import { MainNavigationPaths, RoutePaths } from '../../../../paths'
import { Logger } from '../../../../utils/Logger'
import { ValidatedResults } from '../../ServerResponse'
import { ChatInteraction, MessageType } from '../../types/ChatStep'
import { FreeBotMessage } from '../../types/free-chat.types'
import { SuggestionType } from '../../types/Suggestion'
import { ChatInterface, ToolHandler } from '../const/tool.const'

export type PlanBackendInputParams = {
  filter: 'TopPrepaid'
  redirectToPlansTabs: boolean
  topPickId: number | null
}
export interface PlanFinderToolBackendInput {
  action: null | 'showPlans'
  params: null | PlanBackendInputParams
}
export class PlanFinderToolHandler implements ToolHandler<PlanFinderToolBackendInput> {
  constructor(private chatInterface: ChatInterface<PlanFinderToolBackendInput>) {}

  onMessage(message: FreeBotMessage) {
    return this.chatInterface.onMessage({
      messageRecord: {
        message
      }
    })
  }

  private showMenu() {
    this.onMessage({
      from: 'bot',
      type: MessageType.STEP,
      question: [{ text: 'Sure! Here are some suggestions...' }],
      suggestions: [
        {
          text: 'Find me the perfect plan',
          type: SuggestionType.LINK,
          routerLink: RoutePaths.chatFinder
        },
        {
          text: 'What are the top prepaid plans?',
          type: SuggestionType.TEXT,
          value: {
            parsedAnswer: 'What are the top prepaid plans?',
            resultStatus: ValidatedResults.VALID
          }
        },
        {
          text: 'What are the top unlimited plans?',
          type: SuggestionType.TEXT,
          value: {
            parsedAnswer: 'What are the top unlimited plans?',
            resultStatus: ValidatedResults.VALID
          }
        },
        {
          text: 'Do you have any interesting plans?',
          type: SuggestionType.TEXT,
          value: {
            parsedAnswer: 'Do you have any interesting plans?',
            resultStatus: ValidatedResults.VALID
          }
        }
      ],
      interactions: [ChatInteraction.FREE_TEXT]
    })
  }

  private showPlans() {
    const { params } = this.chatInterface.toolCall.input
    if (!params) {
      Logger.error('showPlans called without params')
      return
    }

    if (params.redirectToPlansTabs) {
      this.onMessage({
        from: 'bot',
        type: MessageType.TEXT,
        text: 'Got it! Taking you to the Plan Finder...'
      })

      window.setTimeout(() => {
        this.chatInterface.pushHistory(MainNavigationPaths.plans + '/' + params.topPickId)
      }, 3000)
      return
    }

    this.onMessage({
      from: 'bot',
      type: MessageType.TOOL,
      tool: 'PlanFinder',
      toolComponent: 'EmbeddedPlansCarouselToolComponent',
      backendInput: this.chatInterface.toolCall.input
    })
    this.onMessage({
      from: 'bot',
      type: MessageType.STEP,
      question: [{ text: 'Would you like to browse more plans like this?' }],
      suggestions: [],
      interactions: [ChatInteraction.FREE_TEXT]
    })
  }

  handle(chatInterface: ChatInterface<PlanFinderToolBackendInput>): void {
    const { input } = chatInterface.toolCall

    if (input.action === null || input.params?.filter === null) {
      this.showMenu()
      return
    }

    if (input.action === 'showPlans') {
      this.showPlans()
      return
    }

    Logger.error('Plan finder tool call not handled:', chatInterface.toolCall)
  }
}
