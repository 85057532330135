import { Geolocation, PermissionStatus } from '@capacitor/geolocation'
import { ValidatedResults } from '../../pages/llm/ServerResponse'
import { ChatInterface, ToolHandler } from '../../pages/llm/tools/const/tool.const'
import { ChatInteraction, MessageType } from '../../pages/llm/types/ChatStep'
import { FreeBotMessage, FreeChatBotMessageRecord } from '../../pages/llm/types/free-chat.types'
import { SuggestionType } from '../../pages/llm/types/Suggestion'
import { Address } from '../../store/address'
import { Logger } from '../../utils/Logger'
import { useGeneralStore } from '../../store'
import { QuickQuestions } from '../../pages/llm/ChatFlow'
import { NetworkType } from '../../services/NetworkType.service'

export type CoverageMapCardBackendInput = {
  address: string | null
  isCurrentLocation: boolean
  isMissingLocation: boolean
  knownLocation: string | null
  networkType: keyof typeof NetworkType
  carrier: string | null
  carrierId: string | null
  carrierStatus: string | null
  wantsBestCarrier: boolean
  // Front-end custom params
  placeId?: string
}

export class CoverageMapSuggestionHandler {
  private coverageMapToolHandler: CoverageMapToolHandler
  private toolCall: ChatInterface<CoverageMapCardBackendInput>['toolCall']

  constructor(address: Address, chatInterface: Omit<ChatInterface<any>, 'toolCall'>) {
    this.toolCall = {
      name: 'CoverageMap',
      input: {
        address: address.addressName,
        placeId: address.placeId,
        knownLocation: address.label ?? null,
        isCurrentLocation: false,
        isMissingLocation: false,
        carrier: null,
        carrierId: null,
        carrierStatus: null,
        networkType: '5G',
        wantsBestCarrier: false
      }
    }
    this.coverageMapToolHandler = new CoverageMapToolHandler({ ...chatInterface, toolCall: this.toolCall })
  }
  public addToolMessages() {
    if (this.toolCall.input.wantsBestCarrier) {
      this.coverageMapToolHandler.onMessage({
        type: MessageType.TEXT,
        from: 'bot',
        text: `Based on this location. The carrier below provides the best ${this.toolCall.input.networkType.toUpperCase()} performance.`
      })
    } else {
      this.coverageMapToolHandler.onMessage({
        type: MessageType.TEXT,
        from: 'bot',
        text: `Got it! Here's the ${this.toolCall.input.networkType.toUpperCase()} coverage for this location.`
      })
    }

    this.coverageMapToolHandler.onMessage({
      type: MessageType.TOOL,
      from: 'bot',
      tool: 'CoverageMap',
      toolComponent: 'CoverageMapCardToolComponent',
      backendInput: this.toolCall.input
    })

    this.coverageMapToolHandler.onMessage(QuickQuestions.what_can_i_help_you_with)
  }
}

export class CoverageMapToolHandler implements ToolHandler<CoverageMapCardBackendInput> {
  constructor(private chatInterface: ChatInterface<CoverageMapCardBackendInput>, private geolocation = Geolocation) {}

  onMessage(message: FreeBotMessage) {
    this.chatInterface.onMessage({
      messageRecord: { message }
    })
  }

  private getNotGrantedLocationMessage(
    permissionState: PermissionStatus['location']
  ): FreeChatBotMessageRecord['message'] {
    if (permissionState === 'prompt' || permissionState === 'prompt-with-rationale') {
      return {
        from: 'bot',
        question: [{ text: 'Please enable location services to use this feature.' }],
        type: MessageType.STEP,
        interactions: [],
        suggestions: [
          {
            text: 'Enable Location',
            value: {
              parsedAnswer: 'Enable Location',
              resultStatus: ValidatedResults.VALID
            },
            type: SuggestionType.CURRENT_LOCATION
          },
          {
            text: 'Cancel',
            value: {
              parsedAnswer: 'Cancel',
              resultStatus: ValidatedResults.CANCELLED
            },
            type: SuggestionType.TEXT
          }
        ]
      }
    }

    return {
      from: 'bot',
      type: MessageType.TEXT,
      text: 'Permission Denied. Please enable location services to use this feature.'
    }
  }

  async handle() {
    const geolocationPermissions = await this.geolocation.checkPermissions()
    if (this.chatInterface.toolCall.input.isCurrentLocation && geolocationPermissions.location !== 'granted') {
      this.onMessage(
        this.getNotGrantedLocationMessage(geolocationPermissions.location ?? geolocationPermissions.coarseLocation)
      )
      return
    }

    const addresses = useGeneralStore.getState().addresses
    if (this.chatInterface.toolCall.input.isMissingLocation) {
      this.onMessage({
        from: 'bot',
        question: [{ text: 'Please provide an address to check the coverage.' }],
        type: MessageType.STEP,
        interactions: [ChatInteraction.ADDRESS],
        suggestions: [
          {
            type: SuggestionType.CURRENT_LOCATION,
            text: 'Current Location',
            value: { parsedAnswer: 'Current Location', resultStatus: ValidatedResults.VALID }
          },
          ...addresses.map((address) => ({
            type: SuggestionType.LOCATION,
            location: address,
            text: address.label,
            value: { parsedAnswer: address.label, resultStatus: ValidatedResults.VALID }
          }))
        ]
      })
      return
    }

    const backendInput: CoverageMapCardBackendInput = {
      ...this.chatInterface.toolCall.input
    }

    if (this.chatInterface.toolCall.input.isCurrentLocation) {
      this.onMessage({
        from: 'bot',
        question: [{ text: 'Sure! Which location would you like to check?' }],
        subtitle: "Type a location or select a label you've saved.",
        type: MessageType.STEP,
        interactions: [ChatInteraction.ADDRESS],
        suggestions: [
          {
            type: SuggestionType.CURRENT_LOCATION,
            text: 'Current Location',
            value: { parsedAnswer: 'Current Location', resultStatus: ValidatedResults.VALID }
          },
          ...addresses.map((address) => ({
            type: SuggestionType.LOCATION,
            location: address,
            text: address.label,
            value: { parsedAnswer: address.label, resultStatus: ValidatedResults.VALID }
          }))
        ]
      })
      return
    }

    // CASE: Known location
    if (this.chatInterface.toolCall.input.knownLocation) {
      Logger.warn('knownLocation: WIP')
      if (!addresses.find((address) => address.label === this.chatInterface.toolCall.input.knownLocation)) {
        this.onMessage({
          from: 'bot',
          type: MessageType.STEP,
          question: [{ text: "Sure, but I don't know where that is. Can you provide an address?" }],
          interactions: [ChatInteraction.ADDRESS],
          suggestions: [
            {
              type: SuggestionType.CURRENT_LOCATION,
              text: 'Current Location',
              value: { parsedAnswer: 'Current Location' }
            },
            ...addresses.map((address) => ({
              type: SuggestionType.LOCATION,
              location: address,
              text: address.label,
              value: { parsedAnswer: address.label }
            }))
          ]
        })
        return
      }
    }

    if (this.chatInterface.toolCall.input.wantsBestCarrier) {
      this.onMessage({
        type: MessageType.TEXT,
        from: 'bot',
        text: `Based on this location. The carrier below provides the best ${this.chatInterface.toolCall.input.networkType.toUpperCase()} performance.`
      })
    } else {
      this.onMessage({
        type: MessageType.TEXT,
        from: 'bot',
        text: `Got it! Here's the ${this.chatInterface.toolCall.input.networkType.toUpperCase()} coverage for this location.`
      })
    }

    this.onMessage({
      from: 'bot',
      type: MessageType.TOOL,
      backendInput,
      tool: this.chatInterface.toolCall.name,
      toolComponent: 'CoverageMapCardToolComponent'
    })
    this.onMessage(QuickQuestions.what_can_i_help_you_with)
  }
}
