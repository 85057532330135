const nameToTitle = {
  poor: 'Poor',
  fair: 'Fair',
  good: 'Good',
  excellent: 'Excellent',
  noData: 'No data'
}

export const noSignalColor = '#000000'

export const GridColorScale = [
  '#800007',
  '#E5000C',
  '#FF4F58',
  '#EB5E00',
  '#FF9752',
  '#FBC02D',
  '#81CA84',
  '#43A047',
  '#357E38'
] as const

export function getGridColor(performance: number) {
  return GridColorScale[Math.max(Math.floor(performance) - 1, 0)]
}

const MAX_NETWORK_PERFORMANCE = 10
export function normalizeNetworkPerformance(networkPerformance: number) {
  return networkPerformance / MAX_NETWORK_PERFORMANCE
}

export function getTitleForNetworkPerformance(performance: number) {
  if (performance === 0) {
    return nameToTitle.noData
  }
  if (performance < 4) {
    return nameToTitle.poor
  }

  if (performance < 7) {
    return nameToTitle.fair
  }

  if (performance < 8) {
    return nameToTitle.good
  }
  return nameToTitle.excellent
}

export const POOR = { name: 'poor', color: 'danger', text: nameToTitle.poor } as const
export const FAIR = { name: 'fair', color: 'warning', text: nameToTitle.fair } as const
export const GOOD = { name: 'good', color: 'success-tint', text: nameToTitle.good } as const
export const EXCELLENT = { name: 'excellent', color: 'success', text: nameToTitle.excellent } as const
export const NO_DATA = { name: 'noData', color: 'warning', text: nameToTitle.noData } as const
export type STRENGTH_DESCRIPTOR = typeof POOR | typeof FAIR | typeof GOOD | typeof EXCELLENT | typeof NO_DATA

export const getAndroidSignalStrengthDescriptor = (quality: number): STRENGTH_DESCRIPTOR => {
  if (quality === 0) {
    return NO_DATA
  }
  if (quality < 0.25) {
    return POOR
  } else if (quality >= 0.25 && quality < 0.5) {
    return FAIR
  } else if (quality >= 0.5 && quality < 0.75) {
    return GOOD
  } else {
    return EXCELLENT
  }
}

// TODO: deprecate
export const getTitleForSignalStrengthDescriptor = (descriptor: STRENGTH_DESCRIPTOR) => {
  return nameToTitle[descriptor.name]
}

export const getIconForDescriptor = (descriptor: STRENGTH_DESCRIPTOR) => {
  const nameToIcon = {
    noData: 'bad',
    poor: 'bad',
    fair: 'medium',
    good: 'good',
    excellent: 'excellent'
  }

  return `/assets/icons/signal-meter/${nameToIcon[descriptor.name]}.svg`
}
