import { StateCreator } from 'zustand'
import { ReasonForSwitch } from '../services/wizard/reason-for-switch'
import { Logger } from '../utils/Logger'

export type CurrentPlanState = {
  currentPlanId?: string
  currentPhoneLines: number
  reasonsForSwitch: ReasonForSwitch[]
}

export type CurrentPlanActions = {
  setCurrentPhoneLines: (phoneLines: number) => void
  setCurrentPlanId: (plan: string | undefined) => void
  setReasonsForSwitch: (value: ReasonForSwitch[]) => void
}

export const currentPlanInitialState: CurrentPlanState = {
  currentPhoneLines: 1,
  currentPlanId: undefined,
  reasonsForSwitch: []
}

const createCurrentPlanSlice: StateCreator<
  CurrentPlanState & CurrentPlanActions,
  [],
  [],
  CurrentPlanState & CurrentPlanActions
> = (set, get) => ({
  ...currentPlanInitialState,

  setReasonsForSwitch: (reasonsForSwitch: ReasonForSwitch[]) => {
    if (!reasonsForSwitch) {
      Logger.error('reasonsForSwitch is falsy')
      return
    }
    set(() => ({ reasonsForSwitch }))
  },
  setCurrentPlanId: (currentPlanId: string | undefined) => {
    set(() => ({ currentPlanId }))
  },

  setCurrentPhoneLines: (currentPhoneLines: number) => {
    set(() => ({ currentPhoneLines }))
  }
})

export default createCurrentPlanSlice
