import { css } from '@emotion/css'

export const TextButtonStyles = css`
  background-color: transparent;
  color: inherit;
  font-size: inherit;
`

export const PlanInfoStyles = css`
  font-size: 16px;
  line-height: 24px;

  .split-in-two {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  ion-col {
    margin-bottom: 8px;
    line-height: 24px;
  }

  color: var(--ion-text-color);
  .plan-info ion-img {
    width: fit-content;
  }

  .addon-puzzle {
    margin-right: 3px;
    width: 30px;
    & > ion-img {
      width: 19.5px;
    }
  }

  .perk-first-col {
    display: flex;
    align-items: center;

    .underline {
      text-decoration: underline;
    }
  }

  .perk-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }

  .expand-collapse-btn {
    margin-top: 10px;
    margin-bottom: -2px;
  }

  .plan-property ion-col:first-child {
    color: #878787;
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
  }

  ul {
    padding-left: 24px;
    font-size: 16px;
    &.perks {
      display: inline-block;
      margin: 0;
    }

    li {
      margin-bottom: 8px;
      > span.perk {
        margin-bottom: 0;
        text-align: left;
        display: -webkit-box;
        &.clamp {
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }

  .more-perks {
    margin-left: 24px;
  }
`

export const PlanPropertyStyles = css`
  .children-wrapper {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const IconWrapper = css`
  display: flex;
  justify-content: center;
  width: 30px;
`

export const PlanInfoSummaryStyles = css`
  .section-header {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    display: flex;
    align-items: center;
  }

  .underline {
    background-color: transparent;
    text-decoration: underline;
  }

  .divider {
    width: 100%;
    height: 0px;
    margin: 0 0 16px;
    border-bottom: 1px solid var(--ion-color-light);
    content: '';
  }

  ul {
    margin: 8px 0;
    padding-left: 32px;
  }

  li {
    font-size: 16px;
    line-height: 22px;
    text-wrap: balance;
  }

  .clarification-streaming-services {
    font-size: 14px;
    color: var(--goji-color-description);
  }

  .streaming-service {
    span {
      margin-right: 4px;
    }
  }

  .service-price {
    font-size: 14px;
    font-weight: 700;
    color: var(--ion-color-secondary-background);
    background-color: var(--goji-color-secondary-blue);
    padding: 4px 8px;
    border-radius: 4px;
  }
`
