import React from 'react'
import useSwipeGesture from './useSwipeGesture'
import useMapOnboardingStore from './useMapOnboardingStore'
import { css } from '@emotion/css'

interface UseBottomTrayGestureProps {
  footerRef: React.RefObject<HTMLDivElement>
  addressDetailsRef: React.RefObject<HTMLDivElement>
  carrierListRef: React.RefObject<HTMLDivElement>
}

const onStart =
  ({
    footerRef,
    hasBeenOnboardedOnMapTray,
    completeOnboard
  }: {
    footerRef: React.RefObject<HTMLDivElement>
    hasBeenOnboardedOnMapTray: boolean
    completeOnboard: () => void
  }) =>
  () => {
    if (!footerRef.current) return
    footerRef.current.style.transition = 'none'
    if (!hasBeenOnboardedOnMapTray) completeOnboard()
  }

const onMove =
  ({
    footerRef,
    carrierListRef,
    position
  }: {
    footerRef: React.RefObject<HTMLDivElement>
    carrierListRef: React.RefObject<HTMLDivElement>
    position: React.MutableRefObject<number>
  }) =>
  (ev: any) => {
    if (!footerRef.current || !carrierListRef.current) return
    const calculatedPositionY = position.current - ev.deltaY
    if (
      calculatedPositionY > carrierListRef.current.clientHeight ||
      calculatedPositionY < -footerRef.current.clientHeight
    ) {
      return
    }
    footerRef.current.style.transform = `translateY(${-calculatedPositionY}px)`
  }

const onEnd =
  ({
    footerRef,
    carrierListRef,
    position,
    setIsCollapsed
  }: {
    footerRef: React.RefObject<HTMLDivElement>
    carrierListRef: React.RefObject<HTMLDivElement>
    position: React.MutableRefObject<number>
    setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>
  }) =>
  (ev: any) => {
    if (!footerRef.current || !carrierListRef.current) return
    footerRef.current.style.transition = 'transform 0.5s ease-out'
    const updatedPositionY = position.current - ev.deltaY
    if (updatedPositionY > -100 && (updatedPositionY < 100 || ev.deltaY > 0)) {
      position.current = 0
    } else if (updatedPositionY >= 100) {
      position.current = carrierListRef.current.clientHeight
    } else {
      updateFooterStyles(footerRef, true, position)
      setIsCollapsed(true)
      return
    }
    setIsCollapsed(false)
    footerRef.current.style.transform = `translateY(-${position.current}px)`
  }

const updateFooterStyles = (
  footerRef: React.RefObject<HTMLDivElement>,
  isCollapsed: boolean,
  position: React.MutableRefObject<number>
) => {
  if (!footerRef.current) return
  if (isCollapsed) {
    position.current = -footerRef.current.clientHeight + 65
  } else {
    position.current = 0
  }
  footerRef.current.style.transform = `translateY(${-position.current}px)`
}

export const boardingGestureStyles = css`
  @keyframes moveFooter {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  animation: moveFooter 2s infinite;
`

export const UseBottomTrayGesture = ({ footerRef, addressDetailsRef, carrierListRef }: UseBottomTrayGestureProps) => {
  const position = React.useRef(0)
  const [hasBeenOnboardedOnMapTray, completeOnboard] = useMapOnboardingStore((state) => [
    state.hasBeenOnboardedOnMapTray,
    state.completeOnboard
  ])
  const [isCollapsed, setIsCollapsed] = React.useState(false)

  useSwipeGesture({
    elementRef: addressDetailsRef,
    onStart: onStart({ footerRef, hasBeenOnboardedOnMapTray, completeOnboard }),
    onMove: onMove({ footerRef, carrierListRef, position }),
    onEnd: onEnd({ footerRef, carrierListRef, position, setIsCollapsed })
  })

  const handleCollapse = () => {
    updateFooterStyles(footerRef, !isCollapsed, position)
    setIsCollapsed(!isCollapsed)
  }

  return { handleCollapse, isCollapsed, hasBeenOnboardedOnMapTray }
}
