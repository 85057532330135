import * as React from 'react'
import { css, cx } from '@emotion/css'
import { IonButton, IonIcon, IonItem, IonPopover, IonSpinner, IonText } from '@ionic/react'
import { chevronDown } from 'ionicons/icons'
import { NetworkType, NetworkTypeValue } from '../../../services/NetworkType.service'
import LegendModal from '../LegendModal'
import { UseCarrier } from '../../../services/Carrier.service'
import { Carrier } from '../../../store/carrierSelector'
import { EditIcon } from '../MapAddressesHeader'
import { AddressesSearchbar } from '../../../pages/network-quality/AddressSearchbar'
import useNavigationStore from '../../../store/navigation'
import shallow from 'zustand/shallow'
import { MainNavigationPaths, RoutePaths } from '../../../paths'
import { useUnsavedAddresses } from '../hooks/useUnsavedAddresses'
import { Address } from '../../../store/address'
import { MOBILE_BREAKPOINT } from '../../../utils/hooks/useWindowSize'

const TopBarButton = css`
  height: 40px;
  &::part(native) {
    background-color: var(--ion-background-color);
    padding: 8px 16px;
  }
`

const CarrierPopoverStyles = css`
  --offset-y: 2px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    --offset-x: 12px;
  }
`

const ListStyles = css`
  margin-top: 4px;
  min-height: 250px;
  max-height: 50vh;
  @media (max-height: 750px) {
    max-height: 40vh;
  }

  .divider {
    border-top: 1px solid var(--ion-color-light);
    margin: 8px 0 16px 0;
  }
`

const ListTitleStyles = css`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding: 16px 0 8px 14px;
  background-color: var(--ion-card-background);
`
const CarrierDropdownStyles = css`
  height: 40px;
  .current-carrier {
    font-size: 16px;
  }
`

const BestChipStyles = css`
  background-color: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
  padding: 2px 8px;
  font-size: 14px;
  border-radius: 2px;
  margin-left: 8px;
  font-weight: 600;
`
const BestChip = ({ networkType }: { networkType: NetworkTypeValue }) => {
  return <div className={BestChipStyles}>Best {networkType === NetworkType['4G'] ? '4G' : '5G'}</div>
}

const carrierItem = (
  carrier: Carrier,
  bestCarrier: Carrier | undefined,
  selectedTechnology: NetworkTypeValue,
  onClick: (carrier: Carrier) => void
) => (
  <IonItem
    key={carrier.id}
    onClick={() => {
      onClick(carrier)
    }}
    button
    detail={false}
    lines='none'
  >
    {carrier.name}
    {carrier.id && carrier.id === bestCarrier?.id ? <BestChip networkType={selectedTechnology} /> : ''}
  </IonItem>
)

const CarrierList = ({
  carriers,
  onClick,
  selectedTechnology
}: {
  carriers: Carrier[]
  onClick: (carrier: Carrier) => void
  selectedTechnology: NetworkTypeValue
}) => {
  const bestCarrier = UseCarrier.useBestCarrierForTechnology({ technology: selectedTechnology })
  const { defaultCarriers, otherCarriers } = carriers.reduce<{ defaultCarriers: Carrier[]; otherCarriers: Carrier[] }>(
    (acc, carrier) => {
      carrier.has_data ? acc.defaultCarriers.push(carrier) : acc.otherCarriers.push(carrier)
      return acc
    },
    { defaultCarriers: [], otherCarriers: [] }
  )

  return (
    <div className={ListStyles}>
      <h5 className={ListTitleStyles}>Major Carriers</h5>
      {defaultCarriers.map((carrier) => carrierItem(carrier, bestCarrier, selectedTechnology, onClick))}
      {otherCarriers.length ? (
        <>
          <div className='divider' />
          <h5 className={ListTitleStyles}>Other Carriers</h5>
        </>
      ) : null}
      {otherCarriers.map((carrier) => carrierItem(carrier, bestCarrier, selectedTechnology, onClick))}
    </div>
  )
}

const CarrierDropdown = (props: {
  selectedCarrier?: Carrier
  addresses: Address[]
  onChangeCarrier: (carrier: Carrier) => void
  selectedTechnology: NetworkTypeValue
}) => {
  const carriers = UseCarrier.useCarriersSplit(UseCarrier.useAvailableInMyRegion(UseCarrier.useAllCarriers()))
  const defaultCarriers = carriers.data ? carriers.data.defaultCarriers : []
  const otherCarriers = carriers.data
    ? carriers.data.otherCarriers.filter((carrier) => !!carrier.is_visible_on_map)
    : []
  const allCarriers = defaultCarriers.concat(otherCarriers)

  const onClickCarrier = (carrier: Carrier) => {
    props.onChangeCarrier(carrier)
  }

  return (
    <>
      <IonButton
        className={cx(CarrierDropdownStyles, ShadowedStyles, TopBarButton)}
        size='small'
        shape='round'
        color='light'
        id='carrier-dropdown'
      >
        {props.selectedCarrier?.id ? (
          <IonText className='current-carrier' color='primary'>
            {props.selectedCarrier?.name}
          </IonText>
        ) : (
          <IonSpinner name='dots' color='primary' />
        )}{' '}
        <IonIcon className='chevron' icon={chevronDown} color='dark' />
      </IonButton>
      <IonPopover
        arrow={false}
        trigger='carrier-dropdown'
        alignment='start'
        dismissOnSelect
        className={CarrierPopoverStyles}
      >
        <CarrierList carriers={allCarriers} onClick={onClickCarrier} selectedTechnology={props.selectedTechnology} />
      </IonPopover>
    </>
  )
}

const LegendButtonStyles = css`
  &::part(native) {
    padding: 0 8px;
  }
`
const LegendButton: React.FC<{ onClick: () => void }> = (props) => {
  return (
    <IonButton
      className={cx(TopBarButton, ShadowedStyles, LegendButtonStyles)}
      color='light'
      title='Legend'
      size='small'
      shape='round'
      onClick={props.onClick}
    >
      <svg width='42' height='12' viewBox='0 0 42 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect y='5.65686' width='8' height='8' transform='rotate(-45 0 5.65686)' fill='#E6000C' />
        <rect x='15.3137' y='5.65686' width='8' height='8' transform='rotate(-45 15.3137 5.65686)' fill='#FF781F' />
        <rect x='30.6274' y='5.65686' width='8' height='8' transform='rotate(-45 30.6274 5.65686)' fill='#43A047' />
      </svg>
    </IonButton>
  )
}

export const ShadowedStyles = css`
  &.button::part(native) {
    box-shadow: 0px 1px 2px var(--ion-color-light-shade);
  }

  &:not(.button) {
    box-shadow: 0px 1px 2px var(--ion-color-light-shade);
  }
`

const InlineSelectorStyles = css`
  display: flex;
  align-items: center;
  background-color: var(--ion-background-color);
  border-radius: 60px;
  margin: 4px 3px;
  padding: 4px;

  .selector-item {
    height: 100%;
  }
`
const InlineSelector: React.FC<{ items: React.ReactNode[] }> = (props) => {
  return (
    <div className={cx(ShadowedStyles, InlineSelectorStyles)}>
      {props.items.map((item, i) => (
        <div key={i} className='selector-item'>
          {item}
        </div>
      ))}
    </div>
  )
}

const TopBarStyles = css`
  position: absolute;
  top: 0;
  display: flex;
  padding: 16px;

  .chevron {
    font-size: 18px;
    transform: translateX(5px);
  }

  .technology-button {
    margin: 0;
    height: 100%;
    &::part(native) {
      padding: 0 12px;
      background-color: transparent;
      color: var(--ion-color-medium);
      font-weight: 600;
      font-size: 16px;
    }

    &.active::part(native) {
      background-color: var(--ion-color-primary-tint);
      color: var(--ion-color-primary);
    }
  }

  &.desktop {
    top: env(safe-area-inset-top);
    .edit-locations {
      margin-left: 4px;
      margin-right: 4px;
      height: 40px;
      font-size: 16px;
      &::part(native) {
        box-shadow: 0px 1px 2px var(--ion-color-light-shade);
        background-color: var(--ion-background-color);
        color: var(--ion-color-primary);
      }
      &.active::part(native) {
        background-color: var(--ion-color-primary-tint);
        color: var(--ion-color-primary);
      }
    }
    .center-icon {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .edit-icon {
      color: var(--ion-color-medium);
    }
  }
`

interface TopBarProps {
  reducedUI?: boolean
  onSwitchNetworkType: (networkType: NetworkTypeValue) => void
  onChangeCarrier: (carrier: Carrier) => void
  selectedCarrier?: Carrier
  selectedTechnology: NetworkTypeValue
  addresses: Address[]
}
const TopBar: React.FC<TopBarProps> = (props) => {
  const [appExperience] = useNavigationStore((store) => [store.appExperience], shallow)
  const [isLegendModalOpen, setLegendModalOpen] = React.useState(false)
  const { setLocalAddress: setAddressInBuffer } = useUnsavedAddresses()
  const { addresses } = props

  return (
    <>
      <section className={cx(TopBarStyles, appExperience)}>
        {props.reducedUI ? null : (
          <>
            {appExperience === 'mobile' ? null : (
              <>
                <AddressesSearchbar
                  addresses={addresses}
                  onAddressSelected={setAddressInBuffer}
                  searchBarPlaceholder='Search location'
                  onMap
                />
                {addresses.length ? (
                  <IonButton
                    shape='round'
                    color='light'
                    routerLink={RoutePaths.addressBook + '?back-to=' + MainNavigationPaths.map}
                    className='edit-locations'
                  >
                    <div className='center-icon'>
                      <EditIcon className='edit-icon' />
                      Edit locations
                    </div>
                  </IonButton>
                ) : null}
              </>
            )}
          </>
        )}
        <CarrierDropdown
          addresses={props.addresses}
          selectedCarrier={props.selectedCarrier}
          onChangeCarrier={props.onChangeCarrier}
          selectedTechnology={props.selectedTechnology}
        />
        <InlineSelector
          items={[
            <IonButton
              onClick={() => props.onSwitchNetworkType(NetworkType['5G'])}
              className={cx('technology-button', props.selectedTechnology === NetworkType['5G'] ? 'active' : '')}
              color='light'
              size='small'
              shape='round'
            >
              5G
            </IonButton>,
            <IonButton
              onClick={() => props.onSwitchNetworkType(NetworkType['4G'])}
              className={cx('technology-button', props.selectedTechnology === NetworkType['4G'] ? 'active' : '')}
              color='light'
              size='small'
              shape='round'
            >
              4G
            </IonButton>
          ]}
        />
        {props.reducedUI ? null : (
          <>
            <LegendButton onClick={() => setLegendModalOpen(true)} />
            <LegendModal isOpen={isLegendModalOpen} onClose={() => setLegendModalOpen(false)} />
          </>
        )}
      </section>
    </>
  )
}

export default TopBar
