import * as React from 'react'
import { css } from '@emotion/css'
import { IonButton, IonIcon } from '@ionic/react'
import { useLocation } from 'react-router'

const NextButtonDesktopStyles = css`
  text-transform: uppercase;
  width: 335px;
  font-size: 16px;
  font-weight: 700;
  height: 52px;
`

export interface NextButtonProps {
  hideArrow?: boolean
  desktop?: boolean
}
const NextButton: React.ForwardRefRenderFunction<
  HTMLIonButtonElement,
  React.ComponentPropsWithoutRef<typeof IonButton> & NextButtonProps
> = ({ routerLink, children, hideArrow, ...props }, ref) => {
  const location = useLocation()

  return (
    <IonButton
      className={props.desktop ? NextButtonDesktopStyles : ''}
      routerLink={routerLink ? routerLink + location.search : undefined}
      size='large'
      shape='round'
      expand='block'
      ref={ref}
      {...props}
    >
      {children}&nbsp;
      {hideArrow ? null : <IonIcon src='/assets/icons/next.svg' size='small' slot='end' />}
    </IonButton>
  )
}

export default React.forwardRef(NextButton)
