import { css, cx } from '@emotion/css'
import { ResetMargin } from '../utils/styles/MarginUtils.styles'
import CloseButton from './CloseButton/CloseButton'
import TModal from './TModal/TModal'
import { IonButton } from '@ionic/react'

const GoToCarrierModalStyles = css`
  background-color: var(--ion-background-color);
  .title {
    padding-right: 10px;
  }
  .modal-heading {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 21px;
    gap: 20px;

    .close-button {
      align-self: end;
      color: #777;
    }
    .title {
      align-self: start;
    }
  }
  .go-to-site-button {
    font-weight: 500;
    font-size: 19px;
    text-transform: uppercase;
    letter-spacing: 0px;
  }
`
const GoToCarrierModal: React.FC<{ onClose: (shouldRedirect: boolean) => void; isOpen: boolean }> = (props) => {
  return (
    <TModal isOpen={props.isOpen} className={GoToCarrierModalStyles}>
      <section className='modal-heading'>
        <div className='close-button'>
          <CloseButton onClose={() => props.onClose(false)} />
        </div>
        <h2 className={cx(ResetMargin, 'title')}>Before we redirect you</h2>
      </section>
      <section className='ion-margin-top'>
        Be sure to select the same plan and amount of phone lines. Then follow the instructions from the carrier and
        your device to complete your plan purchase and activation.
      </section>
      <IonButton
        size='large'
        shape='round'
        expand='block'
        className='go-to-site-button ion-margin-top'
        onClick={() => props.onClose(true)}
      >
        Go to carrier website
      </IonButton>
    </TModal>
  )
}

export default GoToCarrierModal
