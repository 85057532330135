import React, { useEffect } from 'react'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { Browser } from '@capacitor/browser'

const AppUrlListener: React.FC<any> = () => {
  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      Browser.close()
      // TODO: check for auth-specific urls
      const url = new URL(event.url)
      const code = url.searchParams.get('code') || ''
      const state = url.searchParams.get('state') || ''

      const newUrl = new URL(window.location.origin)
      newUrl.searchParams.set('code', code)
      newUrl.searchParams.set('state', state)

      window.location.href = newUrl.toString()
    })
  }, [])

  return null
}

export default AppUrlListener
