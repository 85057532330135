import { CoverageMapCardToolComponent } from '../../../../components/CoverageMapCard/CoverageMapCardToolComponent'
import { CoverageMapToolHandler } from '../../../../components/CoverageMapCard/CoverageMapToolHandler'
import { EmbeddedPlansCarouselToolComponent } from '../../components/tool-components/plans/EmbeddedPlansCarousel'
import { OnMessagePayload } from '../../hooks/useChat'
import { ToolCall } from '../../ServerResponse'
import { PlanFinderToolHandler } from '../handlers/plan-finder-tool-handler'

export enum TOOL_NAME {
  COVERAGE_MAP = 'CoverageMap',
  PLAN_FINDER = 'PlanFinder'
}

export const ToolComponents = {
  // Map
  CoverageMapCardToolComponent,

  // Plans
  EmbeddedPlansCarouselToolComponent
}

export type ToolName = 'CoverageMap' | 'PlanFinder'

export type ChatInterface<I> = {
  toolCall: ToolCall<I>
  onMessage: (payload: OnMessagePayload) => Promise<void>
  pushHistory: (path: string) => void
}

export interface ToolHandler<I> {
  handle(chatInterface: ChatInterface<I>): void
}

export const ToolMap = {
  [TOOL_NAME.COVERAGE_MAP]: {
    ToolHandler: CoverageMapToolHandler
  },
  [TOOL_NAME.PLAN_FINDER]: {
    ToolHandler: PlanFinderToolHandler
  }
}
