import { cx } from '@emotion/css'
import { EllipseBackgroundStyles } from './EllipseBackground.styles'

export function EllipseBackground(props: { className?: string }) {
  return (
    <div className={cx(EllipseBackgroundStyles, props.className)}>
      <svg xmlns='http://www.w3.org/2000/svg' width='600' height='300' viewBox='0 0 600 300'>
        <ellipse cx='300' cy='0' rx='500' ry='250' fill='currentColor' />
      </svg>
    </div>
  )
}
