export interface Plan {
  planId: string
  carrierId: string
  carrierName: string
  totogiCarrierName: string
  planName: string
  shortPlanName: string
  planLink: string
  price: number
  priceCurrency: string
  priceMultiLine: string
  telcoType: 'MNO' | 'MVNO' | 'UNKNOWN'
  data?: number
  sms?: number
  voice?: number
  hotspotDataLimit?: number
  hotspotDataLimitUnits?: string
  isHotspotUnlimited?: boolean
  video_streaming_quality: string
  noCoverage?: boolean
  networkPerformance5G?: number
  networkPerformance4G?: number
  networkOperators?: string[]
  costPerLineScore?: number
  dataAllowanceScore?: number
  popularityScore?: number
  perksScore?: number
  telcoTypeScore?: number
  nsc4gScore?: number
  nsc5gScore?: number
  overall4gScore?: number
  overall5gScore?: number
  costPerLine: number
  numLines?: number
  isDataUnlimited?: boolean
  isCarrierSupported?: boolean
  addons: Addon[]
  streamingServices?: StreamingServiceResponse[]
  roamingCountries?: string[]
  premiumData?: number
  premiumDataUnits?: string
  isPremiumDataUnlimited?: boolean
  affiliateLink?: string
  contractType?: PlanResponse['contractType']
  contractMonthLength?: PlanResponse['contractMonthLength']
}

export interface PlanResponse {
  id: string
  name?: string
  carrierId?: string
  carrierName?: string
  totogiCarrierName?: string
  shortPlanName?: string
  contractType?: string
  contractMonthLength?: number
  status?: string
  supportedNetworkTypes?: string[]
  planDataType?: string
  dataLimit?: number
  dataLimitUnits?: string
  isDataUnlimited?: boolean
  voiceMinutesBottom?: number
  voiceMinutesTop?: number
  isVoiceUnlimited?: boolean
  smsAllowanceBottom?: number
  smsAllowanceTop?: number
  isSmsUnlimited?: boolean
  planLink?: string
  hotspotDataLimit?: number
  hotspotDataLimitUnits?: string
  isHotspotUnlimited?: boolean
  monthlyPrice?: number
  priceCurrency?: string
  telcoType?: 'MNO' | 'MVNO'
  networkOperators?: string[]
  costPerLineScore?: number
  dataAllowanceScore?: number
  popularityScore?: number
  perksScore?: number
  telcoTypeScore?: number
  nsc4gScore?: number
  nsc5gScore?: number
  overall4gScore?: number
  overall5gScore?: number
  costPerLine: number
  numLines?: number
  isCarrierSupported?: boolean
  addons?: AddonResponse[]
  streamingServices?: StreamingServiceResponse[]
  roamingCountries?: string[]
  roaming: RoamingCountry[]
  premiumData?: number
  premiumDataUnits?: string
  isPremiumDataUnlimited?: boolean
  affiliateLink?: string
}
export interface RoamingCountry {
  country: string
  smsRoamingIncluded?: number
  isSmsRoamingIncludedUnlimited?: boolean
  voiceRoamingIncluded?: number
  voiceRoamingIncludedUnits?: string
  isVoiceRoamingIncludedUnlimited?: boolean
  dataRoamingIncluded?: number
  dataRoamingIncludedUnits?: string
  isDataRoamingIncludedUnlimited?: boolean
}

export type AddonTypes =
  | 'Netflix'
  | 'Amazon'
  | 'Discovery+'
  | 'Apple Tv'
  | 'Apple Arcade'
  | 'Google Play'
  | 'Apple Music'
  | 'Disney+'
  | 'Paramount'
  | 'Hulu'
  | 'ESPN+'
  | 'HBO Max'

export interface Addon {
  id: AddonTypes
  carrierId: string
  monthlyPrice: number
  priceCurrency: string
  addonDescription: string
  contractLengthInMonths: string
  contractType?: number
}

export interface AddonResponse {
  id: string
  carrierId: string
  monthlyPrice: number
  priceCurrency: string
  addonDescription: string
  contractLengthInMonths: string
  contractType?: number
}

export interface StreamingServiceResponse {
  serviceName: string
  initialPeriod: number
  initialPeriodUnits: string
  marketValue: number
  marketValueCurrency: string
}

export interface TopPick {
  id: number
  toolbarText: string
  title: string
  subtitle: string
  linkText: string
  link: string
  bgColor: string
  sideImageUrl: string
}

export type BestPlansState = {
  compareSelection: Set<Plan['planId']>
  selectedPlanResult?: Plan
}

export const UnlimitedDataPreference = {
  NO: 0,
  YES: 1,
  NO_PREFERENCE: 2
}
