import React from 'react'
import TModal from '../TModal/TModal'
import { css } from '@emotion/css'
import { IonButton, IonImg, IonText } from '@ionic/react'
import CloseButton from '../CloseButton/CloseButton'
import { applyGeneralStoreToEditStore } from '../../store/editStoreManagement'
import { MainNavigationPaths, RoutePaths } from '../../paths'

const DesktopWelcomeMapModalStyles = css`
  width: 692px;
  padding-top: 60px;

  .close-button {
    height: 13px;
    width: 13px;
    color: var(--ion-color-medium);
    position: absolute;
    top: 25px;
    right: 29px;
  }

  ion-text {
    margin-bottom: 12px;
  }

  ion-button {
    width: 100%;
    margin-top: 12px;
    text-transform: uppercase;
    height: 52px;
  }
`

export const DesktopWelcomeMapModal: React.FC<{ onClose: () => void; isOpen: boolean }> = (props) => {
  return (
    <TModal isOpen={props.isOpen} className={DesktopWelcomeMapModalStyles}>
      <section>
        <CloseButton onClose={props.onClose} />
        <IonImg src='/assets/images/welcome/map-screenshot.webp' alt='Map Screenshot' />
        <h2>Welcome to the Coverage Map</h2>
        <IonText>
          Want to see how good a carrier’s network performance really is? Add some of your favorite locations to find
          out!
        </IonText>
        <IonButton shape='round' onClick={props.onClose}>
          Continue to map
        </IonButton>
        <IonButton
          shape='round'
          fill='outline'
          onClick={applyGeneralStoreToEditStore}
          routerLink={RoutePaths.addresses + '?open-from=' + MainNavigationPaths.map}
        >
          Edit locations
        </IonButton>
      </section>
    </TModal>
  )
}
