import create from 'zustand'
import { State, useEditStore, useGeneralStore } from '.'

export function selectValuesToCopy(state: State) {
  return {
    userCarrierId: state.userCarrierId,
    currentPlanId: state.currentPlanId,
    currentPhoneLines: state.currentPhoneLines,
    reasonsForSwitch: state.reasonsForSwitch,
    phoneLinesNeeded: state.phoneLinesNeeded,
    useUnlimitedDataPlan: state.useUnlimitedDataPlan,
    targetBudget: state.targetBudget,
    addresses: state.addresses,
    currentAddress: state.currentAddress,
    favoritePlanIds: state.favoritePlanIds,
    searchedPlansHistory: state.searchedPlansHistory,
    searchedAddressHistory: state.searchedAddressHistory
  }
}

export function applyGeneralStoreToEditStore() {
  new EditStoreManagementService().applyGeneralStoreToEditStore()
}

export class EditStoreManagementService {
  constructor(
    private generalStore: typeof useGeneralStore = useGeneralStore,
    private editStore: typeof useEditStore = useEditStore,
    private editStoreManagement: typeof useEditStoreManagement = useEditStoreManagement
  ) {}
  applyGeneralStoreToEditStore() {
    const state = this.generalStore.getState()
    this.editStore.setState(selectValuesToCopy(state))
    this.editStoreManagement.getState().setDirty(false)
  }
}

export function applyEditStoreToGeneralStore() {
  const state = useEditStore.getState()
  useGeneralStore.setState(selectValuesToCopy(state))
}

interface UseEditStoreManagementState {
  isDirty: boolean
  setDirty: (isDirty: boolean) => void
}
useEditStore.subscribe(() => {
  useEditStoreManagement.getState().setDirty(true)
})

export const useEditStoreManagement = create<UseEditStoreManagementState>((set) => ({
  isDirty: false,
  setDirty: (isDirty) => set({ isDirty })
}))
