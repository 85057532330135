import { TopPickBannerStyles } from './TopPicksStyles'
import { IonRouterLink, IonSkeletonText } from '@ionic/react'
import { MainNavigationPaths } from '../../../paths'
import { ResetMargin } from '../../../utils/styles/MarginUtils.styles'
import { cx } from '@emotion/css'

type TopPick = {
  id: number
  toolbarText: string
  title: string
  subtitle: string
  linkText: string
  link: string
  bgColor: string
  sideImageUrl: string
}
export function TopPickBanner(props: { topPick: TopPick; loading?: boolean }) {
  return (
    <IonRouterLink routerLink={props.loading ? '#' : MainNavigationPaths.plans + '/' + props.topPick.id}>
      <section className={TopPickBannerStyles(props.topPick.bgColor)}>
        <div className='heading'>
          <div className='heading-text'>
            {props.loading ? (
              <>
                <IonSkeletonText style={{ height: '100%' }} animated />
                <IonSkeletonText animated />
                <IonSkeletonText animated />
              </>
            ) : (
              <>
                <h2 className={cx(ResetMargin, 'banner-title')}>{props.topPick.title}</h2>
                <section className='banner-subtitle'>{props.topPick.subtitle}</section>
              </>
            )}
          </div>
          <div className='side-image-wrapper'>
            {props.loading ? (
              <IonSkeletonText animated />
            ) : (
              <img className='side-image' alt='Plan banner illustration' src={props.topPick.sideImageUrl} />
            )}
          </div>
        </div>

        <div className='banner-link-wrapper'>
          <IonRouterLink className='banner-link' routerLink={props.topPick.link}>
            {props.loading ? <IonSkeletonText style={{ width: '50%' }} animated /> : props.topPick.linkText}
          </IonRouterLink>
        </div>
      </section>
    </IonRouterLink>
  )
}
