import * as React from 'react'
import { Address } from '../store/address'

type LatLngAddr = Pick<Address, 'latitude' | 'longitude'>

interface MarkerProps<A extends LatLngAddr = LatLngAddr>
  extends Omit<google.maps.marker.AdvancedMarkerElementOptions, 'position'> {
  map?: google.maps.Map
  active: boolean
  icon?: string
  onClick: () => void
  address: A
}

export type UseMarkerOptions<A extends LatLngAddr> = Omit<MarkerProps<A>, 'address' | 'active' | 'onClick'> & {
  icon?: string
  iconActive?: string
  onClick: (address: A) => void
  locations: {
    address: A
    active?: boolean
  }[]
}
export function useMarkers<A extends LatLngAddr>({
  iconActive: iconActiveParam,
  icon: iconParam,
  onClick,
  map,
  locations
}: UseMarkerOptions<A>) {
  const icon = iconParam || '/assets/icons/map-marker.svg'
  const iconActive = iconActiveParam || '/assets/icons/map-marker-active.svg' || icon

  const markersRef = React.useRef<google.maps.marker.AdvancedMarkerElement[]>([])
  const listeners = React.useRef<google.maps.MapsEventListener[]>([])
  const initializeMarkers = React.useCallback(() => {
    markersRef.current = locations.map((location) => {
      const iconImg = document.createElement('img')
      iconImg.src = location.active ? iconActive : icon

      const marker = new google.maps.marker.AdvancedMarkerElement({
        map,
        position: new google.maps.LatLng(location.address.latitude, location.address.longitude),
        content: iconImg
      })

      listeners.current.push(marker.addListener('click', () => onClick(location.address)))
      return marker
    })
  }, [icon, iconActive, map, locations, onClick])

  React.useEffect(() => {
    const markersValue = markersRef.current

    removeMarkers()

    function removeMarkers() {
      markersValue.forEach((marker) => {
        marker.map = null
      })
    }
    initializeMarkers()
  }, [map, icon, initializeMarkers, markersRef])
}

const Marker: React.FC<MarkerProps> = ({ address, active, ...props }) => {
  const locations = React.useMemo(() => {
    return [{ address, active }]
  }, [address, active])

  useMarkers({ ...props, locations })
  return <></>
}

export default Marker
