export const usdOnlyNumberFormatter = Intl.NumberFormat('en-US', {
  minimumIntegerDigits: 1,
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
})

export const usdCurrencyFormatter = Intl.NumberFormat('en-US', {
  minimumIntegerDigits: 1,
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  currency: 'USD',
  style: 'currency'
})

export const usdIntegerCurrencyFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0
})

export const formatAsCurrency = (value: string | number | undefined, formatter: Intl.NumberFormat) => {
  if (!value) {
    return ''
  }

  const strValue = String(value)
  const numericValue =
    strValue.slice(0, -2).padStart(2, '0') + '.' + strValue.slice(-2, strValue.length).padStart(2, '0')

  return formatter.format(Number(numericValue))
}
