export const IconText = ({ color, className }: { color?: string; className?: string }) => {
  return (
    <div className={className}>
      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M18 0H2C0.9 0 0 0.9 0 2V20L4 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 14H3.17L2 15.17V2H18V14ZM5 7H7V9H5V7ZM13 7H15V9H13V7ZM9 7H11V9H9V7Z'
          fill={color ?? 'rgba(0, 0, 0, 0.6)'}
        />
      </svg>
    </div>
  )
}
