export type ComparedPlan = {
  price: number
  network_performance: number
  telcoType: 'MNO' | 'MVNO' | 'UNKNOWN'
  premium_data: number
  data: number
}

const PRICE_WEIGHT = 0.4
const NETWORK_PERFORMANCE_WEIGHT = 1
const PREMIUM_DATA_WEIGHT = 0.2

export function compareMNO(planA: ComparedPlan, planB: ComparedPlan) {
  if (planA.telcoType === 'MVNO' && planB.telcoType === 'MNO') {
    return 1
  }
  if (planA.telcoType === 'MNO' && planB.telcoType === 'MVNO') {
    return -1
  }

  return 0
}

export function planCompare(planA: ComparedPlan, planB: ComparedPlan): number {
  function calculateScore(plan: number, otherPlan: number, weight: number): number {
    if (plan > otherPlan) {
      const differencePercentage = (plan - otherPlan) / plan
      return differencePercentage * weight
    }
    return 0
  }

  function calculatePremiumDataScore(
    planAAllowance: number,
    planBAllowance: number,
    planAUnlimited: boolean,
    planBUnlimited: boolean
  ): number {
    if (planAUnlimited && !planBAllowance) {
      return PREMIUM_DATA_WEIGHT
    } else if (!planAUnlimited && planAAllowance && planBAllowance && !planBUnlimited) {
      if (planAAllowance > planBAllowance) {
        const differencePercentage = (planAAllowance - planBAllowance) / planAAllowance
        return differencePercentage * PREMIUM_DATA_WEIGHT
      }
    }
    return 0
  }

  const mnoBoost = compareMNO(planA, planB)
  if (mnoBoost === -1 || mnoBoost === 1) {
    return mnoBoost
  }

  let planAScore = 0
  let planBScore = 0

  const planAPrice = planA.price
  const planBPrice = planB.price
  planAScore -= calculateScore(planAPrice, planBPrice, PRICE_WEIGHT)
  planBScore -= calculateScore(planBPrice, planAPrice, PRICE_WEIGHT)

  const planANetworkPerformance = planA.network_performance
  const planBNetworkPerformance = planB.network_performance
  planAScore += calculateScore(planANetworkPerformance, planBNetworkPerformance, NETWORK_PERFORMANCE_WEIGHT)
  planBScore += calculateScore(planBNetworkPerformance, planANetworkPerformance, NETWORK_PERFORMANCE_WEIGHT)

  planAScore += calculatePremiumDataScore(planA.premium_data, planB.premium_data, false, false)
  planBScore += calculatePremiumDataScore(planB.premium_data, planA.premium_data, false, false)

  const planADataUnlimited = planA.data === 1e18
  const planBDataUnlimited = planB.data === 1e18
  planAScore += calculatePremiumDataScore(planA.data, planB.data, planADataUnlimited, planBDataUnlimited)
  planBScore += calculatePremiumDataScore(planB.data, planA.data, planBDataUnlimited, planADataUnlimited)

  if (planAScore < planBScore) {
    return 1
  } else if (planAScore > planBScore) {
    return -1
  }
  return 0
}
