import { Logger } from '../../../utils/Logger'
import { IChatFlow } from '../types/ChatStep'
import { PredefinedChatStorage, ChatStorageSource } from '../types/ChatStorageSource'

const CHAT_LS_KEY = 'chatStorage'
export function getLocalStorageSource<T extends IChatFlow>(): ChatStorageSource<T> {
  return {
    getStorage: async () => {
      const lsItem = localStorage.getItem(CHAT_LS_KEY)
      if (lsItem) {
        try {
          return JSON.parse(lsItem) as PredefinedChatStorage<T>
        } catch (e) {
          Logger.error('Error parsing chat storage from local storage', e)
        }
      }
    },
    setStorage: async (value) => {
      if (!value) {
        localStorage.removeItem(CHAT_LS_KEY)
      } else {
        localStorage.setItem(CHAT_LS_KEY, JSON.stringify(value))
      }
    }
  }
}
